import React, { Component, useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import DATTMXLogo from "../assets/DATTMXLogo.png";

function MenuAdmin({
  onAdminReporteClick,
  onRegistroClick,
  onRegistroClienteClick,
  onPowerOnlyClick,
  onCostosClick,
  onAdminTranspClienteClick,
  onAdminRemolquesClick,
  onAdminOperadoresClick,
  onAsignarRutasClick,
  onRegistrarClientesClick,
  onCuentasPorCobrarClick,
}) {
  const [userName, setUserName] = useState("");
  const [submenuUsuarios, setSubmenuUsuarios] = useState(false);
  const [submenuCliente, setSubmenuCliente] = useState(false);
  const [submenuOperaciones, setSubmenuOperaciones] = useState(false);
  const [submenuFinanzas, setSubmenuFinanzas] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      // No hay token, redirigir a la página de inicio de sesión
      navigate("/", { replace: true });
    } else {
      const user = jwtDecode(token);
      const decodedUser = jwtDecode(token);
      if (decodedUser) {
        setUserName(decodedUser.name);
      }
      if (!user) {
        localStorage.removeItem("token");
        navigate("/", { replace: true });
      } else {
      }
    }
  }, [navigate]);

  return (
    <div className="">
      <aside
        className="main-sidebar sidebar-dark-primary elevation-4 "
        style={{ zIndex: 999 }}
      >
        <img
          src={DATTMXLogo}
          // className="brand-image  elevation-3"
          style={{
            // opacity: "0.8",
            width: "230px",
            margin: "10px",
            padding: "5px",
          }}
        />
        <div className="sidebar ">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                src="../images/user.png"
                className="img-circle elevation-2"
                alt="User Image"
              />
            </div>
            <div className="info">
              <a href="#" className="d-block">
                {userName}
              </a>
            </div>
          </div>
          <div className="form-inline"></div>
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link accordion"
                  onClick={() => setSubmenuUsuarios(!submenuUsuarios)}
                >
                  <i
                    className="fa fa-user"
                    style={{ fontSize: "20px", marginRight: "1rem" }}
                  />
                  Usuarios
                </a>

                <div
                  className={`accordion__content nav-item ${
                    submenuUsuarios ? "open" : ""
                  }`}
                >
                  <a href="#" className="nav-link" onClick={onRegistroClick}>
                    <p style={{ marginLeft: "2.1rem" }}>Registro de Usuarios</p>
                  </a>
                </div>
              </li>

              {/* Clientes */}
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link accordion"
                  onClick={() => setSubmenuCliente(!submenuCliente)}
                >
                  <i
                    className="fa fa-users"
                    style={{ fontSize: "20px", marginRight: ".6rem" }}
                  />
                  Clientes
                </a>

                <div
                  className={`accordion__content nav-item ${
                    submenuCliente ? "open" : ""
                  }`}
                >
                  <a
                    href="#"
                    className="nav-link"
                    onClick={onRegistroClienteClick}
                  >
                    <p style={{ marginLeft: "2.2rem" }}>
                      Solicitudes de Clientes
                    </p>
                  </a>
                  <a
                    href="#"
                    className="nav-link"
                    onClick={onRegistrarClientesClick}
                  >
                    <p style={{ marginLeft: "2.2rem" }}>Administrar Clientes</p>
                  </a>
                </div>
              </li>

              {/* Operaciones */}

              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link accordion"
                  onClick={() => setSubmenuOperaciones(!submenuOperaciones)}
                >
                  <i
                    className="fa fa-truck"
                    style={{ fontSize: "20px", marginRight: "10px" }}
                  />
                  Operaciones
                </a>

                <div
                  className={`accordion__content nav-item ${
                    submenuOperaciones ? "open" : ""
                  }`}
                >
                  <a
                    href="#"
                    className="nav-link"
                    onClick={onAsignarRutasClick}
                  >
                    <p style={{ marginLeft: "2.1rem" }}>Asignar Viajes</p>
                  </a>
                  <a
                    href="#"
                    className="nav-link"
                    onClick={onAdminOperadoresClick}
                  >
                    <p style={{ marginLeft: "2.1rem" }}>
                      Administrar operadores
                    </p>
                  </a>
                  <a
                    href="#"
                    className="nav-link"
                    onClick={onAdminTranspClienteClick}
                  >
                    <p style={{ marginLeft: "2.1rem" }}>
                      Administrar Transportes
                    </p>
                  </a>

                  <a
                    href="#"
                    className="nav-link"
                    onClick={onAdminRemolquesClick}
                  >
                    <p style={{ marginLeft: "2.1rem" }}>
                      Administrar Remolques
                    </p>
                  </a>
                </div>
              </li>

              {/* FINANZAS */}

              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link accordion"
                  onClick={() => setSubmenuFinanzas(!submenuFinanzas)}
                >
                  <i
                    className="fa fa-balance-scale"
                    style={{ fontSize: "20px", marginRight: "10px" }}
                  />
                  Finanzas
                </a>

                <div
                  className={`accordion__content nav-item ${
                    submenuFinanzas ? "open" : ""
                  }`}
                >
                  <a
                    href="#"
                    className="nav-link"
                    onClick={onAdminReporteClick}
                  >
                    <i style={{ fontSize: "20px" }} />
                    <p style={{ marginLeft: "2rem" }}>Reporte de Viajes</p>
                  </a>

                  <a href="#" className="nav-link" onClick={onPowerOnlyClick}>
                    <i className="" style={{ fontSize: "20px" }} />
                    <p style={{ marginLeft: "2rem" }}>Power Only</p>
                  </a>

                  <a
                    href="#"
                    className="nav-link"
                    onClick={onCuentasPorCobrarClick}
                  >
                    <i className="" style={{ fontSize: "20px" }} />
                    <p style={{ marginLeft: "2rem" }}>Cuentas por cobrar</p>
                  </a>
                  <a href="#" className="nav-link" onClick={onCostosClick}>
                    <i className="" style={{ fontSize: "20px" }} />
                    <p style={{ marginLeft: "2rem" }}>Costos</p>
                  </a>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
}
export default MenuAdmin;
