//DEV
//export const API_URL = "http://localhost:1337";

//URL STAGING
//export const API_URL = "https://dev.datt-tracking.com";

//PROD
export const API_URL = "https://datt-tracking.com";

export const VALOR_CAMBIO = 17.24;

export const RFC_Orig = "DIA191107CI4";
//export const RFC_Orig = "EKU9003173C9";
