import "../../styles/alert.css";
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { API_URL, RFC_Orig, VALOR_CAMBIO } from "../../constants";
import * as Dialog from "@radix-ui/react-dialog";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { toast } from "react-toastify";
import * as Checkbox from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";
import { Cross2Icon } from "@radix-ui/react-icons";
import { PageInfo } from "../PageInfo";
import { MercanciasRead } from "../MercanciasRead";
import { Mercancias } from "../Mercancias";
import { Spinner, filter } from "@chakra-ui/react";
import { ProgressSpinner } from "primereact/progressspinner";
import SyncLoader from "react-spinners/ClipLoader";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { IoDocuments } from "react-icons/io5";
import { FaRegFileWord } from "react-icons/fa";
import jwtDecode from "jwt-decode";

import { IoCloseSharp } from "react-icons/io5";
// import { Flex, Text, Button } from '@radix-ui/themes';

function ReporteRutas() {
  const [route, setRoute] = useState("");
  const [factura, setFactura] = useState("");
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    username: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    origin: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    destination: { value: null, matchMode: FilterMatchMode.IN },
    distance: { value: null, matchMode: FilterMatchMode.EQUALS },
    duration: { value: null, matchMode: FilterMatchMode.EQUALS },
    customerFlete: { value: null, matchMode: FilterMatchMode.EQUALS },
    customerFactura: { value: null, matchMode: FilterMatchMode.EQUALS },
    vehicle: { value: null, matchMode: FilterMatchMode.EQUALS },
    diesel: { value: null, matchMode: FilterMatchMode.EQUALS },
    assignedDate: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const dt = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogCFDIOpen, setIsDialogCFDIOpen] = useState(false);
  const [isDialogOpen2, setIsDialogOpen2] = useState(false);
  const [isDialogOpen3, setIsDialogOpen3] = useState(false);
  const [isDialogOpen4, setIsDialogOpen4] = useState(false);
  const [isDialogOpen5, setIsDialogOpen5] = useState(false);
  const [coloniaFigura, setColoniaFigura] = useState("");
  const [monto, setMonto] = useState("");
  const [numeroFactura, setNumero] = useState("");
  const [comprobante, setComprobante] = useState("");
  const [facturaToSave, setFacturaToSave] = useState(null);
  const [distance, setDistance] = useState("");

  //CartaPorte States
  const [formaPago, setFormaPago] = useState("");
  const [metodoPago, setMetodoPago] = useState("");
  const [exportacion, setExportacion] = useState("");
  const [cantidadViaje, setCantidadViaje] = useState("1");
  const [codigoViaje, setCodigoViaje] = useState("");
  const [codigoServicioViaje, setCodigoServicioViaje] = useState("E48");
  const [claveUnidadViaje, setClaveUnidadViaje] =
    useState("Unidad de Servicio");
  const [descripcionViaje, setDescripcionViaje] = useState("");
  const [precioUnitViaje, setPrecioUnitViaje] = useState("");

  const [precioUnitViajeAux, setPrecioUnitViajeAux] = useState("");

  const [subtotalViaje, setSubtotalViaje] = useState("");
  const [subTotalViajeAux, setSubtotalViajeAux] = useState("");
  const [objetoImpuesto, setObjetoImpuesto] = useState("");
  const [totalViaje, setTotalViaje] = useState("");
  const [totalViajeAux, setTotalViajeAux] = useState("");

  const [viaEntSal, setViaEntSal] = useState("");
  const [transpInt, setTranspInt] = useState("");
  const [regimAduan, setRegimAduan] = useState("");
  const [entSalMerc, setEntSalMerc] = useState("");
  const [paisOrigDest, setPaisOrigDest] = useState("");
  const [fechaHoraSalidaOrig, setFechaHoraSalidaOrig] = useState("");
  const [RFCOrig, setRFCOrig] = useState("");
  const [paisOrig, setPaisOrig] = useState("");
  const [CPOrig, setCPOrig] = useState("");
  const [estadoOrig, setEstadoOrig] = useState("");
  const [fechaHoraSalOrig, setFechaHoraSalOrig] = useState("");
  const [RFCDest, setRFCDest] = useState("");
  const [paisDest, setPaisDest] = useState("");
  const [CPDest, setCPDest] = useState("");
  const [estadoDest, setEstadoDest] = useState("");
  const [fraccAranc, setFraccAranc] = useState("");
  const [impoVucem, setImpoVucem] = useState("");
  const [permImpo, setPermImpo] = useState("");
  const [sectorCofepris, setSectorCofepris] = useState("");
  const [tipoMateria, setTipoMateria] = useState("");
  const [descMateria, setDescMateria] = useState("");
  const [bienesTransp, setBienesTransp] = useState("");
  const [despMaterial, setDespMaterial] = useState("");
  const [cantMaterial, setCantMaterial] = useState("");
  const [claveMaterial, setClaveMaterial] = useState("");
  const [matPeligroso, setMatPeligroso] = useState("No");
  const [pesoMateria, setPesoMateria] = useState("");
  const [tipoDoc, setTipoDoc] = useState("");
  const [numPedimento, setNumPedimento] = useState("");
  const [RFCImportador, setRFCImportador] = useState("");
  const [cantidadMaterial, setCantidadMaterial] = useState("");
  const [IDOrigen, setIDOrigen] = useState("");
  const [IDDestino, setIDDestino] = useState("");
  const [permisoSICT, setPermisoSICT] = useState("");
  const [pesoVehiculo, setPesoVehiculo] = useState("");
  const [placaVehiculo, setPlacaVehiculo] = useState("");
  const [anioVehiculo, setAnioVehiculo] = useState("");
  const [nombreSeguro, setNombreSeguro] = useState("");
  const [polizaSeguro, setPolizaSeguro] = useState("");
  const [tipoRemolque, setTipoRemolque] = useState("");
  const [placaCaja, setPlacaCaja] = useState("");
  const [tipoFigura, setTipoFigura] = useState("01");
  const [RFCFigura, setRFCFigura] = useState("");
  const [numLicencia, setNumLicencia] = useState("");
  const [nombreFigura, setNombreFigura] = useState("");
  const [calleFigura, setCalleFigura] = useState("");
  const [numExt, setNumExt] = useState("");
  const [numInt, setNumInt] = useState("");
  const [refFigura, setRefFigura] = useState("");
  const [municipioFigura, setMunicipioFigura] = useState("");
  const [estadoFigura, setEstadoFigura] = useState("");
  const [paisFigura, setPaisFigura] = useState("");
  const [CPFigura, setCPFigura] = useState("");
  const [logInversa, setLogInversa] = useState("Sí");
  const [numPermisoSCT, setNumPermiso] = useState("");
  const [confVehicular, setConfVehicular] = useState("");
  const [usoCFDI, setUsoCFDI] = useState("");
  const [razonSocialRecep, setRazonSocialRecep] = useState("");
  const [cpRecep, setCPRecep] = useState("");
  const [rfcRecp, setRFCRec] = useState("");
  const [regimenRecep, setRegimenRecep] = useState("");
  const [municipioOrig, setMunicipioOrig] = useState("");
  const [coloniaOrig, setColoniaOrig] = useState("");
  const [municipioDest, setMunicipioDest] = useState("");
  const [coloniaDest, setColoniaDest] = useState("");
  const [precioVenta, setPrecioVenta] = useState("");
  const [emailCliente, setEmailCliente] = useState("");
  const [idFactura, setIdFactura] = useState("");
  const [motivoCancelacion, setMotivoCancelacion] = useState("");
  const [idViaje, setIdViaje] = useState("");
  const [mercancias, setMercancias] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [totalImpuestos, setTotalImpuestos] = useState("");
  const [tipoCambio, setTipoCambio] = useState("");

  const [useContainer, setUseContainer] = useState(false);

  const [cpOrigDisabled, setCpOrigDisabled] = useState(true);
  const [cpDestDisabled, setCpDestDisabled] = useState(true);
  const [nameMunicipio, setNameMunicipio] = useState("");
  const [nameColonia, setNameColonia] = useState("");

  const [nameEstado, setEstadoName] = useState("");

  const [formaFarmaceutica, setFormaFarmaceutica] = useState([]);
  const [condicionesEspeciales, setCondicionesEspeciales] = useState([]);
  const [medAmbiente, setMedAmbiente] = useState(false);
  const [seguroMedAmbiente, setSeguroMedAmbiente] = useState("");
  const [polizaMedAmbiente, setPolizaMedAmbiente] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [embalajeData, setEmbalajeData] = useState("");

  const [tipoCFDI, setTipoCFDI] = useState("");
  const [dolarValue, setDolarValue] = useState("");

  const [isLoadingFactura, setIsLoadingFactura] = useState(false);
  const [isLoadingXML, setIsLoadingXML] = useState(false);

  const [userName, setUserName] = useState("");

  const [cartaPorte, setCartaPorte] = useState(false);

  const [emisor, setEmisor] = useState("");
  const [emisorFactura, setEmisorFactura] = useState("");

  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  const [status, setStatus] = useState([]);
  const [permisos, setPermisos] = useState([]);
  const [transportsByCompany, setTranportsByCompany] = useState([]);
  const [customersByCompany, setCustomersByCompany] = useState([]);

  const [confVehicularData, setConfVehicularData] = useState([]);

  const [remolquesData, setRemolquesData] = useState([]);

  const filterRoutesObj = {
    usuario: "",
    clienteFactura: "",
    empresa: "",
    estatus: "",
    fechaInicio: "",
    vehiculo: "",
    usuario: "",
    idFactura: "",
  };

  const [filterRoutes, setFilterRoutes] = useState(filterRoutesObj);
  const [driversData, setDriversData] = useState([]);
  const [remolque, setRemolque] = useState("");

  useEffect(() => {
    // // fetchRoutesData();
    fetchTravelStatus();
    let perms = JSON.parse(localStorage.getItem("permisos"));
    setPermisos(perms);

    if (perms.length <= 1) {
      setEmisor(perms[0]);

      setFilterRoutes({
        ...filterRoutes,
        empresa: perms[0],
      });

      handleSingleCompany(perms[0]);
    }

    const token = localStorage.getItem("token");
    if (token) {
      const decodedUser = jwtDecode(token);
      setUserName(decodedUser.name);
    }
  }, []);

  const fetchConfVehicular = async () => {
    try {
      let response = await fetch(`${API_URL}/api/catalagos/confVehicular/`);
      let data = await response.json();
      setConfVehicularData(data);
    } catch (error) {
      console.error("Error en fetchConfVehicular", error);
    }
  };

  const fetchRemolques = async (empresa) => {
    try {
      let response = await fetch(
        `${API_URL}/api/catalagos/remolques?empresa=${empresa}`
      );
      let data = await response.json();
      console.log("Data fetchRemolques", data);
      if (data.status === "Ok") {
        setRemolquesData(data.remolques);
      } else {
        notifyWarning(data.message);
      }
    } catch (error) {
      notifyError(error);
    }
  };
  const handleChangeRemolque = (e) => {
    console.log("remolquesData", remolquesData);
    setRemolque(e.target.value);
    console.log("Data Recibida", e.target.value);
    let remolque = remolquesData.find(
      (remolque) => remolque._id === e.target.value
    );
    console.log("Remolque handleRemolqie", remolque);
    setPlacaCaja(remolque.placaRemolque);
    setTipoRemolque(remolque.subTipoRem);
  };

  const handleSingleCompany = async (empresa) => {
    let drivers = await fetchDriversByCompany(empresa);
    setDriversData(drivers);

    let transports = await fetchTransportsByCompany(empresa);
    setTranportsByCompany(transports);

    let customers = await fetchCustomersByCompany(empresa);
    setCustomersByCompany(customers);
  };

  const clearStates = () => {
    setRemolque("");
    setIsLoading(false);
    setCartaPorte("Sí");
    setCantidadViaje("1");
    setTotalViajeAux("");
    setSubtotalViajeAux("");
    setCodigoViaje("");
    setUsoCFDI("");
    setTipoCFDI("");
    setExportacion("");
    setDescripcionViaje("");
    setPaisDest("");
    setPaisOrig("");
    setObjetoImpuesto("");
    // setMercancias([]);
    setTipoDoc("");
    //Fetch customers info

    setPrecioVenta("");
    setPrecioUnitViaje("");
    setPrecioUnitViajeAux("");
    setMetodoPago("");
    setFormaPago("");

    setSubtotalViaje("");
    setTotalViaje("");
    setTipoCambio("MXN");
    setDistance("");
    setPlacaCaja("");
    setTipoRemolque("");

    setEstadoDest("");
    setEstadoOrig("");

    setMunicipioOrig("");
    setMunicipioDest("");

    setCPOrig("");
    setCPDest("");

    setColoniaDest("");
    setColoniaOrig("");

    setFechaHoraSalOrig("");
    setFechaHoraSalidaOrig("");

    //Set mercancias a carta porte
    //falta fraccion arancelaria
    setBienesTransp("");
    setCantMaterial("");
    setDespMaterial("");
    setCantMaterial("");
    setClaveMaterial("");
    setPesoMateria("");

    //Tranp int info
    setTranspInt("");

    setEntSalMerc("");

    setNumPedimento("");
    setTipoDoc("");
    setRFCImportador("");
    setCantidadMaterial("");
    // setMercancias("");

    setFraccAranc("");
    setTipoMateria("");
  };
  const handleChangeFilter = async (e) => {
    console.log("filterRoutes", filterRoutes);
    if (e.target.name === "empresa") {
      setFilterRoutes({
        ...filterRoutes,
        vehiculo: "",
        clienteFactura: "",
        [e.target.name]: e.target.value,
      });

      if (e.target.value !== "") {
        let transports = await fetchTransportsByCompany(e.target.value);
        setTranportsByCompany(transports);
        let customers = await fetchCustomersByCompany(e.target.value);
        setCustomersByCompany(customers);
        let drivers = await fetchDriversByCompany(e.target.value);
        setDriversData(drivers);
      }
    } else {
      setFilterRoutes({
        ...filterRoutes,
        [e.target.name]: e.target.value,
      });
    }
  };

  const notifyInfo = (message) => {
    toast.info(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };
  const notifyWarning = (message) => {
    toast.warning(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const fetchTravelStatus = async () => {
    try {
      let response = await fetch(`${API_URL}/api/routes/status`);
      let data = await response.json();
      if (data.status === "Ok") {
        setStatus(data.response);
      } else {
        notifyWarning(data.message);
      }
    } catch (error) {
      notifyError(error);
    }
  };

  //TODO agregar apartado de fechas (inicio/final)
  //FETCH routes
  const fetchRoutesRData = async () => {
    setIsLoadingSearch(true);
    try {
      let queryString = "?";

      Object.entries(filterRoutes).forEach(([key, value], index) => {
        if (value !== "") {
          queryString += `${key}=${value}&`;
        }
      });

      if (!queryString.includes("empresa")) {
        queryString += `empresa=${permisos}&`;
      }
      queryString = queryString.substring(0, queryString.length - 1);

      const response = await fetch(`${API_URL}/api/routes${queryString}`);
      const data = await response.json();
      setIsLoadingSearch(false);
      if (data.status === "Ok") {
        console.log("Data en fetchRoutes", data);

        let routes = await getRouteDriver(data.routes);
        routes = await getRouteCustomers(routes);
        routes = await getRouteVehicle(routes);
        console.log("Routes", routes);

        setRoute(routes);
        return routes;
      } else {
        setRoute([]);
        notifyWarning(data.message);
      }
    } catch (error) {
      setIsLoadingSearch(false);
      notifyError(error);
    }
  };

  const getRouteDriver = async (routes) => {
    try {
      let driversData = await fetchDriversByCompany("");
      console.log("getRouteDriver", routes);

      routes.map((route) => {
        if (route.usuario.trim() !== "") {
          let usuario = driversData.find(
            (driver) => driver._id === route.usuario
          );
          console.log("Usuario ", usuario);
          route.idUsuario = route.usuario;
          route.usuario = usuario.nombreAlternativo;
        }
      });

      return routes;
    } catch (error) {
      notifyError(error);
    }
  };

  const getRouteCustomers = async (routes) => {
    try {
      let customers = await fetchCustomersByCompany("");

      routes.map((route) => {
        let customerFactura = customers.find(
          (customer) => customer._id === route.clienteFactura
        );
        let customerFlete = customers.find(
          (customer) => customer._id === route.clienteFlete
        );
        route.idClienteFactura = route.clienteFactura;
        route.idClienteFlete = route.clienteFlete;
        route.clienteFactura = customerFactura.razonSocial;
        route.clienteFlete = customerFlete.razonSocial;
      });

      return routes;
    } catch (error) {
      console.error(error);
      notifyError(error);
    }
  };

  const getRouteVehicle = async (routes) => {
    try {
      let transports = await fetchTransportsByCompany("");

      routes.map((route) => {
        let vehicle = transports.find(
          (transport) => transport._id === route.vehiculo
        );
        console.log("Vehicle", vehicle);
        route.idVehiculo = route.vehiculo;
        route.vehiculo = vehicle.numUnidad;
      });

      return routes;
    } catch (error) {
      notifyError(error);
    }
  };

  const fetchCustomersByCompany = async (empresa) => {
    try {
      if (empresa === "") {
        empresa = permisos;
      }
      let response = await fetch(
        `${API_URL}/api/customerUpdate?empresa=${empresa}`
      );
      let data = await response.json();

      if (data.status === "Ok") {
        return data.customers;
      } else {
        setCustomersByCompany([]);
        notifyWarning(data.message);
      }
    } catch (error) {
      notifyError(error);
    }
  };

  const fetchDriversByCompany = async (empresa) => {
    try {
      if (empresa === "") {
        empresa = permisos;
      }

      let response = await fetch(`${API_URL}/api/drivers?company=${empresa}`);
      let data = await response.json();

      if (data.status === "Ok") {
        return data.drivers;
      } else {
        setDriversData([]);
        notifyWarning(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTransportsByCompany = async (empresa) => {
    try {
      if (empresa === "") {
        empresa = permisos;
      }
      let response = await fetch(
        `${API_URL}/api/transports?empresa=${empresa}`
      );
      let data = await response.json();

      if (data.status === "Ok") {
        return data.transports;
      } else {
        setTranportsByCompany([]);
        notifyWarning(data.error);
      }
    } catch (error) {
      notifyError(error);
    }
  };

  const fetchPaisesAPI = async () => {
    try {
      const response = await fetch(`${API_URL}/api/catalogopaises`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        const selectElement = document.getElementById("selectPaises");
        const selectElement2 = document.getElementById("selectPaises2");
        const selectElement3 = document.getElementById("selectPaises3");
        const selectElement4 = document.getElementById("selectPaises4");

        data.forEach((country) => {
          const option = document.createElement("option");
          const option2 = document.createElement("option");
          const option3 = document.createElement("option");
          const option4 = document.createElement("option");
          option.value = country.Value;
          option.text = country.Name;
          option2.value = country.Value;
          option2.text = country.Name;
          option3.value = country.Value;
          option3.text = country.Name;
          option4.value = country.Value;
          option4.text = country.Name;
          selectElement.add(option);
          selectElement2.add(option2);
          selectElement3.add(option3);
          selectElement4.add(option4);
        });
      } else {
        // console.error("Error en la solicitud:", response.statusText);
      }
    } catch (error) {
      // Handle any fetch errors here
      // console.error(error);
      // notifyError()
    }
  };

  const fetchDollarValue = async () => {
    try {
      let response = await fetch(`${API_URL}/api/divisas/dolar`);
      let data = await response.json();
      let dolar = data.response[0].dato;
      setDolarValue(Number(dolar).toFixed(2));
    } catch (error) {
      notifyError("No se puede acceder al valor del dolar");
    }
  };

  const fetchEstadosOrigen = async (e) => {
    try {
      const response = await fetch(
        `${API_URL}/api/catalogoestados?countryCode=${e}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        const selectElement = document.getElementById("selectEstado");
        selectElement.innerHTML = "";
        const optionDefault = document.createElement("option");
        optionDefault.value = "";
        optionDefault.text = "Selecciona un Estado";
        selectElement.add(optionDefault);
        data.forEach((country) => {
          const option = document.createElement("option");
          option.value = country.Value;
          option.text = country.Name;
          selectElement.add(option);
        });
        return data;
      } else {
        // console.error("Error en la solicitud:", response.statusText);
        notifyError("No se pudieron obtener los estados del origen");
      }
    } catch (error) {
      notifyError("No se pudieron obtener los estados del origen");
    }
  };

  const fetchEstadosDestino = async (e) => {
    //Fetch Estados segun country value:
    try {
      const response = await fetch(
        `${API_URL}/api/catalogoestados?countryCode=${e}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        const selectElement2 = document.getElementById("selectEstado2");
        selectElement2.innerHTML = "";
        const optionDefault = document.createElement("option");
        optionDefault.value = "";
        optionDefault.text = "Selecciona un Estado";
        selectElement2.add(optionDefault);
        data.forEach((country) => {
          const option2 = document.createElement("option");
          option2.value = country.Value;
          option2.text = country.Name;
          selectElement2.add(option2);
        });
        return data;
      } else {
        // console.error("Error en la solicitud:", response.statusText);
        notifyError("No se pudieron obtener los estados del destino");
      }
    } catch (error) {
      notifyError("No se pudieron obtener los estados del destino");
    }
  };

  const fetchColoniasOrig = async (e) => {
    if (e.length !== 5) {
      return false;
    }
    try {
      const response = await fetch(
        `${API_URL}/api/catalogolocalidades?postalCode=${e}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        const selectElement = document.getElementById("selectColonia");
        selectElement.innerHTML = "";
        const defaultOption = document.createElement("option");
        defaultOption.value = "";
        defaultOption.text = "Selecciona una colonia";
        selectElement.add(defaultOption);
        // if (data.length === 1) {
        // }
        data.forEach((country) => {
          const option = document.createElement("option");
          option.value = country.Value;
          option.text = country.Name;
          selectElement.add(option);
        });
        return data;
      } else {
        notifyError("No se pueden devolver las colonias del origen");
      }
    } catch (error) {
      notifyError("No se pueden devolver las colonias del origen");
    }
  };

  const fetchColoniasDest = async (e) => {
    if (e.length !== 5) {
      return false;
    }
    try {
      const response = await fetch(
        `${API_URL}/api/catalogolocalidades?postalCode=${e}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        const selectElement = document.getElementById("selectColonia1");
        selectElement.innerHTML = "";
        const optionDefault = document.createElement("option");
        optionDefault.value = "";
        optionDefault.text = "Selecciona una Colonia";
        selectElement.add(optionDefault);
        if (data.length === 1) {
          const defaultOption = document.createElement("option");
          defaultOption.value = "";
          defaultOption.text = "Escoge una opción";
          selectElement.add(defaultOption);
        }
        data.forEach((country) => {
          const option = document.createElement("option");
          option.value = country.Value;
          option.text = country.Name;
          selectElement.add(option);
        });
        return data;
      } else {
        // console.error("Error en la solicitud:", response.statusText);
        notifyError("No se pueden devolver las colonias del destino");
      }
    } catch (error) {
      // console.error(error);
      notifyError("No se pueden devolver las colonias del destino");
    }
  };

  const handleFormaPagoVal = (e) => {
    if (e === "99") {
      setMetodoPago("PDD");
    } else {
      setMetodoPago("PUE");
    }
  };

  const fetchMunicipiosOrig = async (e) => {
    try {
      const response = await fetch(
        `${API_URL}/api/catalogomunicipios?stateCode=${e}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        const selectElement = document.getElementById("selectMunicipio");
        selectElement.innerHTML = "";
        const optionDefault = document.createElement("option");
        optionDefault.value = "";
        optionDefault.text = "Selecciona un Municipio";
        selectElement.add(optionDefault);

        // let optionDefault = document.createElement("optionDefault");
        // optionDefault.value = "";
        // optionDefault.text = "Selecciona un Municipio";
        // selectElement.add(optionDefault);

        data.forEach((country) => {
          let option = document.createElement("option");
          option.value = country.Value;
          option.text = country.Name;
          selectElement.add(option);
        });
        return data;
      } else {
        // console.error("Error en la solicitud:", response.statusText);
        notifyError("No se pueden devolver los municipios de origen");
      }
    } catch (error) {
      notifyError("No se pueden devolver los municipios de origen");
    }
  };

  const fetchMunicipiosDest = async (e) => {
    try {
      const response = await fetch(
        `${API_URL}/api/catalogomunicipios?stateCode=${e}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        const selectElement = document.getElementById("selectMunicipio1");
        selectElement.innerHTML = "";
        const optionDefault = document.createElement("option");
        optionDefault.value = "";
        optionDefault.text = "Selecciona un Municipio";
        selectElement.add(optionDefault);
        data.forEach((country) => {
          const option = document.createElement("option");
          option.value = country.Value;
          option.text = country.Name;
          selectElement.add(option);
        });
        return data;
      } else {
      }
    } catch (error) {
      notifyError("No se pueden devolver los municipios de Destino");
    }
  };

  const notifyError = (message) => {
    toast.error(`¡Error: ${message}!`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const notifyAviso = (message) => {
    toast.warning(`¡${message}!`, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const showPdfComprobante = (factura) => {
    const urlFile = factura;
    window.open(`${API_URL}/files/comprobantes/${urlFile}`);
  };

  const showPdfConfirmacion = (factura) => {
    const urlFile = factura;
    window.open(`${API_URL}/files/hoja-finalizacion/${urlFile}`);
  };
  const getStatusColor = (status) => {
    let colors = [
      {
        status: "Pendiente Chofer",
        color: "#ffc107",
      },

      {
        status: "Pendiente Gastos Diesel",
        color: "#ffc107",
      },

      {
        status: "Pendiente Gastos Viaje",
        color: "#ffc107",
      },
      {
        status: "Pendiente Precio Venta",
        color: "#ffc107",
      },
      {
        status: "Pendiente Vehículo",
        color: "#ffc107",
      },
      {
        status: "Pendiente Referencia",
        color: "#ffc107",
      },
      {
        status: "Pendiente Facturación",
        color: "#17a2b8",
      },
      {
        status: "Pendiente Pago",
        color: "#cf9b00",
        // color: "#ffc107"
      },
      {
        status: "Viaje en curso",
        color: "#28a745",
      },
      {
        status: "Finalizado",
        color: "#28a745",
      },
    ];

    let color = colors.find((value) => {
      if (value.status == status) return value.color;
    });

    return color ? color.color : "#dc3545";
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(route);
      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ["data"],
      };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "viajes");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const handleComprobanteUpload = async () => {
    const formData = new FormData();
    formData.append("estatus", facturaToSave.estatus);
    formData.append("comprobante", comprobante);
    formData.append("_id", facturaToSave._id);
    try {
      setIsButtonDisabled(true);
      const response = await fetch(`${API_URL}/api/uploadcomprobante`, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        // fetchRoutesData();
        notifyComprobante();
      } else {
        notifyError("no se pudo subir el comprobante");
      }
    } catch (error) {
      notifyError("No se pudo subir el comprobante");
    }
  };

  const confirmDeleteDriver = (rowData) => {
    setFacturaToSave(rowData);
    setIsDialogOpen(true);
  };

  const showDialogCFDI = (rowData) => {
    setFacturaToSave(rowData);
    setIsDialogCFDIOpen(true);
  };

  const handleSendFactura = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/api/cfdi/send-factura`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ emailCliente, idFactura }),
      });
      if (response.status == 200) {
        notifyMail("¡Factura enviada con éxito!");
        setIsDialogOpen(false);
      } else {
        notifyError("No se pudo enviar la factura");
        setIsDialogOpen(true);
      }
    } catch (error) {
      notifyError("No se pudo enviar la factura");
    }
  };

  const handleSaveComprobante = (e) => {
    e.preventDefault();
    if (facturaToSave) {
      handleComprobanteUpload(facturaToSave._id);
    }
    setIsDialogOpen3(false);
  };

  const genNationalPayload = (basePayload) => {
    let mercancia = [];
    let transpMatPel = [];

    mercancias.forEach((el) => {
      let objMercancia = {
        BienesTransp: el.bienesTransp,
        Descripcion: el.descripcion,
        Cantidad: Number(el.cantidad).toFixed(2),
        ClaveUnidad: el.claveUnidad.toUpperCase(),
        PesoEnKg: el.pesoMateria,
        MaterialPeligroso: el.matPeligroso,
      };

      if (el.matPeligroso === "Sí") {
        objMercancia.CveMaterialPeligroso = el.cveMatPeligroso;
        objMercancia.Embalaje = el.embalaje;
        transpMatPel.push(true);
      }
      mercancia.push(objMercancia);
    });

    let ubicaciones = [
      {
        TipoUbicacion: "Origen",
        RFCRemitenteDestinatario: rfcRecp,
        FechaHoraSalidaLlegada: fechaHoraSalidaOrig,
        Domicilio: {
          Pais: paisOrig,
          CodigoPostal: CPOrig,
          Estado: estadoOrig,
          Municipio: municipioOrig,
          Colonia: coloniaOrig,
        },
      },
      {
        TipoUbicacion: "Destino",
        RFCRemitenteDestinatario: RFCDest,
        FechaHoraSalidaLlegada: fechaHoraSalOrig,
        DistanciaRecorrida: distance,
        Domicilio: {
          Pais: paisDest,
          CodigoPostal: CPDest,
          Estado: estadoDest,
          Municipio: municipioDest,
          Colonia: coloniaDest,
        },
      },
    ];
    let cartaPorte = {
      TranspInternac: transpInt,
      Mercancias: {
        UnidadPeso: "KGM",
        Mercancia: mercancia,
        Autotransporte: {
          PermSCT: permisoSICT,
          NumPermisoSCT: numPermisoSCT,
          IdentificacionVehicular: {
            ConfigVehicular: confVehicular,
            PesoBrutoVehicular: pesoVehiculo,
            PlacaVM: placaVehiculo,
            AnioModeloVM: anioVehiculo,
          },
          Seguros: {
            AseguraRespCivil: nombreSeguro,
            PolizaRespCivil: polizaSeguro,
            AseguraMedAmbiente: seguroMedAmbiente,
            PolizaMedAmbiente: polizaMedAmbiente,
          },
          Remolques: [
            {
              SubTipoRem: tipoRemolque,
              Placa: placaCaja,
            },
          ],
        },
      },
    };
    if (logInversa === "Sí") {
      cartaPorte.Mercancias.LogisticaInversaRecoleccionDevolucion = logInversa;
    }

    if (!useContainer) {
      delete cartaPorte.Mercancias.Autotransporte.Remolques;
    }

    if (!medAmbiente || !transpMatPel.includes(true)) {
      delete cartaPorte.Mercancias.Autotransporte.Seguros.AseguraMedAmbiente;
      delete cartaPorte.Mercancias.Autotransporte.Seguros.PolizaMedAmbiente;
    }

    let figuraTransporte = [
      {
        TipoFigura: tipoFigura,
        RFCFigura: RFCFigura,
        NumLicencia: numLicencia,
        NombreFigura: nombreFigura,
        Domicilio: {
          Calle: calleFigura,
          NumeroExterior: numExt,
          NumeroInterior: numInt,
          Colonia: coloniaFigura,
          Referencia: refFigura,
          Municipio: municipioFigura,
          Estado: estadoFigura,
          Pais: paisFigura,
          CodigoPostal: CPFigura,
        },
      },
    ];

    cartaPorte.Ubicaciones = ubicaciones;
    cartaPorte.FiguraTransporte = figuraTransporte;

    basePayload.Complemento = {
      CartaPorte30: cartaPorte,
    };

    return basePayload;
  };
  const handleEntradaSalida = ({ target }) => {
    let valueInput = target.value;

    let currentArray = mercancias.map((element) => {
      element.entradaSalida = valueInput;
      return element;
    });

    setEntSalMerc(valueInput);

    setMercancias([...currentArray]);
  };

  const genInternationalPayload = (payload) => {
    payload.Complemento.CartaPorte30.ViaEntradaSalida = viaEntSal;
    payload.Complemento.CartaPorte30.TranspInternac = transpInt;
    payload.Complemento.CartaPorte30.RegimenAduanero = regimAduan;
    payload.Complemento.CartaPorte30.EntradaSalidaMerc = entSalMerc;
    payload.Complemento.CartaPorte30.PaisOrigenDestino = paisOrigDest;
    let mercanciasArr = payload.Complemento.CartaPorte30.Mercancias.Mercancia;

    mercancias.forEach((el, index) => {
      mercanciasArr[index].FraccionArancelaria = el.fraccAranc;

      if (
        el.impoVucem.trim() !== "" &&
        el.sectorCofepris !== "03" &&
        el.sectorCofepris !== ""
      ) {
        mercanciasArr[index].FolioImpoVUCEM = el.impoVucem;
      }

      if (
        el.sectorCofepris === "03" &&
        mercanciasArr[index].hasOwnProperty("FolioImpoVUCEM")
      ) {
        delete mercanciasArr[index].FolioImpoVUCEM;
      }

      if (
        el.permImpo.trim() !== "" &&
        el.sectorCofepris !== "" &&
        el.sectorCofepris !== "04"
      ) {
        mercanciasArr[index].PermisoImportacion = el.permImpo;
      }

      if (el.sectorCofepris.trim() !== "") {
        mercanciasArr[index].SectorCOFEPRIS = el.sectorCofepris;
      }

      mercanciasArr[index].TipoMateria = el.tipoMateria;

      if (el.descripcionMateria.trim() !== "") {
        mercanciasArr[index].DescripcionMateria = el.descripcionMateria;
      }

      if (
        el.sectorCofepris === "01" ||
        el.sectorCofepris === "02" ||
        el.sectorCofepris === "03"
      ) {
        if (el.sectorCofepris !== "02") {
          mercanciasArr[index].DenominacionGenericaProd =
            el.denominacionGenProd;
          mercanciasArr[index].DenominacionDistintivaProd =
            el.denominacionDistProd;
          mercanciasArr[index].RegistroSanitarioFolioAutorizacion =
            el.registroSanitario;
        }

        mercanciasArr[index].Fabricante = el.fabricante;
        mercanciasArr[index].FechaCaducidad = el.fechaCaducidad;
        mercanciasArr[index].LoteMedicamento = el.loteMed;
        mercanciasArr[index].FormaFarmaceutica = el.formaFarmaceutica;
        mercanciasArr[index].CondicionesEspTransp = el.condTransporte;
      }

      if (el.sectorCofepris === "02") {
        mercanciasArr[index].NombreIngredienteActivo = el.ingActivo;
        mercanciasArr[index].nomQuimico = el.nomQuimico;
      }

      if (el.sectorCofepris === "04") {
        mercanciasArr[index].razonSocialEmpImp = el.razonSocialEmpImp;
        mercanciasArr[index].nomQuimico = el.nomQuimico;
        mercanciasArr[index].numCAS = el.numCAS;
      }

      if (el.sectorCofepris === "05") {
        mercanciasArr[index].NombreIngredienteActivo = el.ingActivo;
        mercanciasArr[index].NumRegSanPlagCOFEPRIS = el.regSanPlag;
        mercanciasArr[index].DatosFabricante = el.datosFabricante;
        mercanciasArr[index].DatosFormulador = el.datosFormulador;
        mercanciasArr[index].DatosMaquilador = el.datosMaquilador;
        mercanciasArr[index].UsoAutorizado = el.usoAutorizado;
      }
      mercanciasArr[index].DocumentacionAduanera = [
        {
          TipoDocumento: el.tipoDoc,
          NumPedimento: el.numPedimento,
          RFCImpo: el.RFCImportador,
        },
      ];

      if (el.tipoDoc !== "01" && el.tipoDoc !== "") {
        delete mercanciasArr[index].DocumentacionAduanera[0].NumPedimento;
        // delete mercanciasArr[index].DocumentacionAduanera[0].RFCImpo;
        mercanciasArr[index].DocumentacionAduanera[0].IdentDocAduanero =
          el.identDocAduanero;
      }
    });

    payload.Complemento.CartaPorte30.Mercancias.Mercancia = mercanciasArr;

    return payload;
  };

  const handleGenCartaPorte = async (e) => {
    e.preventDefault();

    let payload = "";
    let basePayload = {
      Receiver: {
        Name: razonSocialRecep, //razonSocialRecep
        CfdiUse: usoCFDI,
        Rfc: rfcRecp,
        FiscalRegime: regimenRecep,
        TaxZipCode: cpRecep, //Tax zip code 42501
      },
      CfdiType: tipoCFDI,
      NameId: "35",
      ExpeditionPlace: 66630,
      Currency: tipoCambio,
    };
    if (tipoCambio !== "MXN") {
      delete basePayload.Currency;
    }

    if (tipoCFDI === "I") {
      basePayload.PaymentForm = formaPago;
      basePayload.PaymentMethod = metodoPago;
    }

    let unitPrice = Number(precioUnitViaje);

    let itemObj = {
      Quantity: cantidadViaje,
      ProductCode: codigoViaje,
      Unit: "Unidad de Servicio",
      UnitCode: codigoServicioViaje,
      Description: descripcionViaje,
      UnitPrice: unitPrice,
      Subtotal: subtotalViaje,
      TaxObject: objetoImpuesto,
      Total: totalViaje,
    };

    if (objetoImpuesto == "02") {
      let tax = Number(subtotalViaje * 0.16).toFixed(2);
      itemObj.Taxes = [
        {
          Total: tax,
          Name: "IVA",
          Base: subtotalViaje,
          Rate: 0.16,
          IsRetention: false,
        },
      ];
    }
    basePayload.Items = [itemObj];
    payload = basePayload;
    if (cartaPorte === "Sí") {
      payload = genNationalPayload(basePayload);
      payload = transpInt === "Sí" ? genInternationalPayload(payload) : payload;
    }

    try {
      setIsLoading(true);
      const response = await fetch(`${API_URL}/api/cfdi`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          facturaId: facturaToSave._id,
          datosDeSolicitud: payload,
          cartaPorte,
          userName,
          emisor,
        }),
      });

      const data = await response.json();

      setIsLoading(false);

      if (data.Message) {
        if (data.ModelState) {
          const errores = data.ModelState;

          for (const prop in errores) {
            if (Object.hasOwnProperty.call(errores, prop)) {
              const mensajes = errores[prop];

              mensajes.forEach((mensaje) => {
                notifyAviso(mensaje);
              });
            }
          }
        }
        notifyAviso(data.Message || "Error al realizar la solicitud");
      } else if (data.error) {
        notifyError(data.error);
      } else {
        notifyMail("Se realizó la carta porte exitosamente");
        fetchRoutesRData();
        setIsDialogOpen4(false);
      }
    } catch (error) {
      notifyError(error?.Message || error);
    }
  };

  const descargarFacturaPDF = async () => {
    setIsLoadingFactura(true);
    try {
      const response = await fetch(`${API_URL}/api/cfdi/descargarpdf`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idFactura,
          emisor: emisorFactura ? emisorFactura : "DATTMX",
        }),
      });
      const data = await response.json();
      const byteCharacters = atob(data);
      const jsonContent = JSON.parse(byteCharacters);
      const base64Content = jsonContent.Content;
      const binaryContent = atob(base64Content);
      const byteArray = new Uint8Array(binaryContent.length);
      for (let i = 0; i < binaryContent.length; i++) {
        byteArray[i] = binaryContent.charCodeAt(i);
      }
      const blob = new Blob([byteArray], { type: jsonContent.ContentType });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Factura_${idFactura}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsLoadingFactura(false);
    } catch (error) {
      notifyError("No se pudo descargar la factura");
      setIsLoadingFactura(false);
      console.log(error);
    }
  };

  const descargarFacturaXML = async () => {
    setIsLoadingXML(true);
    const response = await fetch(`${API_URL}/api/cfdi/descargarxml`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idFactura,
        emisor: emisorFactura ? emisorFactura : "DATTMX",
      }),
    });
    const data = await response.json();
    const byteCharacters = atob(data);
    const jsonContent = JSON.parse(byteCharacters);
    const base64Content = jsonContent.Content;
    const binaryContent = atob(base64Content);
    const byteArray = new Uint8Array(binaryContent.length);
    for (let i = 0; i < binaryContent.length; i++) {
      byteArray[i] = binaryContent.charCodeAt(i);
    }

    const xmlString = new TextDecoder().decode(byteArray);
    const blob = new Blob([xmlString], { type: "application/xml" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Factura_${idFactura}.xml`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsLoadingXML(false);
  };

  const CancelarCFDI = async () => {
    if (!motivoCancelacion) {
      notifyError("escoge el motivo de cancelación.");
      return false;
    }
    const response = await fetch(`${API_URL}/api/cfdi/cancel-factura`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idFactura,
        motivoCancelacion,
        idViaje,
        emisor: emisorFactura ? emisorFactura : "DATTMX",
      }),
    });
    const data = await response.json();
    if (data.Status == "canceled") {
      setIsDialogOpen(false);
      // fetchRoutesData();
      notifyCancelacion();
    } else {
      notifyError(data.Message);
    }
  };

  // Upload factura on route assigned:
  const handleFileUpload = async () => {
    const nombreArchivo = `factura_${numeroFactura}.pdf`;
    const formData = new FormData();
    formData.append("monto", monto);
    formData.append("factura", factura, nombreArchivo);
    formData.append("_id", facturaToSave._id);
    try {
      const response = await fetch(`${API_URL}/api/uploadfactura`, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        // fetchRoutesData();
      } else {
        notifyError("No se pudo subir la factura");
      }
    } catch (error) {
      notifyError("No se pudo subir la factura");
    }
  };

  const showPdf = (factura) => {
    const urlFile = factura;
    window.open(`${API_URL}/files/facturas/${urlFile}`);
  };

  const actionBodyTemplate3 = (rowData) => {
    if (rowData.estatus != "Pendiente Pago") {
      return null;
    }
    return (
      <React.Fragment>
        <Button
          rounded
          outlined
          severity="primary"
          placeholder="Guardar"
          className="pi pi-save btn__pi"
          style={{ color: "#5c6f80" }}
          onClick={() => confirmDeleteDriver3(rowData)}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate4 = (rowData) => {
    if (rowData.estatus != "Pendiente Facturación") {
      return null;
    }
    return (
      <React.Fragment>
        <Button
          rounded
          outlined
          severity="primary"
          placeholder="Guardar"
          className="pi pi-save btn__pi"
          style={{ color: "#5c6f80" }}
          onClick={() => confirmDeleteDriver4(rowData)}
        ></Button>
      </React.Fragment>
    );
  };

  //Subir factura
  const actionBodyTemplate5 = (rowData) => {
    if (rowData.estatus != "Pendiente Facturación") {
      return null;
    }
    return (
      <React.Fragment>
        <Button
          icon="pi pi-save"
          rounded
          outlined
          severity="primary"
          placeholder="Generar Carta Porte"
          className="ButtonPDF"
          onClick={() => confirmDeleteDriver5(rowData)}
          style={{ fontSize: "1.5rem", color: "black" }}
        />
      </React.Fragment>
    );
  };

  const confirmDeleteDriver5 = (rowData) => {
    setFacturaToSave(rowData);
    setIsDialogOpen5(true);
  };

  const handleSaveConfirmation = (e) => {
    e.preventDefault();
    if (facturaToSave) {
      handleFileUpload(facturaToSave._id);
    }
    setIsDialogOpen5(false);
  };

  const confirmDeleteDriver2 = (rowData) => {
    setFacturaToSave(rowData);
    setIsDialogOpen2(true);
  };

  const confirmDeleteDriver3 = (rowData) => {
    setFacturaToSave(rowData);
    setIsDialogOpen3(true);
  };
  const dataCountryCode = (data) => {
    try {
      let countryValue = [];
      let countryCodes = [
        { country: "México", code: "MEX" },
        { country: "Estados Unidos", code: "USA" },
      ];
      data.forEach((address) => {
        if (address.types.includes("country")) {
          countryValue.push(address);
        }
      });
      let countryCode = countryCodes.find(
        (country) => country.country == countryValue[0].long_name
      );
      return countryCode.code;
    } catch (error) {
      notifyError(
        "No se puede devolver el valor del páis,ingrésalo manualmente"
      );
    }
  };

  const dataStateCode = async (statesData, data, ubication) => {
    let statesTypes = [
      "administrative_area_level_1",
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5",
      "administrative_area_level_6",
      "administrative_area_level_7",
    ];

    try {
      // let dataEstados =
      let stateAddress = [];
      data.forEach((address) => {
        if (statesTypes.includes(address.types[0])) {
          stateAddress.push(address);
        }
      });
      let processedStates = [];

      let stateData = statesData.forEach((state) => {
        let obj = {};
        obj.Name = state.Name;
        obj.Value = state.Value;
        obj.NameSplit = state.Name.split(" ");
        processedStates.push(obj);
      });

      let stateValue = processedStates.find((state) => {
        if (state.NameSplit.includes(stateAddress[0].long_name)) {
          return state;
        } else if (state.Name === stateAddress[0].long_name) {
          return state;
        }
      });

      return stateValue.Value;
    } catch (error) {
      notifyError(
        `No se pueden obtener los datos de los estados en ${ubication}`
      );
    }
  };
  const dataMunicipioCode = (municipiosData, data, ubication) => {
    try {
      const municipioType = ["locality"];
      let municipioValue = [];
      data.forEach((address) => {
        if (municipioType.includes(address.types[0])) {
          municipioValue.push(address);
        }
      });
      let municipioData = municipiosData.find(
        (munic) => munic.Name === municipioValue[0].long_name
      );

      return municipioData.Value;
    } catch (error) {
      notifyAviso(`No se pueden devolver los municipios en ${ubication}`);
    }
  };
  const getCodigoPostal = (data, ubication) => {
    try {
      let codigoPostal = data.find((address) =>
        address.types[0].includes("postal_code")
      );
      return codigoPostal.long_name;
    } catch (error) {
      notifyAviso(
        `El codigo postal no pudo ser devuelto en ${ubication}, ingresalo manualmente`
      );
    }
  };

  const getColonia = async (colonias, data, ubication) => {
    try {
      let coloniaFound = data.find((address) =>
        address.types[0].includes("sublocality")
      );
      let coloniaName = coloniaFound.long_name;
      coloniaFound.long_name = coloniaName
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      let coloniaData = colonias.find(
        (colonia) => colonia.Name === coloniaFound.long_name
      );
      return coloniaData ? coloniaData.Value : "";
    } catch (error) {
      notifyAviso(
        `No se pueden devolver la colonia en ${ubication}, ingresala manualmente`
      );
    }
  };

  const getTiempoEstimado = (time) => {
    let tiempoEstimado = 0;
    let tiempoTrans = time.split(".");
    let horasEstimadas = Number(tiempoTrans[0]);
    let minutosEstimados = Number(tiempoTrans[1]);

    if (horasEstimadas > 0) {
      tiempoEstimado += horasEstimadas * 60 * 60000;

      if (minutosEstimados > 0) {
        tiempoEstimado += ((minutosEstimados * 60) / 100) * 60000;
      }
    } else {
      tiempoEstimado += ((minutosEstimados * 60) / 100) * 60000;
    }
    return tiempoEstimado;
  };

  const handleInfoCartaPorte = async (dataFactura) => {
    if (confVehicular.length <= 0) {
      fetchConfVehicular();
    }

    setPaisOrig("");
    setPaisDest("");
    console.log("Empresa ", dataFactura.empresa);
    setEmisor(dataFactura.empresa);

    // let duracion = rowData.duracion;
    let fechaOrigen = new Date(dataFactura.fechaInicio);
    let tiempoEstimado = getTiempoEstimado(dataFactura.duracion);

    let fechaDestino = fechaOrigen.getTime() + tiempoEstimado;
    let horaMinutosDestino = new Date(fechaDestino)
      .toLocaleTimeString()
      .split(":");
    let fechaSalidaHoraDest = `${
      new Date(fechaDestino).toISOString().split("T")[0]
    }T${
      horaMinutosDestino[0].length <= 1
        ? `0${horaMinutosDestino[0]}`
        : horaMinutosDestino[0]
    }:${horaMinutosDestino[1]}`;
    setFechaHoraSalOrig(fechaSalidaHoraDest);

    //---------------------- !Origen!  --------

    //Información de origen
    //Pais de origen
    let originCountryCode = dataCountryCode(dataFactura.originAddress);
    setPaisOrig(originCountryCode);

    //Estado de origen
    let originStatesData = await fetchEstadosOrigen(originCountryCode);
    let originStateCode = await dataStateCode(
      originStatesData,
      dataFactura.originAddress,
      "Origen"
    );
    setEstadoOrig(originStateCode);

    //Municipio del Origen
    let origenMunicipios = await fetchMunicipiosOrig(originStateCode);
    let origenMunicipioCode = await dataMunicipioCode(
      origenMunicipios,
      dataFactura.originAddress,
      "Origen"
    );
    setMunicipioOrig(origenMunicipioCode);

    let origenCP = getCodigoPostal(dataFactura.originAddress, "Origen");
    if (origenCP) {
      setCPOrig(origenCP);
      let colonias = await fetchColoniasOrig(origenCP);

      let coloniaCode = await getColonia(
        colonias,
        dataFactura.originAddress,
        "Origen"
      );
      setColoniaOrig(coloniaCode);
    }
    let fechaInicioOrig = dataFactura.fechaInicio.split(" ").join("T");
    setFechaHoraSalidaOrig(fechaInicioOrig);
    //---------------------- !Destino  --------
    //Información de destino

    //Pais de destino
    let destinationCountryCode = dataCountryCode(
      dataFactura.destinationAddress
    );
    setPaisDest(destinationCountryCode);

    //Estados del Destino
    let destinationStatesData = await fetchEstadosDestino(
      destinationCountryCode
    );
    let destinationStateCode = await dataStateCode(
      destinationStatesData,
      dataFactura.destinationAddress,
      "Destino"
    );
    setEstadoDest(destinationStateCode);

    //Municipio del Destino

    let destinationMunicipios = await fetchMunicipiosDest(destinationStateCode);
    let destinationCode = await dataMunicipioCode(
      destinationMunicipios,
      dataFactura.destinationAddress,
      "Destino"
    );
    setMunicipioDest(destinationCode);

    //Codigo Postal de destino
    let destinoCP = getCodigoPostal(dataFactura.destinationAddress, "Destino");

    if (destinoCP) {
      setCPDest(destinoCP);
      let colonias = await fetchColoniasDest(destinoCP);
      let coloniaCode = await getColonia(
        colonias,
        dataFactura.destinationAddress,
        "Destino"
      );
      setColoniaDest(coloniaCode);
    }

    setMercancias([]);

    //Fetch drivers info
    // fetchDriverData(dataFactura);

    let dataDrivers = await fetchDriversByCompany(dataFactura.empresa);
    getDriversData(dataDrivers, dataFactura);

    fetchTransportData(dataFactura.vehiculo);
    setDistance(dataFactura.distancia);
    setPlacaCaja(dataFactura.contenedor);
    setTipoRemolque(dataFactura.tipoRemolque);

    //Set mercancias a carta porte
    //falta fraccion arancelaria
    setBienesTransp(dataFactura.mercancias[0].bienesTransp);
    setCantMaterial(dataFactura.mercancias[0].cantMaterial);
    setDespMaterial(dataFactura.mercancias[0].despMaterial);
    setCantMaterial(dataFactura.mercancias[0].cantMaterial);
    setClaveMaterial(dataFactura.mercancias[0].claveMaterial);
    setPesoMateria(dataFactura.mercancias[0].pesoMateria);

    //Tranp int info
    setTranspInt(dataFactura.mercancias[0].transpInt);

    setEntSalMerc(dataFactura.mercancias[0].entradaSalida || "");
    if (embalajeData === "") {
      fetchEmbalajeData();
    }

    setNumPedimento(dataFactura.mercancias[0].numPedimento);
    setTipoDoc(dataFactura.mercancias[0].tipoDoc);
    setRFCImportador(dataFactura.mercancias[0].RFCImportador);
    setCantidadMaterial(dataFactura.mercancias[0].cantidadMaterial);
    setMercancias(dataFactura.mercancias);
    fetchCondicionesEspeciales();
    fetchFormaFarmaceutica();

    setFraccAranc(dataFactura.mercancias[0].fraccAranc);
    setTipoMateria(dataFactura.mercancias[0].tipoMateria);
  };

  const getDriversData = async (data, dataFactura) => {
    console.log("dataFactura", dataFactura);
    console.log("Data recibida", data);
    const filteredDrivers = data.find(
      (driver) => dataFactura.idUsuario === driver._id
    );

    console.log("FilteredDrivers", filteredDrivers);

    //Set DRIVER info to values
    setRFCFigura(filteredDrivers.RFCFigura);
    setNumLicencia(filteredDrivers.numLicencia);
    setNombreFigura(filteredDrivers.name + filteredDrivers.lastname);
    setCalleFigura(filteredDrivers.calle);
    setNumExt(filteredDrivers.numExt);
    setNumInt(filteredDrivers.numInt);
    fetchMunicipioDriver(filteredDrivers.estado, filteredDrivers.municipio);
    if (filteredDrivers.colonia) {
      setColoniaFigura(filteredDrivers.colonia);
      fetchColoniaDriver(filteredDrivers.codigoPostal, filteredDrivers.colonia);
    }
    fetchEstadoDriver(filteredDrivers.pais, filteredDrivers.estado);

    setRefFigura(filteredDrivers.referencia);
    setMunicipioFigura(filteredDrivers.municipio);
    setEstadoFigura(filteredDrivers.estado);
    setPaisFigura(filteredDrivers.pais);
    setCPFigura(filteredDrivers.codigoPostal);
    fetchRemolques(permisos[0]);
  };

  const handleCartaPorte = async (isChecked) => {
    setCartaPorte(isChecked ? "Sí" : "No");

    if (isChecked == true) {
      handleInfoCartaPorte(facturaToSave);
      // setPaisOrig("");
      // setPaisDest("");

      // // let duracion = rowData.duracion;
      // let fechaOrigen = new Date(facturaToSave.fechaInicio);
      // let tiempoEstimado = getTiempoEstimado(facturaToSave.duracion);

      // let fechaDestino = fechaOrigen.getTime() + tiempoEstimado;
      // let horaMinutosDestino = new Date(fechaDestino).toLocaleTimeString().split(':');
      // let fechaSalidaHoraDest = `${new Date(fechaDestino).toISOString().split('T')[0]}T${horaMinutosDestino[0].length <= 1 ? `0${horaMinutosDestino[0]}` : horaMinutosDestino[0]}:${horaMinutosDestino[1]}`
      // setFechaHoraSalOrig(fechaSalidaHoraDest);

      // //---------------------- !Origen!  --------

      // //Información de origen
      // //Pais de origen
      // let originCountryCode = dataCountryCode(facturaToSave.originAddress);
      // setPaisOrig(originCountryCode);

      // //Estado de origen
      // let originStatesData = await fetchEstadosOrigen(originCountryCode);
      // let originStateCode = await dataStateCode(originStatesData, facturaToSave.originAddress, "Origen");
      // setEstadoOrig(originStateCode);

      // //Municipio del Origen
      // let origenMunicipios = await fetchMunicipiosOrig(originStateCode);
      // let origenMunicipioCode = await dataMunicipioCode(origenMunicipios, facturaToSave.originAddress, "Origen");
      // setMunicipioOrig(origenMunicipioCode);

      // let origenCP = getCodigoPostal(facturaToSave.originAddress, "Origen");
      // if (origenCP) {
      //   setCPOrig(origenCP);
      //   let colonias = await fetchColoniasOrig(origenCP)

      //   let coloniaCode = await getColonia(colonias, facturaToSave.originAddress, "Origen");
      //   setColoniaOrig(coloniaCode)
      // }
      // let fechaInicioOrig = facturaToSave.fechaInicio.split(' ').join('T')
      // setFechaHoraSalidaOrig(fechaInicioOrig);
      // //---------------------- !Destino  --------
      // //Información de destino

      // //Pais de destino
      // let destinationCountryCode = dataCountryCode(facturaToSave.destinationAddress);
      // setPaisDest(destinationCountryCode);

      // //Estados del Destino
      // let destinationStatesData = await fetchEstadosDestino(destinationCountryCode);
      // let destinationStateCode = await dataStateCode(destinationStatesData, facturaToSave.destinationAddress, "Destino");
      // setEstadoDest(destinationStateCode);

      // //Municipio del Destino

      // let destinationMunicipios = await fetchMunicipiosDest(destinationStateCode);
      // let destinationCode = await dataMunicipioCode(destinationMunicipios, facturaToSave.destinationAddress, "Destino");
      // setMunicipioDest(destinationCode);

      // //Codigo Postal de destino
      // let destinoCP = getCodigoPostal(facturaToSave.destinationAddress, "Destino");

      // if (destinoCP) {
      //   setCPDest(destinoCP);
      //   let colonias = await fetchColoniasDest(destinoCP);
      //   let coloniaCode = await getColonia(colonias, facturaToSave.destinationAddress, "Destino");
      //   setColoniaDest(coloniaCode);
      // }

      // setMercancias([]);

      // //Fetch drivers info
      // fetchDriverData(facturaToSave);
      // fetchTransportData(facturaToSave.vehiculo);
      // setDistance(facturaToSave.distancia);
      // setPlacaCaja(facturaToSave.contenedor);
      // setTipoRemolque(facturaToSave.tipoRemolque);

      // //Set mercancias a carta porte
      // //falta fraccion arancelaria
      // setBienesTransp(facturaToSave.mercancias[0].bienesTransp);
      // setCantMaterial(facturaToSave.mercancias[0].cantMaterial);
      // setDespMaterial(facturaToSave.mercancias[0].despMaterial);
      // setCantMaterial(facturaToSave.mercancias[0].cantMaterial);
      // setClaveMaterial(facturaToSave.mercancias[0].claveMaterial);
      // setPesoMateria(facturaToSave.mercancias[0].pesoMateria);

      // //Tranp int info
      // setTranspInt(facturaToSave.mercancias[0].transpInt);

      // setEntSalMerc(facturaToSave.mercancias[0].entradaSalida || "");
      // if (embalajeData === "") {
      //   fetchEmbalajeData();
      // }

      // setNumPedimento(facturaToSave.mercancias[0].numPedimento);
      // setTipoDoc(facturaToSave.mercancias[0].tipoDoc);
      // setRFCImportador(facturaToSave.mercancias[0].RFCImportador);
      // setCantidadMaterial(facturaToSave.mercancias[0].cantidadMaterial);
      // setMercancias(facturaToSave.mercancias);
      // fetchCondicionesEspeciales();
      // fetchFormaFarmaceutica();

      // setFraccAranc(facturaToSave.mercancias[0].fraccAranc);
      // setTipoMateria(facturaToSave.mercancias[0].tipoMateria);
    }
  };
  const confirmDeleteDriver4 = async (rowData) => {
    clearStates();

    setFacturaToSave(rowData);
    setIsDialogOpen4(true);
    fetchDollarValue();
    handleInfoCartaPorte(rowData);
    setObjetoImpuesto("");
    setTipoDoc("");
    setTipoCFDI("I");
    //Fetch customers info
    fetchCustomerRazonSocial(rowData);

    setPrecioVenta(Number(rowData.precioVenta).toFixed(2));
    setPrecioUnitViaje(Number(rowData.precioVenta).toFixed(2));
    setPrecioUnitViajeAux(Number(rowData.precioVenta).toFixed(2));

    setSubtotalViaje(Number(rowData.precioVenta * cantidadViaje).toFixed(2));
    setSubtotalViajeAux(Number(rowData.precioVenta * cantidadViaje).toFixed(2));

    setTotalViaje(Number(rowData.precioVenta * cantidadViaje).toFixed(2));
    setTotalViajeAux(Number(rowData.precioVenta * cantidadViaje).toFixed(2));
    setTipoCambio("MXN");
  };

  const fetchCustomerRazonSocial = async (rowData) => {
    try {
      const response = await fetch(`${API_URL}/api/customers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      const filteredCustomers = data.filter((customer) => {
        if (rowData.clienteFactura === customer.razonSocial) {
          return customer;
        } else if (rowData.clienteFactura === customer._id) {
          return customer;
        }
      });

      // setCustomers(filteredCustomers);
      //Set customer info to values
      setRFCRec(filteredCustomers[0].RFC);
      setCPRecep(filteredCustomers[0].codigoPostal);
      setRazonSocialRecep(filteredCustomers[0].razonSocial);
      setRegimenRecep(filteredCustomers[0].regimenFiscal);
      setEmailCliente(filteredCustomers[0].email);
    } catch (error) {
      // console.error(error);
      notifyError("No se pudieron obtener los datos del cliente");
    }
  };

  const fetchDriverData = async (rowData) => {
    const response = await fetch(`${API_URL}/api/drivers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    const filteredDrivers = data.filter((driver) => {
      return rowData.usuario === driver.nombreAlternativo;
    });

    //Set DRIVER info to values
    setRFCFigura(filteredDrivers[0].RFCFigura);
    setNumLicencia(filteredDrivers[0].numLicencia);
    setNombreFigura(filteredDrivers[0].name + filteredDrivers[0].lastname);
    setCalleFigura(filteredDrivers[0].calle);
    setNumExt(filteredDrivers[0].numExt);
    setNumInt(filteredDrivers[0].numInt);
    fetchMunicipioDriver(
      filteredDrivers[0].estado,
      filteredDrivers[0].municipio
    );
    if (filteredDrivers[0].colonia) {
      setColoniaFigura(filteredDrivers[0].colonia);
      fetchColoniaDriver(
        filteredDrivers[0].codigoPostal,
        filteredDrivers[0].colonia
      );
    }
    fetchEstadoDriver(filteredDrivers[0].pais, filteredDrivers[0].estado);

    setRefFigura(filteredDrivers[0].referencia);
    setMunicipioFigura(filteredDrivers[0].municipio);
    setEstadoFigura(filteredDrivers[0].estado);
    setPaisFigura(filteredDrivers[0].pais);
    setCPFigura(filteredDrivers[0].codigoPostal);
  };

  const fetchMunicipioDriver = async (estado, municipio) => {
    const response = await fetch(
      `${API_URL}/api/catalogomunicipios?stateCode=${estado}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    const nameMunicipio = data.find((el) => el.Value == municipio);

    setNameMunicipio(nameMunicipio.Name);
  };

  const fetchColoniaDriver = async (cp, colonia) => {
    const response = await fetch(
      `${API_URL}/api/catalogolocalidades?postalCode=${cp}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    let coloniaDriver = data.find((el) => el.Value == colonia);
    setNameColonia(coloniaDriver.Name);
  };

  const fetchEstadoDriver = async (pais, estado) => {
    try {
      const response = await fetch(
        `${API_URL}/api/catalogoestados?countryCode=${pais}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      const nameEstado = data.find((el) => el.Value === estado);

      setEstadoName(nameEstado.Name);
    } catch (error) {}
  };

  const fetchTransportData = async (vehicle) => {
    const res = await fetch(`${API_URL}/api/transports/${vehicle}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    try {
      const response = await res.json();

      if (response.status === "Ok") {
        setPesoVehiculo(response.vehicle.pesoVehiculo);
        setPlacaVehiculo(response.vehicle.placaVehiculo);
        setAnioVehiculo(response.vehicle.anioVehiculo);
        setNombreSeguro(response.vehicle.nombreSeguro);
        setPolizaSeguro(response.vehicle.numPoliza);
        setConfVehicular(response.vehicle.confVehicular);
        console.log("Configuracion vehicular", response.vehicle.confVehicular);
        setPermisoSICT(response.vehicle.permisoSCT);
        setNumPermiso(response.vehicle.numPermisoSCT);
        setUseContainer(response.vehicle.remolqueRequerido);
        setMedAmbiente(response.vehicle.medAmbiente);
        setSeguroMedAmbiente(response.vehicle.aseguraMedAmbiente);
        setPolizaMedAmbiente(response.vehicle.polizaMedAmbiente);
      } else {
        throw response;
      }
    } catch (err) {
      // console.error("Error en peticion", err);
      notifyError(
        err?.message || "Error al obtener la información del transporte"
      );
    }
  };
  const getDocType = (docName) => {
    return docName.includes(".docx") ? "word" : "pdf";
  };

  const actionBodyTemplate2 = (rowData) => {
    return (
      <React.Fragment>
        <IoDocuments
          className="documentosInicio"
          onClick={() => confirmDeleteDriver2(rowData)}
        />
        {/* <Button
          icon="pi pi-eye"
          rounded
          outlined
          severity="primary"
          placeholder="Guardar"
          className="ButtonPDF"
         
          style={{ fontSize: "1.5rem", color: "black" }}
        /> */}
      </React.Fragment>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            style={{ marginLeft: "30px" }}
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar"
          />
        </span>
      </div>
    );
  };

  const notifyComprobante = () => {
    toast.success("¡Comprobante subido con éxito!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };
  const notifyCancelacion = () => {
    toast.success("¡Factura cancelada con éxito!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };
  const notifyMail = (message) => {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const handleAddMerch = () => {
    const nuevaMercancia = {
      tipoMateria: "",
      tipoDocumento: "",
    };

    setMercancias([...mercancias, nuevaMercancia]);
  };

  const handleInputChange = (target, index) => {
    let currentArray = mercancias[index];
    let valueInput = target.value;
    let newObj = {
      ...currentArray,
      [target.name]: valueInput,
    };

    let mercanciasArray = [...mercancias];
    mercanciasArray[index] = newObj;
    setMercancias([...mercanciasArray]);
  };
  const fetchEmbalajeData = async () => {
    const response = await fetch(`${API_URL}/api/catalagos/embalaje`);
    const data = await response.json();
    setEmbalajeData(data.response);
  };
  const handleMaterialPeligroso = (isChecked, index) => {
    // mercancias[1].matPeligroso =
    let valueChecked = isChecked ? "Sí" : "No";
    let currentArray = mercancias[index];

    setMedAmbiente(isChecked);

    let newObj = {
      ...currentArray,
      matPeligroso: valueChecked,
    };
    let mercanciasArray = [...mercancias];
    mercanciasArray[index] = newObj;
    setMercancias([...mercanciasArray]);
  };

  const fetchFormaFarmaceutica = async () => {
    const response = await fetch(`${API_URL}/api/catalagos/formaFarmaceutica`);

    const data = await response.json();
    setFormaFarmaceutica(data.response);
  };

  const fetchCondicionesEspeciales = async () => {
    const response = await fetch(
      `${API_URL}/api/catalagos/condicionesEspeciales`
    );
    const data = await response.json();

    setCondicionesEspeciales(data.response);
  };
  const displayComprobanteFile = (options) => {
    if (options.comprobantePago) {
      return (
        <React.Fragment>
          <Button
            // icon="pi pi-file-pdf"
            className="pi pi-file-pdf btn__pi"
            rounded
            outlined
            severity="primary"
            placeholder="Mostrar archivo"
            onClick={(e) => {
              showPdfComprobante(options.comprobantePago);
            }}
            style={{ color: "#ad0b00" }}
          />
        </React.Fragment>
      );
    }
  };

  return (
    <div>
      <div className="content-wrapper container__block">
        <PageInfo heading={"DATT - Tracking | Reportes"} />

        <section className="content">
          <div className="container-fluid">
            <section className="content">
              <div
                className=""
                style={{ backgroundColor: "white", padding: "1.3rem" }}
              >
                <h3>Viajes asignados</h3>

                <section className="">
                  <div className="cartaPorte__group">
                    <div className="cartaPorte__group_elements">
                      <label className="FormLabel">Id de Factura</label>
                      <input
                        className="InputForm"
                        name="idFactura"
                        value={filterRoutes.idFactura}
                        onChange={handleChangeFilter}
                      ></input>
                    </div>
                    {permisos && permisos.length > 1 ? (
                      <div className="cartaPorte__group_elements">
                        <label className="FormLabel">Empresa</label>
                        <select
                          className="SelectTrigger"
                          style={{ width: "100%" }}
                          name="empresa"
                          value={filterRoutes.empresa}
                          onChange={handleChangeFilter}
                        >
                          <option value="">Selecciona una empresa</option>
                          {permisos && permisos.length > 0
                            ? permisos.map((permiso, index) => (
                                <option key={index} value={permiso}>
                                  {permiso}
                                </option>
                              ))
                            : ""}
                        </select>
                      </div>
                    ) : (
                      ""
                    )}

                    {permisos && filterRoutes.empresa !== "" ? (
                      <>
                        <div className="cartaPorte__group_elements">
                          <label className="FormLabel">Cliente</label>
                          <select
                            className="SelectTrigger"
                            name="clienteFactura"
                            onChange={handleChangeFilter}
                            value={filterRoutes.clienteFactura}
                          >
                            <option value="">Selecciona un cliente</option>
                            {customersByCompany &&
                            customersByCompany.length > 0 ? (
                              customersByCompany.map((customer) => (
                                <option key={customer._id} value={customer._id}>
                                  {customer.razonSocial}
                                </option>
                              ))
                            ) : (
                              <option disabled>
                                {" "}
                                No hay clientes registrados
                              </option>
                            )}
                          </select>
                        </div>

                        <div className="cartaPorte__group_elements">
                          <label className="FormLabel">Operador</label>
                          <select
                            className="SelectTrigger"
                            name="usuario"
                            value={filterRoutes.usuario}
                            onChange={handleChangeFilter}
                          >
                            <option value="">Selecciona un operador</option>
                            {driversData && driversData.length > 0 ? (
                              driversData.map((driver) => (
                                <option key={driver._id} value={driver._id}>
                                  {driver.nombreAlternativo}
                                </option>
                              ))
                            ) : (
                              <option disabled>
                                No hay operadores registrados
                              </option>
                            )}
                          </select>
                        </div>

                        <div className="cartaPorte__group_elements">
                          <label className="FormLabel">Unidad</label>
                          <select
                            className="SelectTrigger"
                            name="vehiculo"
                            value={filterRoutes.vehiculo}
                            onChange={handleChangeFilter}
                          >
                            <option value="">Selecciona una unidad</option>
                            {transportsByCompany &&
                            transportsByCompany.length > 0 ? (
                              transportsByCompany.map((transport) => (
                                <option
                                  key={transport._id}
                                  value={transport._id}
                                >
                                  {transport.numUnidad}
                                </option>
                              ))
                            ) : (
                              <option disabled>
                                No hay unidades registradas
                              </option>
                            )}
                          </select>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div className="cartaPorte__group_elements">
                      <label className="FormLabel">Estatus</label>
                      <select
                        className="SelectTrigger"
                        style={{ width: "100%" }}
                        name="estatus"
                        // value={filtersFacturas.idFactura}
                        // onChange={handleChangeFilter}
                      >
                        <option value="">Selecciona un Estatus</option>
                        {status && status.length > 0
                          ? status.map((status, index) => (
                              <option key={index} value={status}>
                                {status}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>

                    <div
                      className="cartaPorte__group_elements"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <div className="btn__container_search">
                        <button
                          type="submit"
                          disabled={isLoadingSearch}
                          className="btn__login"
                          style={{
                            marginTop: 10,
                            width: "240px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={fetchRoutesRData}
                        >
                          {
                            isLoadingSearch ? (
                              <SyncLoader
                                color="white"
                                loading={isLoadingSearch}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                speedMultiplier={0.7}
                              />
                            ) : (
                              "Buscar Viajes"
                            )
                            // <FaSearch />
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
                <header
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Button
                    severity="success"
                    type="button"
                    icon="pi pi-file-excel"
                    rounded
                    onClick={exportExcel}
                    data-pr-tooltip="XLS"
                    style={{ fontSize: "1.5rem", color: "green" }}
                  />
                </header>
                <div className="card">
                  <DataTable
                    value={route}
                    header={renderHeader}
                    filters={filters}
                    paginator
                    ref={dt}
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: "50rem" }}
                    editMode="row"
                    dataKey="_id"
                  >
                    <Column field="_id" header="ID" hidden />
                    <Column
                      field="empresa"
                      header="Empresa"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="usuario"
                      header="Usuario"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="origen"
                      header="Origen"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="destino"
                      header="Destino"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="distancia"
                      header="Distancia (km)"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="duracion"
                      header="Duración (horas)"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="diesel"
                      header="Gastos Diesel"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="clienteFactura"
                      header="Cliente Factura"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="clienteFlete"
                      header="Cliente Flete"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="vehiculo"
                      header="Vehículo"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="fechaAsignado"
                      header="Fecha asignado"
                      sortable
                      style={{ width: "25%" }}
                    />
                    <Column
                      header="Documentos de viaje"
                      body={actionBodyTemplate2}
                      exportable={false}
                      style={{ minWidth: "12rem" }}
                    />
                    <Column
                      field="estatus"
                      header="Estatus"
                      sortable
                      style={{ width: "25%" }}
                      body={(rowData) => (
                        <div
                          style={{
                            background: getStatusColor(rowData.estatus),
                            padding: "6px",
                            borderRadius: "5px",
                            color: "white",
                            // boxShadow: "0 0 5px rgba(0, 0, 0, 0.8)",
                          }}
                        >
                          {rowData.estatus}
                        </div>
                      )}
                    />
                    <Column
                      field="confirmacion"
                      header="Confirmación"
                      body={(rowData) => {
                        if (rowData.documentoFinalizado) {
                          return (
                            <button
                              onClick={() => {
                                if (rowData.documentoFinalizado) {
                                  showPdfConfirmacion(
                                    rowData.documentoFinalizado
                                  );
                                }
                              }}
                              className="pi pi-file-pdf btn__pi"
                              style={{ color: "#ad0b00" }}
                              disabled={!rowData.documentoFinalizado}
                            ></button>
                          );
                        }
                      }}
                    />

                    {/* <Column
                      field="cfdi40"
                      header="CFDI 4.0"
                      body={(rowData) => {
                        if (rowData.idCFDI || rowData.facturaCFDI) {
                          return (
                            <FaFileInvoiceDollar
                              className="documentosInicio"
                              onClick={() => {
                                if (rowData.idFactura) {
                                  fetchCustomerRazonSocial(rowData);
                                  // confirmDeleteDriver();
                                  showDialogCFDI();
                                  setIdFactura(rowData.idCFDI)
                                  setIdViaje(rowData._id);
                                } else if (rowData.facturaCFDI) {
                                  showPdf(rowData.factura);
                                }
                              }

                              }
                            />

                          )
                        }

                      }}
                    /> */}

                    <Column
                      field="facturacion"
                      header="Factura"
                      body={(rowData) => {
                        if (rowData.idFactura || rowData.factura) {
                          return (
                            <FaFileInvoiceDollar
                              className="documentosInicio"
                              onClick={() => {
                                if (rowData.idFactura) {
                                  fetchCustomerRazonSocial(rowData);
                                  confirmDeleteDriver();
                                  setIdFactura(rowData.idFactura);
                                  setIdViaje(rowData._id);
                                  setEmisorFactura(rowData.emisor);
                                } else if (rowData.factura) {
                                  showPdf(rowData.factura);
                                }
                              }}
                            />
                            // <button
                            //   icon=""
                            //   onClick={() => {
                            //     if (rowData.idFactura) {
                            //       fetchCustomerRazonSocial(rowData);
                            //       confirmDeleteDriver();
                            //       setIdFactura(rowData.idFactura)
                            //       setIdViaje(rowData._id);
                            //     } else if (rowData.factura) {
                            //       showPdf(rowData.factura);
                            //     }
                            //   }}
                            //   className="btn__pi"
                            //   disabled={!rowData.idFactura && !rowData.factura}
                            // >
                            //   {rowData.idFactura || rowData.factura ? "Ver Factura" : "No hay archivo"}
                            // </button>
                          );
                        }
                      }}
                    />

                    <Column
                      field="fechaFacturacion"
                      header="Fecha Facturación"
                      style={{ minWidth: "12rem", marginLeft: "5px" }}
                      sortable
                    />

                    {/*Disabled until finish module */}
                    <Column
                      field="facturacion"
                      header="Generar Factura/Carta Porte"
                      style={{ minWidth: "12rem", marginLeft: "5px" }}
                      body={actionBodyTemplate4}
                    />

                    <Column
                      hidden
                      field="facturacion"
                      header="Subir Factura/Carta Porte"
                      style={{ minWidth: "12rem", marginLeft: "5px" }}
                      body={actionBodyTemplate5}
                    />

                    <Column
                      field="comprobantePago"
                      header="Comprobante"
                      body={displayComprobanteFile}
                    />

                    <Column
                      field="comprobante"
                      header="Subir Comprobante"
                      style={{ minWidth: "12rem", marginLeft: "5px" }}
                      body={actionBodyTemplate3}
                    />
                  </DataTable>
                  <Dialog.Root
                    open={isDialogOpen}
                    onOpenChange={() => setIsDialogOpen(false)}
                  >
                    <Dialog.Portal>
                      <Dialog.Overlay className="DialogOverlay" />
                      <Dialog.Content
                        className="DialogContentFactura"
                        style={{ backgroundColor: "white", width: "40rem" }}
                      >
                        <Dialog.Title
                          className="DialogTitle"
                          style={{ textAlign: "center" }}
                        >
                          CFDI 4.0 con Complemento Carta Porte 3.0
                        </Dialog.Title>
                        <div className="facturaId">
                          <p style={{ margin: "1rem 0" }}>
                            ID de factura asignado:<br></br>{" "}
                            <strong>{idFactura}</strong>
                          </p>
                        </div>

                        <div
                          style={{
                            margin: "1rem 0 2rem 0",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            aria-label="Close"
                            className="btn__login"
                            style={{
                              marginRight: "10px",
                              width: "150px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={descargarFacturaPDF}
                          >
                            {isLoadingFactura ? (
                              <SyncLoader
                                color="white"
                                loading={isLoadingFactura}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                speedMultiplier={0.7}
                              />
                            ) : (
                              "Descargar Factura"
                            )}
                          </Button>
                          <Button
                            aria-label="Close"
                            className="btn__login"
                            onClick={descargarFacturaXML}
                            style={{
                              width: "150px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {isLoadingXML ? (
                              <SyncLoader
                                color="white"
                                loading={isLoadingXML}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                speedMultiplier={0.7}
                              />
                            ) : (
                              "Descargar XML"
                            )}
                          </Button>
                        </div>
                        <hr></hr>
                        <h2 className="DialogTitle">
                          Enviar factura por correo electrónico
                        </h2>
                        <form
                          id="formRoute"
                          encType="multipart/form-data"
                          onSubmit={(e) => handleSendFactura(e)}
                          style={{ marginTop: "1rem" }}
                        >
                          <label className="FormLabel" style={{ margin: "0" }}>
                            Correo electrónico
                          </label>
                          <input
                            className="Input"
                            type="mail"
                            required
                            name="numero"
                            placeholder="E-mail"
                            onChange={(e) => setEmailCliente(e.target.value)}
                            value={emailCliente}
                          />

                          <div
                            style={{
                              margin: "20px 0",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <input
                              className="btn__login"
                              type="submit"
                              value="Enviar Factura"
                              style={{ width: "140px" }}
                            />
                          </div>
                        </form>

                        <hr></hr>
                        <div className="cfdiCancelacion">
                          <div className="cfdiCancelacion__group">
                            <h2 className="DialogTitle">Cancelación de CFDI</h2>
                            <label
                              className="FormLabel"
                              style={{ marginTop: "1rem" }}
                            >
                              Motivo de cancelación
                            </label>
                            <select
                              className="SelectTrigger"
                              style={{ width: "100%" }}
                              value={motivoCancelacion}
                              onChange={(e) =>
                                setMotivoCancelacion(e.target.value)
                              }
                            >
                              <option value="" disabled>
                                Elige una opción
                              </option>
                              <option value="01">
                                Comprobante emitido con errores con relación
                              </option>
                              <option value="02">
                                {" "}
                                Comprobante emitido con errores sin relación
                              </option>
                              <option value="04">
                                No se llevó a cabo la operación
                              </option>
                              <option value="04">
                                Operación nominativa relacionada con una factura
                                global
                              </option>
                            </select>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              margin: "1rem 0",
                            }}
                          >
                            <Button
                              aria-label="Close"
                              className="EliminarButton"
                              style={{ marginLeft: "20px" }}
                              onClick={CancelarCFDI}
                            >
                              Cancelar CFDI
                            </Button>
                          </div>
                        </div>
                        {/*                         
                        
                        {/* <hr></hr>  */}
                        {/* <Dialog.Close asChild>
                          <Button
                            aria-label="Close"
                            className="EliminarButton"
                          >
                            Cerrar
                          </Button>
                        </Dialog.Close> */}
                      </Dialog.Content>
                    </Dialog.Portal>
                  </Dialog.Root>

                  <Dialog.Root
                    open={isDialogCFDIOpen}
                    onOpenChange={() => setIsDialogCFDIOpen(false)}
                  >
                    <Dialog.Portal>
                      <Dialog.Overlay className="DialogOverlay" />
                      <Dialog.Content
                        className="DialogContentFactura"
                        style={{ backgroundColor: "white", width: "40rem" }}
                      >
                        <Dialog.Title
                          className="DialogTitle"
                          style={{ textAlign: "center" }}
                        >
                          CFDI 4.0
                        </Dialog.Title>
                        <div className="facturaId">
                          <p style={{ margin: "1rem 0" }}>
                            ID de factura asignado:<br></br>{" "}
                            <strong>{idFactura}</strong>
                          </p>
                        </div>

                        <div
                          style={{
                            margin: "1rem 0 2rem 0",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            aria-label="Close"
                            className="btn__login"
                            style={{
                              marginRight: "10px",
                              width: "150px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={descargarFacturaPDF}
                          >
                            {isLoadingFactura ? (
                              <SyncLoader
                                color="white"
                                loading={isLoadingFactura}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                speedMultiplier={0.7}
                              />
                            ) : (
                              "Descargar Factura"
                            )}
                          </Button>
                          <Button
                            aria-label="Close"
                            className="btn__login"
                            onClick={descargarFacturaXML}
                            style={{
                              width: "150px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {isLoadingXML ? (
                              <SyncLoader
                                color="white"
                                loading={isLoadingXML}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                speedMultiplier={0.7}
                              />
                            ) : (
                              "Descargar XML"
                            )}
                          </Button>
                        </div>
                        <hr></hr>
                        <h2 className="DialogTitle">
                          Enviar factura por correo electrónico
                        </h2>
                        <form
                          id="formRoute"
                          encType="multipart/form-data"
                          onSubmit={(e) => handleSendFactura(e)}
                          style={{ marginTop: "1rem" }}
                        >
                          <label className="FormLabel" style={{ margin: "0" }}>
                            Correo electrónico
                          </label>
                          <input
                            className="Input"
                            type="mail"
                            required
                            name="numero"
                            placeholder="E-mail"
                            onChange={(e) => setEmailCliente(e.target.value)}
                            value={emailCliente}
                          />

                          <div
                            style={{
                              margin: "20px 0",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <input
                              className="btn__login"
                              type="submit"
                              value="Enviar Factura"
                              style={{ width: "140px" }}
                            />
                          </div>
                        </form>

                        <hr></hr>
                        <div className="cfdiCancelacion">
                          <div className="cfdiCancelacion__group">
                            <h2 className="DialogTitle">Cancelación de CFDI</h2>
                            <label
                              className="FormLabel"
                              style={{ marginTop: "1rem" }}
                            >
                              Motivo de cancelación
                            </label>
                            <select
                              className="SelectTrigger"
                              style={{ width: "100%" }}
                              value={motivoCancelacion}
                              onChange={(e) =>
                                setMotivoCancelacion(e.target.value)
                              }
                            >
                              <option value="" disabled>
                                Elige una opción
                              </option>
                              <option value="01">
                                Comprobante emitido con errores con relación
                              </option>
                              <option value="02">
                                {" "}
                                Comprobante emitido con errores sin relación
                              </option>
                              <option value="04">
                                No se llevó a cabo la operación
                              </option>
                              <option value="04">
                                Operación nominativa relacionada con una factura
                                global
                              </option>
                            </select>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              margin: "1rem 0",
                            }}
                          >
                            <Button
                              aria-label="Close"
                              className="EliminarButton"
                              style={{ marginLeft: "20px" }}
                              onClick={CancelarCFDI}
                            >
                              Cancelar CFDI
                            </Button>
                          </div>
                        </div>
                        {/*                         
                        
                        {/* <hr></hr>  */}
                        {/* <Dialog.Close asChild>
                          <Button
                            aria-label="Close"
                            className="EliminarButton"
                          >
                            Cerrar
                          </Button>
                        </Dialog.Close> */}
                      </Dialog.Content>
                    </Dialog.Portal>
                  </Dialog.Root>

                  <Dialog.Root
                    open={isDialogOpen2}
                    onOpenChange={() => setIsDialogOpen2(false)}
                  >
                    <Dialog.Portal>
                      <Dialog.Overlay className="DialogOverlay" />
                      <Dialog.Content className="DialogContentFactura dialogContentViaje">
                        <Dialog.Title className="DialogTitle">
                          Documentos del viaje
                          <IoCloseSharp
                            onClick={(e) => setIsDialogOpen2(false)}
                            className="closeDialogIcon"
                            style={{
                              position: "absolute",
                              right: "20px",
                              top: "10px",
                            }}
                          />
                        </Dialog.Title>
                        <Dialog.Description className="DialogDescription">
                          Abajo se muestran los documentos que se anexaron por
                          cliente.
                        </Dialog.Description>
                        {/* {
                          clientDocsData.length > 0 && clientDocsData ?
                            clientDocsData.map((doc) => {
                              let docType = getDocType(doc.originalname);
                              return (
                                <div className="docs__container" onClick={() => { window.open(`${API_URL}${doc.path}`); }}>
                                  {
                                    docType === "word" ? (<FaRegFileWord style={{ color: "#073b8d", fontSize: "1.3rem" }} />) : (<i className="pi pi-file-pdf btn__pi"></i>)
                                  }


                                  <p >
                                    {doc.originalname}
                                  </p>

                                </div>

                              )
                            }) : ""

                        } */}

                        {facturaToSave && (
                          <div style={{ margin: "1rem 0" }}>
                            {facturaToSave.documentos.map((documento) => {
                              let docType = getDocType(documento.originalname);
                              return (
                                <div
                                  className="docs__container"
                                  onClick={() => {
                                    window.open(`${API_URL}${documento.path}`);
                                  }}
                                >
                                  {docType === "word" ? (
                                    <FaRegFileWord
                                      style={{
                                        color: "#073b8d",
                                        fontSize: "1.3rem",
                                      }}
                                    />
                                  ) : (
                                    <i className="pi pi-file-pdf btn__pi"></i>
                                  )}

                                  <p>{documento.originalname}</p>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </Dialog.Content>
                    </Dialog.Portal>
                  </Dialog.Root>

                  <Dialog.Root
                    open={isDialogOpen3}
                    onOpenChange={() => setIsDialogOpen3(false)}
                  >
                    <Dialog.Portal>
                      <Dialog.Overlay className="DialogOverlay" />
                      <Dialog.Content
                        className="DialogContentFactura"
                        style={{ width: "35rem", backgroundColor: "white" }}
                      >
                        <Dialog.Title className="DialogTitle">
                          Subir comprobante de pago
                        </Dialog.Title>
                        <Dialog.Description
                          className="DialogDescription"
                          style={{ marginBottom: "0" }}
                        >
                          Selecciona el archivo PDF del comprobante.
                        </Dialog.Description>

                        <form
                          id="formRoute"
                          encType="multipart/form-data"
                          onSubmit={(e) => handleSaveComprobante(e)}
                        >
                          <label
                            className="FormLabel"
                            style={{ margin: "1rem 0" }}
                          >
                            Selecciona el archivo
                          </label>
                          <input
                            className="Input"
                            type="file"
                            required
                            accept=".pdf"
                            name="comprobante"
                            onChange={(e) => setComprobante(e.target.files[0])}
                          />
                          <div
                            style={{
                              margin: "20px 0",
                              display: "flex",
                              flexDirection: "row-reverse",
                            }}
                          >
                            <input
                              className="AprobarButton"
                              type="submit"
                              value="Subir Comprobante"
                            />
                            <Dialog.Close asChild>
                              <Button
                                aria-label="Close"
                                className="EliminarButton"
                                style={{ marginRight: "1rem" }}
                              >
                                Cancelar
                              </Button>
                            </Dialog.Close>
                          </div>
                        </form>
                      </Dialog.Content>
                    </Dialog.Portal>
                  </Dialog.Root>

                  <Dialog.Root
                    open={isDialogOpen5}
                    onOpenChange={() => setIsDialogOpen5(false)}
                  >
                    <Dialog.Portal>
                      <Dialog.Overlay className="DialogOverlay" />
                      <Dialog.Content className="DialogContentFactura">
                        <Dialog.Title className="DialogTitle">
                          Subir factura
                        </Dialog.Title>
                        <Dialog.Description className="DialogDescription">
                          Selecciona el archivo PDF de la factura y completa la
                          información.
                        </Dialog.Description>

                        <form
                          id="formRoute"
                          encType="multipart/form-data"
                          onSubmit={(e) => handleSaveConfirmation(e)}
                        >
                          <label className="FormLabel">
                            ID/Número de factura
                          </label>
                          <input
                            className="Input"
                            type="text"
                            required
                            name="numero"
                            placeholder="Número de factura"
                            onChange={(e) => setNumero(e.target.value)}
                          />
                          <label className="FormLabel">
                            Monto de la factura
                          </label>
                          <input
                            className="Input"
                            type="text"
                            required
                            name="monto"
                            placeholder="Monto"
                            onChange={(e) => setMonto(e.target.value)}
                          />
                          <label className="FormLabel">
                            Selecciona el archivo
                          </label>
                          <input
                            className="Input"
                            type="file"
                            required
                            accept=".pdf"
                            name="factura"
                            onChange={(e) => setFactura(e.target.files[0])}
                          />
                          <div style={{ margin: "30px" }}>
                            <input
                              className="CancelarButton"
                              type="submit"
                              value="Subir Factura"
                            />
                            <Dialog.Close asChild>
                              <Button
                                aria-label="Close"
                                className="EliminarButton"
                              >
                                Cancelar
                              </Button>
                            </Dialog.Close>
                          </div>
                        </form>
                      </Dialog.Content>
                    </Dialog.Portal>
                  </Dialog.Root>
                  {/*Carta Porte */}
                  <Dialog.Root
                    open={isDialogOpen4}
                    onOpenChange={() => {
                      clearStates();
                      setIsDialogOpen4(false);
                    }}
                  >
                    <Dialog.Portal>
                      <Dialog.Overlay className="DialogOverlay" />
                      <Dialog.Content
                        className="DialogContentCP"
                        style={{ backgroundColor: "white" }}
                      >
                        <Dialog.Title className="DialogTitle">
                          Factura CFDI 4.0{" "}
                          {cartaPorte === "Sí"
                            ? "con complemento Carta Porte 3.0"
                            : ""}
                        </Dialog.Title>
                        <Dialog.Description className="DialogDescription">
                          Completa los campos para generar una Factura{" "}
                          {cartaPorte === "Sí"
                            ? "con complemento Carta Porte 3.0"
                            : ""}
                          <br></br>
                          Los datos con * son obligatorios.
                          <br></br>
                          <a
                            href="http://pys.sat.gob.mx/PyS/catPyS.aspx"
                            target="_blank"
                          >
                            Clic aquí para ver las Claves de Productos y
                            Servicios del SAT
                          </a>
                          <div className="cartaPorte__group_elements_checkbox">
                            <Checkbox.Root
                              className="CheckboxRoot"
                              checked={cartaPorte === "Sí" ? true : false}
                              onCheckedChange={(isChecked) => {
                                handleCartaPorte(isChecked);
                              }}
                            >
                              <Checkbox.Indicator className="CheckboxIndicator">
                                <CheckIcon />
                              </Checkbox.Indicator>
                            </Checkbox.Root>

                            <label
                              style={{ marginLeft: "1rem" }}
                              className="FormLabel"
                              htmlFor="c1"
                            >
                              Carta Porte 3.0
                            </label>
                          </div>
                        </Dialog.Description>{" "}
                        <form onSubmit={(e) => handleGenCartaPorte(e)}>
                          <div>
                            <hr></hr>
                            <h3 style={{ marginBottom: "30px" }}>Emisor</h3>

                            <div
                              className="cartaPorte__group_elements"
                              style={{ marginBottom: "2rem" }}
                            >
                              <label className="FormLabel" htmlFor="emisor">
                                Emisor de la Factura
                              </label>
                              <select
                                id="emisor"
                                className="SelectTrigger"
                                onChange={(e) => setEmisor(e.target.value)}
                                value={emisor}
                                required
                              >
                                <option value="" disabled>
                                  Selecciona un Emisor
                                </option>
                                {permisos && permisos.length > 0
                                  ? permisos.map((permiso, index) => (
                                      <option value={permiso} key={index}>
                                        {permiso}
                                      </option>
                                    ))
                                  : ""}
                              </select>
                            </div>

                            <hr></hr>
                            <h3 style={{ marginBottom: "30px" }}>
                              Datos del Receptor
                            </h3>
                            <div className="cartaPorte__group">
                              <div className="cartaPorte__group_elements">
                                <label htmlFor="name" className="FormLabel">
                                  Razón Social *
                                </label>
                                <input
                                  disabled
                                  type="text"
                                  className="InputForm inputDisabled"
                                  onChange={(e) =>
                                    setRazonSocialRecep(e.target.value)
                                  }
                                  required
                                  value={razonSocialRecep}
                                ></input>
                              </div>

                              <div className="cartaPorte__group_elements">
                                <label htmlFor="name" className="FormLabel">
                                  Código Postal *
                                </label>
                                <input
                                  disabled
                                  type="number"
                                  className="InputForm"
                                  value={cpRecep}
                                  required
                                  onChange={(e) => setCPRecep(e.target.value)}
                                ></input>
                              </div>
                              <div className="cartaPorte__group_elements">
                                <label htmlFor="name" className="FormLabel">
                                  RFC *
                                </label>
                                <input
                                  disabled
                                  type="text"
                                  className="InputForm"
                                  required
                                  onChange={(e) => setRFCRec(e.target.value)}
                                  value={rfcRecp}
                                ></input>
                              </div>
                              <div className="cartaPorte__group_elements">
                                <label htmlFor="name" className="FormLabel">
                                  Régimen Fiscal *
                                </label>
                                <select
                                  disabled
                                  onChange={(e) =>
                                    setRegimenRecep(e.target.value)
                                  }
                                  className="SelectTrigger"
                                  value={regimenRecep}
                                  required
                                >
                                  <option value="">Escoge una opción</option>
                                  <option value="601">
                                    General de Ley Personas Morales
                                  </option>
                                  <option value="603">
                                    Personas Morales con Fines no Lucrativos
                                  </option>
                                  <option value="605">
                                    Sueldos y Salarios e Ingresos Asimilados a
                                    Salarios
                                  </option>
                                  <option value="606">Arrendamiento</option>
                                  <option value="608">Demás ingresos</option>
                                  <option value="610">
                                    Residentes en el Extranjero sin
                                    Establecimiento Permanente en México
                                  </option>
                                  <option value="611">
                                    Ingresos por Dividendos (socios y
                                    accionistas)
                                  </option>
                                  <option value="612">
                                    Personas Físicas con Actividades
                                    Empresariales y Profesionales
                                  </option>
                                  <option value="614">
                                    Ingresos por intereses
                                  </option>
                                  <option value="616">
                                    Sin obligaciones fiscales
                                  </option>
                                  <option value="620">
                                    Sociedades Cooperativas de Producción que
                                    optan por diferir sus ingresos
                                  </option>
                                  <option value="621">
                                    Incorporación Fiscal
                                  </option>
                                  <option value="622">
                                    Actividades Agrícolas, Ganaderas, Silvícolas
                                    y Pesqueras
                                  </option>
                                  <option value="623">
                                    Opcional para Grupos de Sociedades
                                  </option>
                                  <option value="624">Coordinados</option>
                                  <option value="628">Hidrocarburos</option>
                                  <option value="607">
                                    Régimen de Enajenación o Adquisición de
                                    Bienes
                                  </option>
                                  <option value="629">
                                    De los Regímenes Fiscales Preferentes y de
                                    las Empresas Multinacionales
                                  </option>
                                  <option value="630">
                                    Enajenación de acciones en bolsa de valores
                                  </option>
                                  <option value="615">
                                    Régimen de los ingresos por obtención de
                                    premios
                                  </option>
                                  <option value="625">
                                    Régimen de las Actividades Empresariales con
                                    ingresos a través de Plataformas
                                    Tecnológicas
                                  </option>
                                </select>
                              </div>
                            </div>

                            <hr></hr>
                            <h3 style={{ marginBottom: "30px" }}>
                              Datos de la Factura
                            </h3>

                            <div className="cartaPorte__group_elements">
                              <label className="FormLabel" htmlFor="name">
                                Tipo de CFDI *
                              </label>
                              <select
                                id="tipoCFDI"
                                className="SelectTrigger"
                                onChange={(e) => setTipoCFDI(e.target.value)}
                                value={tipoCFDI}
                                required
                              >
                                <option value="" disabled>
                                  Selecciona una opción
                                </option>
                                <option value="T">T - Traslado</option>
                                <option value="I">I - Ingreso</option>
                              </select>
                            </div>

                            <div className="cartaPorte__group">
                              <div className="cartaPorte__group_elements">
                                <label htmlFor="name" className="FormLabel">
                                  Uso de CFDI *
                                </label>
                                <select
                                  className="SelectTrigger"
                                  onChange={(e) => {
                                    setUsoCFDI(e.target.value);
                                  }}
                                  required
                                  value={usoCFDI}
                                >
                                  <option value="" disabled>
                                    Escoge una opción
                                  </option>
                                  <option value="G01">
                                    Adquisición de mercancías
                                  </option>
                                  <option value="G02">
                                    Devoluciones, descuentos o bonificaciones{" "}
                                  </option>
                                  <option value="G03">Gastos en general</option>
                                  <option value="I01">Construcciones</option>
                                  <option value="I02">
                                    Mobiliario y equipo de oficina por
                                    inversiones{" "}
                                  </option>
                                  <option value="I03">
                                    Equipo de transporte{" "}
                                  </option>
                                  <option value="I04">
                                    Equipo de cómputo y accesorios{" "}
                                  </option>
                                  <option value="I05">
                                    Dados, troqueles, moldes, matrices y
                                    herramental{" "}
                                  </option>
                                  <option value="I06">
                                    Comunicaciones telefónicas{" "}
                                  </option>
                                  <option value="I07">
                                    Comunicaciones satelitales{" "}
                                  </option>
                                  <option value="I08">
                                    Otra maquinaria y equipo{" "}
                                  </option>
                                  <option value="D01">
                                    Honorarios médicos, dentales y gastos
                                    hospitalarios.{" "}
                                  </option>
                                  <option value="D02">
                                    Gastos médicos por incapacidad o
                                    discapacidad{" "}
                                  </option>
                                  <option value="D03">
                                    Gastos funerales.{" "}
                                  </option>
                                  <option value="D04">Donativos </option>
                                  <option value="D05">
                                    Intereses reales efectivamente pagados por
                                    créditos hipotecarios (casa habitación).{" "}
                                  </option>
                                  <option value="D06">
                                    Aportaciones voluntarias al SAR.{" "}
                                  </option>
                                  <option value="D07">
                                    Primas por seguros de gastos médicos.{" "}
                                  </option>
                                  <option value="D08">
                                    Gastos de transportación escolar
                                    obligatoria.{" "}
                                  </option>
                                  <option value="D09">
                                    Depósitos en cuentas para el ahorro, primas
                                    que tengan como base planes de pensiones.{" "}
                                  </option>
                                  <option value="D10">
                                    Pagos por servicios educativos
                                    (colegiaturas){" "}
                                  </option>
                                  <option value="CP01">Pagos</option>
                                  <option value="CN01">Nómina</option>
                                  <option value="S01">
                                    Sin Efectos Fiscales{" "}
                                  </option>
                                </select>
                              </div>

                              {tipoCFDI === "I" ? (
                                <>
                                  <div className="cartaPorte__group_elements">
                                    <label htmlFor="name" className="FormLabel">
                                      Forma de Pago *
                                    </label>

                                    <select
                                      className="SelectTrigger"
                                      onChange={(e) => {
                                        setFormaPago(e.target.value);
                                        handleFormaPagoVal(e.target.value);
                                      }}
                                      value={formaPago}
                                      required
                                    >
                                      <option value="" disabled>
                                        Escoge una opción
                                      </option>
                                      <option value="01">01 - Efectivo</option>
                                      <option value="02">
                                        02 - Cheque nominativo
                                      </option>
                                      <option value="03">
                                        03 - Transferencia electrónica de fondos
                                      </option>
                                      <option value="04">
                                        04 - Tarjeta de Crédito
                                      </option>
                                      <option value="05">
                                        05 - Monedero Electrónico
                                      </option>
                                      <option value="06">
                                        06 - Dinero Electrónico
                                      </option>
                                      <option value="08">
                                        08 - Vales de Despensa
                                      </option>
                                      <option value="12">
                                        12 - Dación de Pago
                                      </option>
                                      <option value="13">
                                        13 - Pago por subrogación
                                      </option>
                                      <option value="14">
                                        14 - Pago por Consignación
                                      </option>
                                      <option value="15">
                                        15 - Condonación
                                      </option>
                                      <option value="17">
                                        17 - Compensación
                                      </option>
                                      <option value="23">
                                        23 - Renovación
                                      </option>
                                      <option value="24">24 - Confusión</option>
                                      <option value="25">
                                        25 - Remisión de Deuda
                                      </option>
                                      <option value="26">
                                        26 - Prescripción o Caducidad
                                      </option>
                                      <option value="27">
                                        27 - A Satisfacción del Acreedor
                                      </option>
                                      <option value="28">
                                        28 - Tarjeta de Débito
                                      </option>
                                      <option value="29">
                                        29 - Tarjeta de Servicios
                                      </option>
                                      <option value="30">
                                        30 - Aplicación de Anticipos
                                      </option>
                                      <option value="31">
                                        31 - Intermediarios
                                      </option>
                                      <option value="99">
                                        99 - Por definir
                                      </option>
                                    </select>
                                  </div>

                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Método de pago *
                                    </label>
                                    <select
                                      id="metodoPago"
                                      className="SelectTrigger"
                                      onChange={(e) =>
                                        setMetodoPago(e.target.value)
                                      }
                                      value={metodoPago}
                                      required
                                    >
                                      <option value="" disabled>
                                        Escoge una opción
                                      </option>
                                      <option value="PUE">
                                        PUE - Pago en una Sola Exhibición
                                      </option>
                                      {formaPago === "99" && (
                                        <option value="PDD">
                                          PDD - Pago en Parcialidades o Diferido
                                        </option>
                                      )}
                                    </select>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}

                              <div className="cartaPorte__group_elements">
                                <label className="FormLabel" htmlFor="name">
                                  Exportación *
                                </label>
                                <select
                                  className="SelectTrigger"
                                  onChange={(e) =>
                                    setExportacion(e.target.value)
                                  }
                                  value={exportacion}
                                  required
                                >
                                  <option value="" disabled>
                                    Escoge una opción
                                  </option>
                                  <option value="01">No Aplica</option>
                                  <option value="02">
                                    Definitiva con clave A1
                                  </option>
                                  <option value="03">Temportal</option>
                                  <option value="04">
                                    Definitiva con Clave Distinta a A1 o cuando
                                    no existe enajenación en términos del CFF
                                  </option>
                                </select>
                              </div>

                              <div className="cartaPorte__group_elements">
                                <label className="FormLabel" htmlFor="name">
                                  Tipo de Cambio *
                                </label>
                                <select
                                  className="SelectTrigger"
                                  onChange={(e) => {
                                    setTipoCambio(e.target.value);

                                    // setTotalViaje(subtotalViaje);
                                    if (e.target.value === "USN") {
                                      let dolarExchange = Number(
                                        precioUnitViaje / dolarValue
                                      ).toFixed(2);
                                      setPrecioUnitViaje(dolarExchange);
                                      setPrecioUnitViajeAux(dolarExchange);

                                      if (objetoImpuesto === "02") {
                                        setSubtotalViaje(
                                          Number(
                                            dolarExchange * cantidadViaje
                                          ).toFixed(2)
                                        );
                                        setTotalViaje(
                                          Number(
                                            dolarExchange * cantidadViaje * 1.16
                                          ).toFixed(2)
                                        );
                                      } else {
                                        setSubtotalViaje(
                                          Number(
                                            dolarExchange * cantidadViaje
                                          ).toFixed(2)
                                        );
                                        setTotalViaje(
                                          Number(
                                            dolarExchange * cantidadViaje
                                          ).toFixed(2)
                                        );
                                      }
                                    } else {
                                      setPrecioUnitViaje(
                                        Number(precioVenta).toFixed(2)
                                      );
                                      setPrecioUnitViajeAux(
                                        Number(precioVenta).toFixed(2)
                                      );
                                      if (objetoImpuesto === "02") {
                                        setSubtotalViaje(
                                          Number(
                                            precioVenta * cantidadViaje
                                          ).toFixed(2)
                                        );
                                        setTotalViaje(
                                          Number(
                                            precioVenta * cantidadViaje * 1.16
                                          ).toFixed(2)
                                        );
                                      } else if (objetoImpuesto === "03") {
                                        setPrecioUnitViaje(
                                          Number(precioVenta * 1.16).toFixed(2)
                                        );
                                        setSubtotalViaje(
                                          Number(
                                            precioVenta * cantidadViaje * 1.16
                                          ).toFixed(2)
                                        );
                                        setTotalViaje(
                                          Number(
                                            precioVenta * cantidadViaje * 1.16
                                          ).toFixed(2)
                                        );
                                      } else {
                                        setSubtotalViaje(
                                          Number(
                                            precioVenta * cantidadViaje
                                          ).toFixed(2)
                                        );
                                        setTotalViaje(
                                          Number(
                                            precioVenta * cantidadViaje
                                          ).toFixed(2)
                                        );
                                      }
                                    }

                                    // if (e.target.value == "USN") {

                                    //   if (objetoImpuesto !== '01') {
                                    //     setPrecioUnitViaje((parseFloat(precioVenta * 1.16 / VALOR_CAMBIO)).toFixed(2));
                                    //     // setSubtotalViaje((parseFloat(precioVenta) * 1.16 / VALOR_CAMBIO).toFixed(2));
                                    //     setTotalViaje(((precioVenta * 1.16) / VALOR_CAMBIO).toFixed(2));
                                    //     if (objetoImpuesto == "02") {
                                    //       setPrecioUnitViaje((parseFloat(precioVenta) / VALOR_CAMBIO).toFixed(2));
                                    //       // setSubtotalViaje((parseFloat(precioVenta) / VALOR_CAMBIO).toFixed(2));
                                    //     }
                                    //   } else {
                                    //     setPrecioUnitViaje((parseFloat(precioVenta / VALOR_CAMBIO)).toFixed(2));
                                    //     // setSubtotalViaje((parseFloat(precioVenta) / VALOR_CAMBIO).toFixed(2));
                                    //     setTotalViaje(((precioVenta) / VALOR_CAMBIO).toFixed(2))
                                    //   }
                                    // } else {

                                    //   if (objetoImpuesto !== '01') {
                                    //     setPrecioUnitViaje((parseFloat(precioVenta) * 1.16).toFixed(2));
                                    //     // setSubtotalViaje((parseFloat(precioVenta) * 1.16).toFixed(2));
                                    //     setTotalViaje((precioVenta * 1.16).toFixed(2));
                                    //     if (objetoImpuesto == "02") {
                                    //       setPrecioUnitViaje((parseFloat(precioVenta)).toFixed(2));
                                    //       // setSubtotalViaje((parseFloat(precioVenta)).toFixed(2));
                                    //     }
                                    //   } else {
                                    //     setPrecioUnitViaje((parseFloat(precioVenta)).toFixed(2));
                                    //     // setSubtotalViaje((parseFloat(precioVenta)).toFixed(2));
                                    //     setTotalViaje(precioVenta);
                                    //   }

                                    // }
                                  }}
                                  value={tipoCambio}
                                  required
                                >
                                  <option value="" disabled>
                                    Escoge una opción
                                  </option>
                                  <option value="MXN">Pesos mexicanos</option>
                                  <option value="USN">Dolar</option>
                                </select>
                              </div>
                              {tipoCambio === "USN" ? (
                                <div className="cartaPorte__group_elements">
                                  <label htmlFor="name" className="FormLabel">
                                    Valor del Dólar
                                  </label>
                                  <input
                                    disabled
                                    type="number"
                                    className="InputForm"
                                    value={dolarValue}
                                  ></input>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <hr></hr>
                          <div style={{ marginTop: "20px" }}>
                            <h3 style={{ marginBottom: "30px" }}>
                              Datos del Viaje
                            </h3>

                            <div className="cartaPorte__group">
                              <div className="cartaPorte__group_elements">
                                <label
                                  className="FormLabel"
                                  htmlFor="name"
                                  style={{ marginRight: "8px" }}
                                >
                                  Cantidad *
                                </label>
                                <input
                                  type="number"
                                  min={1}
                                  className="InputForm"
                                  value={cantidadViaje}
                                  required
                                  onChange={(e) => {
                                    setCantidadViaje(e.target.value);
                                    setSubtotalViaje(
                                      Number(
                                        e.target.value * precioUnitViaje
                                      ).toFixed(2)
                                    );
                                    setSubtotalViajeAux(
                                      Number(
                                        e.target.value * precioUnitViaje
                                      ).toFixed(2)
                                    );

                                    if (objetoImpuesto === "02") {
                                      setTotalViaje(
                                        Number(
                                          e.target.value *
                                            precioUnitViaje *
                                            1.16
                                        ).toFixed(2)
                                      );
                                    } else if (objetoImpuesto === "03") {
                                      setSubtotalViajeAux(
                                        Number(
                                          e.target.value * precioUnitViaje
                                        ).toFixed(2)
                                      );
                                      setTotalViaje(
                                        Number(
                                          e.target.value * precioUnitViaje
                                        ).toFixed(2)
                                      );
                                    } else {
                                      setTotalViaje(
                                        Number(
                                          e.target.value * precioUnitViaje
                                        ).toFixed(2)
                                      );
                                    }
                                  }}
                                ></input>
                              </div>

                              <div className="cartaPorte__group_elements">
                                <label className="FormLabel" htmlFor="name">
                                  Código de Producto *
                                </label>
                                <select
                                  className="SelectTrigger"
                                  onChange={(e) =>
                                    setCodigoViaje(e.target.value)
                                  }
                                  value={codigoViaje}
                                  required
                                >
                                  <option value="" disabled>
                                    Escoge una opción
                                  </option>
                                  <option value="78101500">
                                    78101500 - Transporte de carga aérea
                                  </option>
                                  <option value="78101501">
                                    78101501 - Transporte nacional aéreo de
                                    carga
                                  </option>
                                  <option value="78101502">
                                    78101502 - Transporte internacional aéreo de
                                    carga
                                  </option>
                                  <option value="78101503">
                                    78101503 - Transporte aéreo blindado
                                  </option>
                                  <option value="78101600">
                                    78101600 - Transporte de carga por
                                    ferrocarril
                                  </option>
                                  <option value="78101601">
                                    78101601 - Servicios de transporte en
                                    furgones, Servicios de transporte en vagones
                                  </option>
                                  <option value="78101602">
                                    78101602 - Servicios de transporte
                                    ferroviario de carga a granel, Servicios de
                                    transporte ferroviario de mercancía a granel
                                  </option>
                                  <option value="78101603">
                                    78101603 - Transporte de ganado por
                                    ferrocarril, Transporte ferroviario de
                                    ganado
                                  </option>
                                  <option value="78101604">
                                    78101604 - Servicios de transporte de
                                    vehículos por ferrocarril, Vehículos de
                                    servicios de transporte
                                  </option>
                                  <option value="78101605">
                                    78101605 - Servicio de alquiler de
                                    carrotanques de ferrocarril, Servicio de
                                    renta de carrotanques de ferrocarril
                                  </option>
                                  <option value="78101606">
                                    78101606 - Servicio de alquiler de furgones
                                    de ferrocarril o carros de carga, Servicio
                                    de renta de vagones de ferrocarril o carros
                                    de carga
                                  </option>
                                  <option value="78101702">
                                    78101702 - Servicios de transporte
                                    internacional por buque (Servicios de
                                    transporte internacional por embarcación)
                                  </option>
                                  <option value="78101701">
                                    78101701 - Servicios de transporte nacional
                                    por buque, Servicios de transporte nacional
                                    por embarcación
                                  </option>
                                  <option value="78101704">
                                    78101704 - Servicios de transporte
                                    internacional por barcazas
                                  </option>
                                  <option value="78101705">
                                    78101705 - Transporte marítimo blindado
                                  </option>
                                  <option value="">
                                    78101706 - Servicio de buques para
                                    suministros a plataformas de gas y petróleo
                                    en alta mar, Servicio de embarcaciones para
                                    suministros a plataformas de gas y petróleo
                                    mar adentro
                                  </option>
                                  <option value="78101800">
                                    78101800 - Transporte de carga por carretera
                                  </option>
                                  <option value="78101801">
                                    78101801 - Servicios de transporte de carga
                                    local, Servicios de transporte de carga por
                                    carretera (en camión) en área local
                                  </option>
                                  <option value="78101802">
                                    78101802 - Servicios de transporte de carga
                                    foráneo, Servicios transporte de carga por
                                    carretera (en camión) a nivel regional y
                                    nacional
                                  </option>
                                  <option value="78101803">
                                    78101803 - Servicios de transporte de
                                    vehículos, Servicios de transporte de
                                    vehículos por carretera
                                  </option>
                                  <option value="78101804">
                                    78101804 - Servicios de fletes y mudanzas,
                                    Servicios de reubicación
                                  </option>
                                  <option value="78101806">
                                    78101806 - Servicios transporte de carga por
                                    carretera a nivel internacional
                                  </option>
                                  <option value="78101807">
                                    78101807 - Servicios de transporte de carga
                                    de petróleo o químicos por carretera
                                  </option>
                                  <option value="78101900">
                                    78101900 - Transporte de carga intermodal
                                  </option>
                                  <option value="78101901">
                                    78101901 - Transporte aéreo a marítimo,
                                    Transporte con salida aérea y destino
                                    marítimo
                                  </option>
                                  <option value="78101902">
                                    78101902 - Transporte con salida marítima y
                                    destino ferroviario, Transporte marítimo a
                                    ferroviario
                                  </option>
                                  <option value="78101903">
                                    78101903 - Transporte con salida marítima y
                                    destino a carretera (por camión), Transporte
                                    marino a carretera (por camión)
                                  </option>
                                  <option value="78101904">
                                    78101904 - Transporte aéreo a carretera (por
                                    camión), Transporte con salida aérea y
                                    destino a carretera (por camión)
                                  </option>
                                  <option value="78101905">
                                    78101905 - Transporte por vagones de
                                    ferrocarril
                                  </option>
                                  <option value="78102200">
                                    78102200 - Servicios postales de paqueteo y
                                    courrier
                                  </option>
                                  <option value="78102201">
                                    78102201 - Servicios de entrega postal
                                    nacional
                                  </option>
                                  <option value="78102203">
                                    78102203 - Servicios de envío, recogida o
                                    entrega de correo
                                  </option>
                                  <option value="78102204">
                                    78102204 - Servicios de entrega a nivel
                                    mundial de cartas o paquetes pequeños,
                                    Servicios de mensajería y paquetería a nivel
                                    mundial
                                  </option>
                                  <option value="78102205">
                                    78102205 - Servicios de entrega local de
                                    cartas o paquetes pequeños, Servicios de
                                    mensajería y paquetería en territorio
                                    nacional
                                  </option>
                                  <option value="78121603">
                                    78121603 - Tarifa de los fletes (Servicio de
                                    fletes de carga)
                                  </option>
                                  <option value="78141500">
                                    78141500 - Servicios de organización de
                                    transportes
                                  </option>
                                  <option value="78141501">
                                    78141501 - Servicios de agentes de carga,
                                    Servicios de expedidores de fletes
                                  </option>
                                  <option value="84121806">
                                    84121806 - Servicios de custodia de valores
                                  </option>
                                  <option value="92121800">
                                    92121800 - Servicios de Carros blindados y
                                    transporte de valores
                                  </option>
                                  <option value="92121801">
                                    92121801 - Servicio de carros blindados
                                  </option>
                                  <option value="92121802">
                                    92121802 - Servicio de transporte de dinero
                                  </option>
                                </select>
                              </div>
                              <div className="cartaPorte__group_elements">
                                <label className="FormLabel" htmlFor="name">
                                  Código de Unidad *
                                </label>
                                <select
                                  disabled
                                  className="SelectTrigger"
                                  onChange={(e) =>
                                    setCodigoServicioViaje(e.target.value)
                                  }
                                  value={codigoServicioViaje}
                                  required
                                >
                                  <option value="" disabled>
                                    Escoge una opción
                                  </option>
                                  <option>E48</option>
                                </select>
                              </div>

                              <div className="cartaPorte__group_elements">
                                <label className="FormLabel" htmlFor="name">
                                  Unidad *
                                </label>
                                <select
                                  className="SelectTrigger"
                                  disabled
                                  onChange={(e) =>
                                    setClaveUnidadViaje(e.target.value)
                                  }
                                  value={claveUnidadViaje}
                                  required
                                >
                                  <option value="" disabled>
                                    Escoge una opción
                                  </option>
                                  <option value="Unidad de Servicio">
                                    Unidad de Servicio
                                  </option>
                                </select>
                              </div>
                              <div className="cartaPorte__group_elements">
                                <label className="FormLabel" htmlFor="name">
                                  Descripción *
                                </label>
                                <input
                                  type="text"
                                  className="InputForm"
                                  value={descripcionViaje}
                                  onChange={(e) =>
                                    setDescripcionViaje(e.target.value)
                                  }
                                  required
                                ></input>
                              </div>

                              <div className="cartaPorte__group_elements">
                                <label className="FormLabel" htmlFor="name">
                                  Precio Unitario: $ *
                                </label>
                                <input
                                  type="number"
                                  disabled
                                  className="InputForm"
                                  onChange={(e) =>
                                    setPrecioUnitViaje(e.target.value)
                                  }
                                  required
                                  value={precioUnitViaje}
                                ></input>
                              </div>

                              <div className="cartaPorte__group_elements">
                                <label className="FormLabel" htmlFor="name">
                                  Subtotal: $ *
                                </label>
                                <input
                                  type="number"
                                  disabled
                                  className="InputForm"
                                  onChange={(e) =>
                                    setSubtotalViaje(e.target.value)
                                  }
                                  required
                                  value={subtotalViaje}
                                ></input>
                              </div>

                              <div className="cartaPorte__group_elements">
                                <label className="FormLabel" htmlFor="name">
                                  Objeto de Impuesto *
                                </label>
                                <select
                                  className="SelectTrigger"
                                  onChange={(e) => {
                                    setObjetoImpuesto(e.target.value);
                                    if (e.target.value === "02") {
                                      setTotalViaje(
                                        Number(
                                          precioUnitViajeAux *
                                            cantidadViaje *
                                            1.16
                                        ).toFixed(2)
                                      );
                                      setSubtotalViaje(
                                        Number(
                                          precioUnitViajeAux * cantidadViaje
                                        ).toFixed(2)
                                      );
                                      setPrecioUnitViaje(precioUnitViajeAux);
                                    } else if (e.target.value === "03") {
                                      setPrecioUnitViaje(
                                        Number(
                                          precioUnitViajeAux * 1.16
                                        ).toFixed(2)
                                      );
                                      setSubtotalViaje(
                                        Number(
                                          precioUnitViajeAux *
                                            cantidadViaje *
                                            1.16
                                        ).toFixed(2)
                                      );
                                      setTotalViaje(
                                        Number(
                                          precioUnitViajeAux *
                                            cantidadViaje *
                                            1.16
                                        ).toFixed(2)
                                      );
                                    } else {
                                      setSubtotalViaje(
                                        Number(
                                          precioUnitViajeAux * cantidadViaje
                                        ).toFixed(2)
                                      );
                                      setTotalViaje(
                                        Number(
                                          precioUnitViajeAux * cantidadViaje
                                        ).toFixed(2)
                                      );
                                      // setSubtotalViaje(subTotalViajeAux);
                                      setPrecioUnitViaje(precioUnitViajeAux);
                                    }

                                    // if(e.target.value==="02"){
                                    //   setTotalViaje(())
                                    // }

                                    // if(e.target.value==="01"){
                                    //   setTotalViaje(precioVenta);
                                    // }
                                    // if(e.target.value === "02"){
                                    //   setTotalImpuestos((precioVenta * 0.16).toFixed(2));
                                    //     // setPrecioUnitViaje((parseFloat(precioVenta) * 1.16).toFixed(2));
                                    //     setSubtotalViaje((parseFloat(precioVenta) * 1.16).toFixed(2));
                                    //     setTotalViaje((precioVenta * 1.16).toFixed(2));
                                    // }else{
                                    //   setTotalViaje(precioVenta);
                                    // }
                                    // if (e.target.value !== '01') {
                                    //   if (tipoCambio == "USN") {
                                    //     setTotalImpuestos(((precioVenta * 0.16 / VALOR_CAMBIO)).toFixed(4));
                                    //     //USD
                                    //     setTotalViaje((parseFloat(precioVenta) * 1.16 / VALOR_CAMBIO).toFixed(2));
                                    //     setPrecioUnitViaje((parseFloat(precioVenta) * 1.16 / VALOR_CAMBIO).toFixed(2));
                                    //     setSubtotalViaje((parseFloat(precioVenta) * 1.16 / VALOR_CAMBIO).toFixed(2));
                                    //     if (e.target.value == "02") {
                                    //       setPrecioUnitViaje((parseFloat(precioVenta) / VALOR_CAMBIO).toFixed(2));
                                    //       setSubtotalViaje((parseFloat(precioVenta) / VALOR_CAMBIO).toFixed(2));
                                    //       setTotalViaje(parseFloat(totalImpuestos) + parseFloat(subtotalViaje));
                                    //     }
                                    //     //MXN
                                    //   } else {
                                    //     setTotalImpuestos((precioVenta * 0.16).toFixed(2));
                                    //     // setPrecioUnitViaje((parseFloat(precioVenta) * 1.16).toFixed(2));
                                    //     // setSubtotalViaje((parseFloat(precioVenta) * 1.16).toFixed(2));
                                    //     setTotalViaje((precioVenta * 1.16).toFixed(2));
                                    //     if (e.target.value == "02") {
                                    //       setPrecioUnitViaje((parseFloat(precioVenta)).toFixed(2));
                                    //       setSubtotalViaje((parseFloat(precioVenta)).toFixed(2));
                                    //     }
                                    //   }

                                    // } else {
                                    //   if (tipoCambio == "USN") {
                                    //     setPrecioUnitViaje((parseFloat(precioVenta) / VALOR_CAMBIO).toFixed(2));
                                    //     setSubtotalViaje((parseFloat(precioVenta) / VALOR_CAMBIO).toFixed(2));
                                    //     setTotalViaje((parseFloat(precioVenta) / VALOR_CAMBIO).toFixed(2));
                                    //   } else {
                                    //     setPrecioUnitViaje((parseFloat(precioVenta)).toFixed(2));
                                    //     setSubtotalViaje((parseFloat(precioVenta)).toFixed(2));
                                    //     const viajeTotal = (parseFloat(precioVenta)).toFixed(2);
                                    //     setTotalViaje(viajeTotal);
                                    //   }

                                    // }
                                  }}
                                  value={objetoImpuesto}
                                  required
                                >
                                  <option value="" disabled>
                                    Escoge una opción
                                  </option>
                                  <option value="01">
                                    01 - No Objeto de Impuesto
                                  </option>
                                  <option value="02">
                                    {" "}
                                    {/*Se debe agregar nodo de Impuestos */}
                                    02 - (Sí objeto de impuesto), se deben
                                    desglosar los Impuestos a nivel de Concepto.
                                  </option>
                                  <option value="03">
                                    03 - (Sí objeto del impuesto y no obligado
                                    al desglose) no se desglosan impuestos a
                                    nivel Concepto.
                                  </option>
                                  <option value="04">
                                    04 - (Sí Objeto de impuesto y no causa
                                    impuesto)
                                  </option>
                                </select>
                              </div>

                              <div className="cartaPorte__group_elements">
                                <label className="FormLabel" htmlFor="name">
                                  Total: $ *
                                </label>
                                <input
                                  type="number"
                                  disabled
                                  className="InputForm"
                                  onChange={(e) =>
                                    setTotalViaje(e.target.value)
                                  }
                                  required
                                  value={totalViaje}
                                ></input>
                              </div>
                            </div>
                          </div>
                          {transpInt === "Sí" ? (
                            <>
                              <div style={{ marginTop: "20px" }}>
                                <hr></hr>
                                <h3 style={{ marginBottom: "30px" }}>
                                  Complemento Carta Porte
                                </h3>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="c1">
                                      Vía Entrada Salida
                                    </label>
                                    <select
                                      className="SelectTrigger"
                                      onChange={(e) =>
                                        setViaEntSal(e.target.value)
                                      }
                                      value={viaEntSal}
                                    >
                                      <option value="" disabled>
                                        Escoge una opción
                                      </option>
                                      <option value="01">Autotransporte</option>
                                      <option value="02">
                                        Transporte Marítimo
                                      </option>
                                      <option value="03">
                                        Transporte Aéreo
                                      </option>
                                      <option value="04">
                                        Transporte Ferroviario
                                      </option>
                                    </select>
                                  </div>
                                </div>

                                <div className="cartaPorte__group">
                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Regimen Aduanero
                                    </label>
                                    <select
                                      className="SelectTrigger"
                                      onChange={(e) =>
                                        setRegimAduan(e.target.value)
                                      }
                                      value={regimAduan}
                                    >
                                      <option value="" disabled>
                                        Escoge una opción
                                      </option>
                                      {entSalMerc === "Entrada" ? (
                                        <>
                                          <option value="IMD">
                                            IMD- Definitivo de Importación
                                          </option>
                                          <option value="ITR">
                                            ITR Temporales de importación para
                                            retomar al extranjero en el mismo
                                            estado.
                                          </option>
                                          <option value="ITE">
                                            ITE Temporales de importación para
                                            elaboración, transformación o
                                            reparación para empresas con
                                            programa IMMEX.
                                          </option>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {entSalMerc === "Salida" ? (
                                        <>
                                          <option value="EXD">
                                            EXD Definitivo de exportación.
                                          </option>

                                          <option value="ETR">
                                            ETR Temporales de exportación para
                                            retornar al país en el mismo estado.
                                          </option>
                                          <option value="ETE">
                                            ETE Temporales de exportación para
                                            elaboración, transformación o
                                            reparación.
                                          </option>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      <option value="DFI">
                                        DFI Depósito Fiscal.
                                      </option>
                                      <option value="RFE">
                                        RFE Elaboración, transformación o
                                        reparación en recinto fiscalizado.
                                      </option>
                                      <option value="RFS">
                                        RFS Recinto fiscalizado estratégico.
                                      </option>
                                      <option value="TRA">
                                        TRA Tránsitos.
                                      </option>
                                    </select>
                                  </div>

                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Entrada/Salida de Mercancía
                                    </label>
                                    <select
                                      className="SelectTrigger"
                                      value={entSalMerc}
                                      onChange={handleEntradaSalida}
                                    >
                                      <option value="" disabled>
                                        Escoge una opción
                                      </option>
                                      <option value="Entrada">Entrada</option>
                                      <option value="Salida">Salida</option>
                                    </select>
                                  </div>

                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      País de Origen del Destino
                                    </label>
                                    <select
                                      className="SelectTrigger"
                                      id="selectPaises"
                                      onChange={(e) => {
                                        setPaisOrigDest(e.target.value);
                                      }}
                                      value={paisOrigDest}
                                    >
                                      <option value="" disabled>
                                        Escoge una opción
                                      </option>
                                      <option value="MEX" name="México">
                                        México
                                      </option>
                                      <option value="USA" name="Estados Unidos">
                                        Estados Unidos
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {cartaPorte === "Sí" ? (
                            <>
                              <hr></hr>
                              <div style={{ marginTop: "20px" }}>
                                <h3 style={{ marginBottom: "30px" }}>
                                  Ubicaciones
                                </h3>

                                <h5 style={{ margin: "1rem 0" }}>Origen</h5>
                                <div className="cartaPorte__group">
                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Fecha y Hora de Salida *
                                    </label>
                                    <input
                                      className="InputForm"
                                      type="datetime-local"
                                      onChange={(e) => {
                                        setFechaHoraSalidaOrig(e.target.value);
                                      }}
                                      value={fechaHoraSalidaOrig}
                                      required
                                    ></input>
                                  </div>

                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      RFC Origen *
                                    </label>
                                    <input
                                      disabled
                                      className="InputForm"
                                      type="text"
                                      onChange={(e) =>
                                        setRFCOrig(e.target.value)
                                      }
                                      value={RFC_Orig}
                                      required
                                    ></input>
                                  </div>

                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      País *
                                    </label>
                                    <select
                                      id="selectPaises2"
                                      className="SelectTrigger"
                                      onChange={(e) => {
                                        setPaisOrig(e.target.value);
                                        fetchEstadosOrigen(e.target.value);
                                      }}
                                      value={paisOrig}
                                      required
                                    >
                                      <option value="" disabled>
                                        Selecciona un país
                                      </option>
                                      <option value="MEX">México</option>
                                      <option value="USA">
                                        Estados Unidos
                                      </option>
                                    </select>
                                  </div>

                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Estado *
                                    </label>
                                    <select
                                      className="SelectTrigger"
                                      type="text"
                                      id="selectEstado"
                                      onChange={(e) => {
                                        setEstadoOrig(e.target.value);
                                        fetchMunicipiosOrig(e.target.value);
                                      }}
                                      required
                                      value={estadoOrig}
                                    >
                                      <option value="" disabled>
                                        Selecciona un Estado
                                      </option>
                                    </select>
                                  </div>

                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Municipio *
                                    </label>
                                    <select
                                      className="SelectTrigger"
                                      type="text"
                                      id="selectMunicipio"
                                      onChange={(e) => {
                                        if (e.target.value !== "") {
                                          setCpOrigDisabled(false);
                                        }
                                        setMunicipioOrig(e.target.value);
                                      }}
                                      required
                                      value={municipioOrig}
                                    >
                                      <option value="" disabled>
                                        Selecciona un Municipio
                                      </option>
                                    </select>
                                  </div>

                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Código Postal *
                                    </label>
                                    <input
                                      className="InputForm"
                                      type="text"
                                      value={CPOrig}
                                      onChange={(e) => {
                                        setCPOrig(e.target.value);
                                        fetchColoniasOrig(e.target.value);
                                      }}
                                      placeholder="Código Postal de Origen"
                                      required
                                    ></input>
                                  </div>

                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Colonia *
                                    </label>
                                    <select
                                      className="SelectTrigger"
                                      type="text"
                                      id="selectColonia"
                                      onChange={(e) => {
                                        setColoniaOrig(e.target.value);
                                      }}
                                      required
                                      value={coloniaOrig}
                                    >
                                      <option value="" disabled>
                                        Selecciona una Colonia
                                      </option>
                                    </select>
                                  </div>
                                </div>

                                <h5 style={{ margin: "1rem 0" }}>Destino</h5>
                                <div className="cartaPorte__group">
                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Fecha y Hora de Salida *
                                    </label>
                                    <input
                                      className="InputForm"
                                      type="datetime-local"
                                      onChange={(e) => {
                                        setFechaHoraSalOrig(e.target.value);
                                        let dateFechaSalida = new Date(
                                          e.target.value
                                        );
                                      }}
                                      required
                                      value={fechaHoraSalOrig}
                                    ></input>
                                  </div>

                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      RFC Destinatario *
                                    </label>
                                    <input
                                      className="InputForm"
                                      type="text"
                                      onChange={(e) =>
                                        setRFCDest(e.target.value)
                                      }
                                      required
                                    ></input>
                                  </div>

                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      País *
                                    </label>
                                    <select
                                      id="selectPaises3"
                                      className="SelectTrigger"
                                      onChange={(e) => {
                                        setPaisDest(e.target.value); // Set the selected country value to state
                                        fetchEstadosDestino(e.target.value); // Update selectedCountry state
                                      }}
                                      value={paisDest}
                                      required
                                    >
                                      <option value="" disabled>
                                        Selecciona un país
                                      </option>
                                      <option value="MEX">México</option>
                                      <option value="USA">
                                        Estados Unidos
                                      </option>
                                    </select>
                                  </div>

                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Estado *
                                    </label>
                                    <select
                                      id="selectEstado2"
                                      className="SelectTrigger"
                                      onChange={(e) => {
                                        setEstadoDest(e.target.value);
                                        fetchMunicipiosDest(e.target.value);
                                      }}
                                      required
                                      value={estadoDest}
                                    >
                                      <option value="" disabled>
                                        Selecciona un Estado
                                      </option>
                                    </select>
                                  </div>

                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Municipio
                                    </label>
                                    <select
                                      className="SelectTrigger"
                                      type="text"
                                      id="selectMunicipio1"
                                      onChange={(e) => {
                                        if (e.target.value !== "") {
                                          setCpDestDisabled(false);
                                        }
                                        setMunicipioDest(e.target.value);
                                      }}
                                      value={municipioDest}
                                    >
                                      <option value="" disabled>
                                        Selecciona un Municipio
                                      </option>
                                    </select>
                                  </div>

                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Código Postal *
                                    </label>
                                    <input
                                      className="InputForm"
                                      type="text"
                                      value={CPDest}
                                      placeholder="Codigo Postal de Destino"
                                      onChange={(e) => {
                                        setCPDest(e.target.value);
                                        fetchColoniasDest(e.target.value);
                                      }}
                                      required
                                    ></input>
                                  </div>

                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Colonia
                                    </label>
                                    <select
                                      className="SelectTrigger"
                                      type="text"
                                      id="selectColonia1"
                                      onChange={(e) => {
                                        setColoniaDest(e.target.value);
                                      }}
                                      value={coloniaDest}
                                    >
                                      <option value="" disabled>
                                        Selecciona una Colonia
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <hr></hr>
                              <div>
                                <h3>Mercancias</h3>
                                <a
                                  href="http://pys.sat.gob.mx/PyS/catPyS.aspx"
                                  target="_blank"
                                >
                                  Click aquí para ver las Claves de Productos y
                                  Servicios del SAT
                                </a>
                                <br></br>
                                Para conocer las claves de material peligroso y
                                claves de unidad, ve el <br></br>
                                <a
                                  href="http://omawww.sat.gob.mx/tramitesyservicios/Paginas/documentos/CatalogosCartaPorte30.xls"
                                  target="_blank"
                                >
                                  Catálago de Carta Porte 3.0
                                </a>
                                <br></br>
                                <a
                                  href="http://omawww.sat.gob.mx/tramitesyservicios/Paginas/documentos/c_FraccionArancelaria_20231219.xls"
                                  target="_blank"
                                >
                                  Catálogo de Fracciones Arancelarias SAT
                                </a>
                                <div className="cartaPorte__group_elements_checkbox_parent">
                                  <div className="cartaPorte__group_elements_checkbox">
                                    <Checkbox.Root
                                      className="CheckboxRoot"
                                      checked={
                                        transpInt === "Sí" ? true : false
                                      }
                                      onCheckedChange={(isChecked) =>
                                        setTranspInt(isChecked ? "Sí" : "No")
                                      }
                                    >
                                      <Checkbox.Indicator className="CheckboxIndicator">
                                        <CheckIcon />
                                      </Checkbox.Indicator>
                                    </Checkbox.Root>

                                    <label
                                      style={{ marginLeft: "1rem" }}
                                      className="FormLabel"
                                      htmlFor="c1"
                                    >
                                      Transportación Internacional
                                    </label>
                                  </div>

                                  <div className="cartaPorte__group_elements_checkbox">
                                    <Checkbox.Root
                                      className="CheckboxRoot"
                                      onCheckedChange={(isChecked) => {
                                        setLogInversa(isChecked ? "Sí" : "No");
                                      }}
                                      checked={
                                        logInversa === "Sí" ? true : false
                                      }
                                    >
                                      <Checkbox.Indicator className="CheckboxIndicator">
                                        <CheckIcon />
                                      </Checkbox.Indicator>
                                    </Checkbox.Root>
                                    <label
                                      style={{ marginLeft: "1rem" }}
                                      className="FormLabel"
                                      htmlFor="c1"
                                    >
                                      Logística Inversa Recolección Devolución
                                    </label>
                                  </div>
                                </div>
                                <div>
                                  {/* Renderiza los inputs existentes */}
                                  {mercancias.map((mercancia, index) => (
                                    <div key={index}>
                                      <hr></hr>
                                      <h4>Mercancía {index + 1}</h4>

                                      <Mercancias
                                        index={index}
                                        mercancia={mercancia}
                                        handleInputChange={handleInputChange}
                                        handleMaterialPeligroso={
                                          handleMaterialPeligroso
                                        }
                                        isTransInt={transpInt}
                                        entradaSalida={entSalMerc}
                                        formaFarmaceutica={formaFarmaceutica}
                                        condicionesEspeciales={
                                          condicionesEspeciales
                                        }
                                        embalajeData={embalajeData}
                                      />
                                    </div>
                                  ))}
                                  {/* Botón para agregar más mercancías
                              <button className="Button" style={{ margin: "1rem 0" }} onClick={handleAddMerch}>
                                Agregar mercancía
                              </button> */}
                                </div>
                              </div>
                              <hr></hr>

                              <div style={{ marginTop: "20px" }}>
                                <h3>Autotransporte</h3>
                                <div className="cartaPorte__group">
                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Tipo de Permiso de SICT *
                                    </label>
                                    <select
                                      className="SelectTrigger"
                                      onChange={(e) =>
                                        setPermisoSICT(e.target.value)
                                      }
                                      value={permisoSICT}
                                      required
                                    >
                                      <option value="" disabled>
                                        Escoge una opción
                                      </option>
                                      <option value="TPAF01">
                                        TPAF01: autotransporte federal de carga
                                        general
                                      </option>
                                      <option value="TPAF02">
                                        TPAF02: transporte privado de carga
                                      </option>
                                      <option value="TPAF03">
                                        TPAF03:Autotransporte federal de carga
                                        especializada de materiales y residuos
                                        peligrosos
                                      </option>
                                      <option value="TPAF04">
                                        TPAF04: transporte de automoviles sin
                                        rodar en vehiculo tipo gondola
                                      </option>
                                      <option value="TPAF05">
                                        TPAF05: transporte de carga de gran peso
                                        y/o volumen de hasta 90 toneladas
                                      </option>
                                      <option value="TPAF06">
                                        TPAF06: transporte de carga
                                        especializada de gran peso y/o volumen
                                        de mas de 90 toneladas
                                      </option>
                                      <option value="TPAF07">
                                        TPAF07: transporte privado de materiales
                                        y residuos peligrosos
                                      </option>
                                      <option value="TPAF08">
                                        TPAF08: autotransporte internacional de
                                        carga de largo recorrido
                                      </option>
                                      <option value="TPAF09">
                                        TPAF09: autotransporte internacional de
                                        carga especializada de materiales y
                                        residuos peligrosos de largo recorrido
                                      </option>
                                      <option value="TPAF10">
                                        TPAF10: autotransporte federal de carga
                                        general cuyo ambito de aplicación
                                        comprende la franja fronteriza con
                                        Estados Unidos
                                      </option>
                                      <option value="TPAF11">
                                        TPAF11: autotransporte federal de Carga
                                        especializada cuyo ambito de aplicación
                                        comprende la franja fronteriza con
                                        estados unidos
                                      </option>
                                      <option value="TPAF12">
                                        TPAF12: servicio auxiliar de arrastre en
                                        las vías generales de comunicación
                                      </option>
                                      <option value="TPAF13">
                                        TPAF13: servicio auxiliar de servicios
                                        de arrastre, arrastre y salvamento, y
                                        deposito de vehiculos en las vías
                                        generales de comunicación
                                      </option>
                                      <option value="TPAF14">
                                        TPAF14: servicio de paquetería y
                                        mensajería en las vías generales de
                                        comunicación
                                      </option>
                                      <option value="TPAF15">
                                        TPAF15: transporte especial para el
                                        transito de gruas industriales con peso
                                        maximo de 90 toneladas
                                      </option>
                                      <option value="TPAF16">
                                        TPAF16: servicio federal para empresas
                                        arrendadoras servicio publico federal
                                      </option>
                                      <option value="TPAF17">
                                        TPAF17: empresas trasladistas de
                                        vehiculos nuevos
                                      </option>
                                      <option value="TPAF18">
                                        TPAF18: empresas fabricantes o
                                        distrubuidoras de vehiculos nuevos
                                      </option>
                                      <option value="TPAF19">
                                        TPAF19: autorización expresa para
                                        circular en los caminos y puentes de
                                        jurisdicción federal con configuraciones
                                        de tractocamion doblemente articulado
                                      </option>
                                      <option value="TPAF19">
                                        TPAF19: autorización expresa para
                                        circular en los caminos y puentes de
                                        jurisdicción federal con configuraciones
                                        de tractocamion doblemente articulado
                                      </option>
                                      <option value="TPAF20">
                                        TPAF20: autotransporte federal de carga
                                        especializada de fondos y valores
                                      </option>
                                      <option value="TPTA01">
                                        TPTA01: Concesión y autorización para el
                                        servicio regular nacional y/o
                                        internacional para empresas mexicanas
                                      </option>
                                      <option value="TPTA02">
                                        TPTA02: Permiso para el servicio aereo
                                        regular de empresas extranjeras
                                      </option>
                                      <option value="TPTA03">
                                        TPTA03: Permiso para el servicio
                                        nacional e internacional no regular de
                                        fletamiento
                                      </option>
                                      <option value="TPTA04">
                                        TPTA04: Permiso para el servicio
                                        nacional e internacional no regular de
                                        taxi aereo
                                      </option>
                                      <option value="TPTMO01">
                                        TPTMO01: Permiso temporal para
                                        navegación de cabotaje
                                      </option>
                                      <option value="TPX00">
                                        TPX00: Permiso no contemplado en el
                                        catalogo
                                      </option>
                                    </select>
                                  </div>

                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Número de Permiso SCT *
                                    </label>
                                    <input
                                      className="InputForm"
                                      type="text"
                                      maxLength={50}
                                      onChange={(e) =>
                                        setNumPermiso(e.target.value)
                                      }
                                      value={numPermisoSCT}
                                      required
                                    ></input>
                                  </div>
                                </div>

                                <h5 style={{ margin: "1rem 0" }}>
                                  Identificación Vehícular
                                </h5>
                                <div className="cartaPorte__group">
                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Configuración Vehícular *
                                    </label>
                                    <select
                                      className="SelectTrigger"
                                      value={confVehicular}
                                      onChange={(e) => {
                                        let remolqueRequired = [
                                          "C2",
                                          "C3",
                                          "OTROSG",
                                          "GPLUTA",
                                          "GPLUTB",
                                          "GPLUTD",
                                          "GPLATA",
                                          "GPLATB",
                                          "GPLATC",
                                          "GPLUTC",
                                          "GPLATD",
                                        ];
                                        console.log(
                                          "Target confVehicular",
                                          e.target.value
                                        );

                                        if (
                                          !remolqueRequired.includes(
                                            e.target.value
                                          )
                                        ) {
                                          setUseContainer(true);
                                        } else {
                                          setUseContainer(false);
                                        }
                                        setConfVehicular(e.target.value);
                                      }}
                                      required
                                    >
                                      <option value="" disabled>
                                        Escoge una opción
                                      </option>
                                      {confVehicularData &&
                                      confVehicularData.length > 0
                                        ? confVehicularData.map(
                                            (confVehicular) => (
                                              <option
                                                key={confVehicular._id}
                                                value={confVehicular.Clave}
                                              >
                                                {confVehicular.confVehicular}
                                              </option>
                                            )
                                          )
                                        : ""}
                                    </select>
                                  </div>
                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Peso Bruto Vehicular (KG) *
                                    </label>
                                    <input
                                      className="InputForm"
                                      type="number"
                                      onChange={(e) =>
                                        setPesoVehiculo(e.target.value)
                                      }
                                      required
                                      value={pesoVehiculo}
                                    ></input>
                                  </div>

                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Placa del vehículo *
                                    </label>
                                    <input
                                      className="InputForm"
                                      type="text"
                                      onChange={(e) =>
                                        setPlacaVehiculo(e.target.value)
                                      }
                                      required
                                      value={placaVehiculo}
                                    ></input>
                                  </div>

                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Año del vehículo *
                                    </label>
                                    <input
                                      className="InputForm"
                                      type="number"
                                      onChange={(e) =>
                                        setAnioVehiculo(e.target.value)
                                      }
                                      required
                                      value={anioVehiculo}
                                    ></input>
                                  </div>
                                </div>
                              </div>

                              <hr></hr>
                              <div style={{ marginTop: "20px" }}>
                                <h3>Seguros</h3>

                                <div className="cartaPorte__group">
                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Aseguradora Responsable Civil *
                                    </label>
                                    <input
                                      className="InputForm"
                                      type="text"
                                      maxLength={50}
                                      onChange={(e) =>
                                        setNombreSeguro(e.target.value)
                                      }
                                      required
                                      value={nombreSeguro}
                                    ></input>
                                  </div>
                                  <div className="cartaPorte__group_elements">
                                    <label className="FormLabel" htmlFor="name">
                                      Poliza Responsable Civil *
                                    </label>
                                    <input
                                      className="InputForm"
                                      type="text"
                                      maxLength={30}
                                      onChange={(e) =>
                                        setPolizaSeguro(e.target.value)
                                      }
                                      required
                                      value={polizaSeguro}
                                    ></input>
                                  </div>
                                </div>

                                {medAmbiente ? (
                                  <>
                                    <div className="cartaPorte__group">
                                      <div className="cartaPorte__group_elements">
                                        <label
                                          className="FormLabel"
                                          htmlFor="name"
                                        >
                                          Aseguradora Medio Ambiente *
                                        </label>
                                        <input
                                          className="InputForm"
                                          type="text"
                                          maxLength={50}
                                          onChange={(e) =>
                                            setSeguroMedAmbiente(e.target.value)
                                          }
                                          required
                                          value={seguroMedAmbiente}
                                        ></input>
                                      </div>

                                      <div className="cartaPorte__group_elements">
                                        <label
                                          className="FormLabel"
                                          htmlFor="name"
                                        >
                                          Poliza Medio Ambiente *
                                        </label>
                                        <input
                                          className="InputForm"
                                          maxLength={30}
                                          type="text"
                                          onChange={(e) =>
                                            setPolizaMedAmbiente(e.target.value)
                                          }
                                          required
                                          value={polizaMedAmbiente}
                                        ></input>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              <hr></hr>
                              {useContainer ? (
                                <>
                                  <h3>Remolques</h3>
                                  {/* <div className="cartaPorte__group">
                                        <div className="cartaPorte__group_elements">
                                          <label className="FormLabel" htmlFor="name">
                                            Remolques Registrados
                                          </label>
                                          <select
                                            className="SelectTrigger"
                                            onChange={handleChangeRemolque}
                                            value={remolque}
                                            required
                                          >
                                            <option value="" disabled>
                                              Escoge una opción
                                            </option>
                                            {
                                              remolquesData && remolquesData.length>0 ?
                                              remolquesData.map((remolque)=>(
                                                <option key={remolque._id} value={remolque._id}>{remolque.placaRemolque}</option>
                                              )):""
                                            }
                                          </select>
                                        </div>
                                      </div> */}

                                  <div className="cartaPorte__group">
                                    <div className="cartaPorte__group_elements">
                                      <label
                                        className="FormLabel"
                                        htmlFor="name"
                                      >
                                        SubTipo de Remolque *
                                      </label>
                                      <select
                                        className="SelectTrigger"
                                        onChange={(e) =>
                                          setTipoRemolque(e.target.value)
                                        }
                                        value={tipoRemolque}
                                        required
                                      >
                                        <option value="" disabled>
                                          Escoge una opción
                                        </option>
                                        <option value="CTR001">
                                          CTR001 Caballete
                                        </option>
                                        <option value="CTR002">
                                          CTR002 Caja
                                        </option>
                                        <option value="CTR003">
                                          CTR003 Caja Abierta
                                        </option>
                                        <option value="CTR004">
                                          CTR004 Caja Cerrada
                                        </option>
                                        <option value="CTR005">
                                          CTR005 Caja De Recolección Con
                                          Cargador Frontal
                                        </option>
                                        <option value="CTR006">
                                          CTR006 Caja Refrigerada
                                        </option>
                                        <option value="CTR007">
                                          CTR007 Caja Seca
                                        </option>
                                        <option value="CTR008">
                                          CTR008 Caja Transferencia
                                        </option>
                                        <option value="CTR009">
                                          CTR009 Cama Baja o Cuello Ganso
                                        </option>
                                        <option value="CTR010">
                                          CTR010 Chasis Portacontenedor
                                        </option>
                                        <option value="CTR011">
                                          CTR011 Convencional De Chasis
                                        </option>
                                        <option value="CTR012">
                                          CTR012 Equipo Especial
                                        </option>
                                        <option value="CTR013">
                                          CTR013 Estacas
                                        </option>
                                        <option value="CTR014">
                                          CTR014 Góndola Madrina
                                        </option>
                                        <option value="CTR015">
                                          CTR015 Grúa Industrial
                                        </option>
                                        <option value="CTR016">
                                          CTR016 Grúa
                                        </option>
                                        <option value="CTR017">
                                          CTR017 Integral
                                        </option>
                                        <option value="CTR018">
                                          CTR018 Jaula
                                        </option>
                                        <option value="CTR019">
                                          CTR019 Media Redila
                                        </option>
                                        <option value="CTR020">
                                          CTR020 Pallet o Celdillas
                                        </option>
                                        <option value="CTR021">
                                          CTR021 Plataforma
                                        </option>
                                        <option value="CTR022">
                                          CTR022 Plataforma Con Grúa
                                        </option>
                                        <option value="CTR023">
                                          CTR023 Plataforma Encortinada
                                        </option>
                                        <option value="CTR024">
                                          CTR024 Redilas
                                        </option>
                                        <option value="CTR025">
                                          CTR025 Refrigerador
                                        </option>
                                        <option value="CTR026">
                                          CTR026 Revolvedora
                                        </option>
                                        <option value="CTR027">
                                          CTR027 Semicaja
                                        </option>
                                        <option value="CTR028">
                                          CTR028 Tanque
                                        </option>
                                        <option value="CTR029">
                                          CTR029 Tolva
                                        </option>
                                        <option value="CTR031">
                                          CTR031 Volteo
                                        </option>
                                        <option value="CTR032">
                                          CTR032 Volteo Desmontable
                                        </option>
                                      </select>
                                    </div>
                                    <div className="cartaPorte__group_elements">
                                      <label
                                        className="FormLabel"
                                        htmlFor="name"
                                      >
                                        Placa de la Caja *
                                      </label>
                                      <input
                                        className="InputForm"
                                        type="text"
                                        minLength={6}
                                        maxLength={7}
                                        onChange={(e) =>
                                          setPlacaCaja(e.target.value)
                                        }
                                        value={placaCaja}
                                        required
                                      ></input>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}

                              <div style={{ marginTop: "20px" }}>
                                <div style={{ marginTop: "20px" }}>
                                  <h3 style={{ marginBottom: "30px" }}>
                                    Figura Transporte
                                  </h3>

                                  <div className="cartaPorte__group">
                                    <div className="cartaPorte__group_elements">
                                      <label
                                        className="FormLabel"
                                        htmlFor="name"
                                      >
                                        Tipo de Figura *
                                      </label>
                                      <select
                                        disabled
                                        className="SelectTrigger"
                                        onChange={(e) =>
                                          setTipoFigura(e.target.value)
                                        }
                                        value={tipoFigura}
                                        required
                                      >
                                        <option value="" disabled>
                                          Escoge una opción
                                        </option>
                                        <option value="01">Operador</option>
                                        <option value="02">Propietario</option>
                                        <option value="03">Arrendador</option>
                                        <option value="04">Notificado</option>
                                      </select>
                                    </div>

                                    <div className="cartaPorte__group_elements">
                                      <label
                                        className="FormLabel"
                                        htmlFor="name"
                                      >
                                        RFC Figura *
                                      </label>
                                      <input
                                        className="InputForm"
                                        type="text"
                                        onChange={(e) =>
                                          setRFCFigura(e.target.value)
                                        }
                                        required
                                        value={RFCFigura}
                                      ></input>
                                    </div>

                                    <div className="cartaPorte__group_elements">
                                      <label
                                        className="FormLabel"
                                        htmlFor="name"
                                      >
                                        Número de Licencia *
                                      </label>
                                      <input
                                        className="InputForm"
                                        type="text"
                                        onChange={(e) =>
                                          setNumLicencia(e.target.value)
                                        }
                                        required
                                        value={numLicencia}
                                      ></input>
                                    </div>

                                    <div className="cartaPorte__group_elements">
                                      <label
                                        className="FormLabel"
                                        htmlFor="name"
                                      >
                                        Nombre de Figura *
                                      </label>
                                      <input
                                        disabled
                                        className="InputForm"
                                        type="text"
                                        onChange={(e) =>
                                          setNombreFigura(e.target.value)
                                        }
                                        required
                                        value={nombreFigura}
                                      ></input>
                                    </div>
                                  </div>
                                  <h5 style={{ margin: "1rem 0" }}>
                                    Domicilio de la figura
                                  </h5>

                                  <div className="cartaPorte__group">
                                    <div className="cartaPorte__group_elements">
                                      <label
                                        className="FormLabel"
                                        htmlFor="name"
                                      >
                                        Calle
                                      </label>
                                      <input
                                        disabled
                                        className="InputForm"
                                        type="text"
                                        onChange={(e) =>
                                          setCalleFigura(e.target.value)
                                        }
                                        value={calleFigura}
                                      ></input>
                                    </div>

                                    <div className="cartaPorte__group_elements">
                                      <label
                                        className="FormLabel"
                                        htmlFor="name"
                                      >
                                        Número Exterior
                                      </label>
                                      <input
                                        disabled
                                        className="InputForm"
                                        type="text"
                                        onChange={(e) =>
                                          setNumExt(e.target.value)
                                        }
                                        value={numExt}
                                      ></input>
                                    </div>

                                    <div className="cartaPorte__group_elements">
                                      <label
                                        className="FormLabel"
                                        htmlFor="name"
                                      >
                                        Número Interior
                                      </label>
                                      <input
                                        disabled
                                        className="InputForm"
                                        type="text"
                                        onChange={(e) =>
                                          setNumInt(e.target.value)
                                        }
                                        value={numInt}
                                      ></input>
                                    </div>

                                    <div className="cartaPorte__group_elements">
                                      <label
                                        className="FormLabel"
                                        htmlFor="name"
                                      >
                                        Referencia
                                      </label>
                                      <textarea
                                        disabled
                                        className="InputForm"
                                        type="text"
                                        style={{
                                          border: "none",
                                          boxShadow: "none",
                                          backgroundColor: "#f3f3f3",
                                        }}
                                        onChange={(e) =>
                                          setRefFigura(e.target.value)
                                        }
                                        value={refFigura}
                                      ></textarea>
                                    </div>

                                    <div className="cartaPorte__group_elements">
                                      <label
                                        className="FormLabel"
                                        htmlFor="name"
                                      >
                                        Municipio
                                      </label>
                                      <input
                                        disabled
                                        className="InputForm"
                                        type="text"
                                        onChange={(e) =>
                                          setMunicipioFigura(e.target.value)
                                        }
                                        value={nameMunicipio}
                                      ></input>
                                    </div>

                                    <div className="cartaPorte__group_elements">
                                      <label
                                        className="FormLabel"
                                        htmlFor="name"
                                      >
                                        Estado *
                                      </label>
                                      <input
                                        disabled
                                        className="InputForm"
                                        type="text"
                                        onChange={(e) =>
                                          setEstadoFigura(e.target.value)
                                        }
                                        required
                                        value={nameEstado}
                                      ></input>
                                    </div>

                                    <div className="cartaPorte__group_elements">
                                      <label
                                        className="FormLabel"
                                        htmlFor="name"
                                      >
                                        País *
                                      </label>

                                      <select
                                        disabled
                                        id="selectPaises4"
                                        className="SelectTrigger"
                                        onChange={(e) => {
                                          setPaisFigura(e.target.value);
                                        }}
                                        value={paisFigura}
                                        required
                                      >
                                        <option value="" disabled>
                                          Escoge un país
                                        </option>
                                        <option value="MEX">México</option>
                                        <option value="USA">
                                          Estados Unidos
                                        </option>
                                      </select>
                                    </div>

                                    <div className="cartaPorte__group_elements">
                                      <label
                                        className="FormLabel"
                                        htmlFor="name"
                                      >
                                        Código Postal *
                                      </label>
                                      <input
                                        disabled
                                        className="InputForm"
                                        type="number"
                                        onChange={(e) =>
                                          setCPFigura(e.target.value)
                                        }
                                        required
                                        value={CPFigura}
                                      ></input>
                                    </div>
                                    <div className="cartaPorte__group_elements">
                                      <label
                                        className="FormLabel"
                                        htmlFor="name"
                                      >
                                        Colonia
                                      </label>
                                      <input
                                        disabled
                                        className="InputForm"
                                        type="text"
                                        onChange={(e) =>
                                          setColoniaFigura(e.target.value)
                                        }
                                        value={nameColonia}
                                      ></input>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          <button
                            className="btn__login"
                            style={{
                              marginTop: "2rem",
                              width: "240px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            type="submit"
                            disabled={isLoading}
                          >
                            {/* <ProgressSpinner style={{ width: '20%', height: '20px', color: "white" }} strokeWidth="4" animationDuration="2s" /> */}

                            {isLoading ? (
                              <SyncLoader
                                color="white"
                                loading={isLoading}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                speedMultiplier={0.7}
                              />
                            ) : cartaPorte === "Sí" ? (
                              "Generar Carta Porte"
                            ) : (
                              "Generar Factura"
                            )}
                          </button>
                        </form>
                        <Dialog.Close asChild></Dialog.Close>
                        <Dialog.Close asChild>
                          <button className="IconButton" aria-label="Close">
                            <Cross2Icon />
                          </button>
                        </Dialog.Close>
                      </Dialog.Content>
                    </Dialog.Portal>
                  </Dialog.Root>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ReporteRutas;
