import { PageInfo } from "../PageInfo";
import * as Form from "@radix-ui/react-form";
import { useState, useEffect, useRef } from "react";
import "@radix-ui/themes/styles.css";
import "../../styles/adminchof.css";
import { FilterMatchMode } from "primereact/api";
import * as Dialog from "@radix-ui/react-dialog";
import * as Tabs from "@radix-ui/react-tabs";
import "../../styles/select.css";
import "../../styles/tabs.css";
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css"; // Elige un tema que prefieras
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../../index.css";
import SyncLoader from "react-spinners/ClipLoader";
import { Cross2Icon } from "@radix-ui/react-icons";
import { API_URL, RFC_Orig } from "../../constants";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { FaMinus, FaPaypal } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import * as Checkbox from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";

import jwtDecode from "jwt-decode";
import { CartaPorte } from "./CartaPorte";

export const CuentasPorCobrar = () => {
  const dt = useRef(null);

  let customerObj = {
    razonSocial: "",
    RFC: "",
    codigoPostal: "",
    regimenFiscal: "",
  };

  let facturaObj = {
    CfdiType: "I",
    PaymentForm: "",
    PaymentMethod: "",
    Exportation: "",
    Currency: "MXN",
  };

  let itemsObj = [
    {
      Quantity: "1",
      ProductCode: "",
      Unit: "Unidad de Servicio",
      UnitCode: "E48",
      Description: "",
      UnitPrice: "0",
      Subtotal: "0",
      TaxObject: "",
      Total: "0",
    },
  ];

  let filtersFacturasObj = {
    idFactura: "",
    clientName: "",
    emisor: "",
    paymentForm: "",
    status: "",
    currency: "MXN",
    createdBy: "",
    createdAt: new Date().toISOString().split("T")[0],
    complemento: "",
  };

  let registroPagosObj = {
    totalPago: "",
    comprobante: "",
    totalCubierto: "",
    totalFaltante: "",
  };
  const [transpInt, setTranspInt] = useState(false);
  const [logInversa, setLogInversa] = useState(false);
  const [matPeligroso, setMatPeligroso] = useState("No");

  let mercanciaObj = {
    transpInt: transpInt ? "Sí" : "No",
    entradaSalida: "",
    fraccAranc: "",
    impoVucem: "",
    permImpo: "",
    sectorCofepris: "",
    denominacionGenProd: "",
    denominacionDistProd: "",
    fabricante: "",
    fechaCaducidad: "",
    loteMed: "",
    formaFarmaceutica: "",
    condTransporte: "",
    registroSanitario: "",
    ingActivo: "",
    nomQuimico: "",
    numCAS: "",
    regSanPlag: "",
    datosFabricante: "",
    datosFormulador: "",
    datosMaquilador: "",
    usoAutorizado: "",
    razonSocialEmpImp: "",
    tipoMateria: "",
    descripcion: "",
    bienesTransp: "",
    descripcionMateria: "",
    cantidad: "",
    claveUnidad: "",
    matPeligroso: matPeligroso || "",
    cveMatPeligroso: "",
    embalaje: "",
    pesoMateria: "",
    tipoDoc: "",
    numPedimento: "",
    cantidadMaterialTransp: "",
    RFCImportador: "",
    identDocAduanero: "",
  };

  const [mercancias, setMercancias] = useState([mercanciaObj]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCancelar, setIsLoadingCancelar] = useState(false);
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isLoadingPago, setIsLoadingPago] = useState(false);

  const [customersData, setCustomersData] = useState([]);
  const [customerReceiver, setCustomerReceiver] = useState(customerObj);
  const [regimenesFiscales, setRegimenesFiscales] = useState([]);
  const [CFDIObj, setCFDIObj] = useState(facturaObj);

  const [paymentForm, setPaymentForm] = useState("");
  const [cfdiType, setCfdiType] = useState("I");
  const [cfdiUse, setCfdiUse] = useState("");
  const [dollarValue, setDollarValue] = useState("");
  const [items, setItems] = useState(itemsObj);
  const [unitPriceAux, setUnitPriceAux] = useState("");

  const [mxnPrice, setMxnPrice] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");

  const [facturasData, setFacturasData] = useState([]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    clientName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    clientRFC: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    description: { value: null, matchMode: FilterMatchMode.EQUALS },
    paymentMethod: { value: null, matchMode: FilterMatchMode.EQUALS },
    paymentForm: { value: null, matchMode: FilterMatchMode.EQUALS },
    quantity: { value: null, matchMode: FilterMatchMode.EQUALS },
    currency: { value: null, matchMode: FilterMatchMode.EQUALS },
    unitPrice: { value: null, matchMode: FilterMatchMode.EQUALS },
    subtotal: { value: null, matchMode: FilterMatchMode.EQUALS },
    total: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    createdBy: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [emailCliente, setEmailCliente] = useState("");
  const [isDialogSendFacturaOpen, setIsDialogSendFacturaOpen] = useState(false);
  const [isDialogPagosOpen, setIsDialogPagosOpen] = useState(false);
  const [idFactura, setIdFactura] = useState("");

  const [isLoadingFactura, setIsLoadingFactura] = useState(false);
  const [isLoadingXML, setIsLoadingXML] = useState(false);
  const [isLoadingNotaPago, setIsLoadingNotaPago] = useState("");
  const [motivoCancelacion, setMotivoCancelacion] = useState("");
  const [idViaje, setIdViaje] = useState("");
  const [formasPagos, setFormasPago] = useState([]);

  const [filtersFacturas, setfiltersFacturas] = useState(filtersFacturasObj);

  const [infoPago, setInfoPago] = useState([]);
  const [registroPagos, setRegistroPagos] = useState([]);
  const [totalACubrir, setTotalACubrir] = useState("");
  const [isExcedente, setIsExcedente] = useState(false);

  const [emisor, setEmisor] = useState("");
  const [emisorFactura, setEmisorFactura] = useState("");

  const [customerValue, setCustomerValue] = useState("");
  const [cartaPorte, setCartaPorte] = useState("No");

  const [formaFarmaceutica, setFormaFarmaceutica] = useState([]);
  const [condicionesEspeciales, setCondicionesEspeciales] = useState([]);
  const [embalajeData, setEmbalajeData] = useState("");
  const [entradaSalida, setEntradaSalida] = useState("");

  const [transportsData, setTransportsData] = useState([]);
  const [autoTransporte, setAutoTransporte] = useState([]);
  const [permisos, setPermisos] = useState([]);

  let remolqueObj = {
    SubTipoRem: "",
    Placa: "",
  };

  const [remolque, setRemolque] = useState([remolqueObj]);

  const [figuraTransporte, setFiguraTransporte] = useState([]);

  let ubicacionesObj = [
    {
      TipoUbicacion: "Origen",
    },
    {
      TipoUbicacion: "Destino",
    },
  ];

  const [ubicaciones, setUbicaciones] = useState(ubicacionesObj);
  const [viaEntSal, setViaEntSal] = useState("");
  const [regimAduan, setRegimAduan] = useState("");
  const [paisOrigDest, setPaisOrigDest] = useState("");

  useEffect(() => {
    let perms = JSON.parse(localStorage.getItem("permisos"));
    setPermisos(perms);

    if (perms.length <= 1) {
      setfiltersFacturas({
        ...filtersFacturas,
        emisor: perms[0],
      });

      fetchCustomersData(perms[0]);
    }

    fetchRegimenesFiscales();
    fetchDollarValue();
    // fetchFacturasData();
    fetchFormasPago();
    const token = localStorage.getItem("token");
    if (token) {
      const decodedUser = jwtDecode(token);
      setUserName(decodedUser.name);
      console.log("Decoded user", decodedUser);
      setUserId(decodedUser.uid);
      if (decodedUser.rol !== 3) {
        setfiltersFacturas({
          ...filtersFacturas,
          createdBy: decodedUser.uid,
        });
      }
    }
  }, []);

  const descargarFacturaPDF = async () => {
    setIsLoadingFactura(true);

    try {
      const response = await fetch(`${API_URL}/api/cfdi/descargarpdf`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idFactura,
          emisor: emisorFactura ? emisorFactura : permisos[0],
        }),
      });
      const data = await response.json();
      const byteCharacters = atob(data);
      const jsonContent = JSON.parse(byteCharacters);
      const base64Content = jsonContent.Content;
      const binaryContent = atob(base64Content);
      const byteArray = new Uint8Array(binaryContent.length);
      for (let i = 0; i < binaryContent.length; i++) {
        byteArray[i] = binaryContent.charCodeAt(i);
      }
      const blob = new Blob([byteArray], { type: jsonContent.ContentType });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Factura_${idFactura}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsLoadingFactura(false);
    } catch (error) {
      notifyError("No se pudo descargar la factura");
      setIsLoadingFactura(false);
    }
  };

  const descargarFacturaXML = async () => {
    setIsLoadingXML(true);
    const response = await fetch(`${API_URL}/api/cfdi/descargarxml`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idFactura,
        emisor: emisorFactura ? emisorFactura : permisos[0],
      }),
    });
    const data = await response.json();
    const byteCharacters = atob(data);
    const jsonContent = JSON.parse(byteCharacters);
    const base64Content = jsonContent.Content;
    const binaryContent = atob(base64Content);
    const byteArray = new Uint8Array(binaryContent.length);
    for (let i = 0; i < binaryContent.length; i++) {
      byteArray[i] = binaryContent.charCodeAt(i);
    }

    const xmlString = new TextDecoder().decode(byteArray);
    const blob = new Blob([xmlString], { type: "application/xml" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Factura_${idFactura}.xml`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsLoadingXML(false);
  };

  const handleSendFactura = async (e) => {
    e.preventDefault();
    setIsLoadingSend(true);
    try {
      const response = await fetch(`${API_URL}/api/cfdi/send-factura`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailCliente,
          idFactura,
          emisor: emisorFactura ? emisorFactura : permisos[0],
        }),
      });
      setIsLoadingSend(false);

      if (response.status == 200) {
        notifySuccess("¡Factura enviada con éxito!");
        setIsDialogSendFacturaOpen(false);
      } else {
        notifyError("No se pudo enviar la factura");
        setIsDialogSendFacturaOpen(true);
      }
    } catch (error) {
      setIsLoadingSend(false);

      notifyError("No se pudo enviar la factura");
    }
  };

  const CancelarCFDI = async (e) => {
    if (!motivoCancelacion) {
      notifyError("escoge el motivo de cancelación.");
      return false;
    }
    setIsLoadingCancelar(false);
    try {
      const response = await fetch(`${API_URL}/api/cfdi/cancel-factura`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idFactura,
          motivoCancelacion,
          emisor: emisorFactura ? emisorFactura : permisos[0],
        }),
      });
      const data = await response.json();
      setIsLoadingCancelar(false);
      if (data.Status == "canceled") {
        setIsDialogSendFacturaOpen(false);
        handleSearchFacturas(e);
        setIsDialogSendFacturaOpen(false);
        // fetchRoutesData();
        // notifyCancelacion();
      } else {
        notifyError(data.Message);
      }
    } catch (error) {
      setIsLoadingCancelar(false);
      notifyError("No se pudo realizar la cancelación de la factura");
    }
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            style={{ marginLeft: "30px" }}
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar"
          />
        </span>
      </div>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(facturasData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "facturas");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const registrarPagoBody = (rowData) => {
    // if (rowData.estatus != "Pendiente Facturación") {
    //   return null;
    // }

    return (
      <>
        <Button
          rounded
          outlined
          severity="primary"
          placeholder="Guardar"
          className="pi pi-save btn__pi"
          style={{ color: "#5c6f80" }}
          onClick={() => {
            setIsDialogPagosOpen(true);
            setInfoPago(rowData);
            setTotalACubrir(rowData.totalFaltante);
            setRegistroPagos(rowData.pagos);
          }}
        ></Button>
      </>
    );
  };

  const getStatusColor = (status) => {
    let colors = [
      {
        status: "Pendiente Chofer",
        color: "#ffc107",
      },

      {
        status: "Pendiente Gastos Diesel",
        color: "#ffc107",
      },

      {
        status: "Pendiente Gastos Viaje",
        color: "#ffc107",
      },
      {
        status: "Pendiente Precio Venta",
        color: "#ffc107",
      },
      {
        status: "Pendiente Vehículo",
        color: "#ffc107",
      },
      {
        status: "Pendiente Referencia",
        color: "#ffc107",
      },
      {
        status: "Pendiente Facturación",
        color: "#17a2b8",
      },
      {
        status: "Pendiente Pago",
        color: "#cf9b00",
        // color: "#ffc107"
      },
      {
        status: "Viaje en curso",
        color: "#28a745",
      },
      {
        status: "Finalizado",
        color: "#28a745",
      },
    ];

    let color = colors.find((value) => {
      if (value.status == status) return value.color;
    });

    return color ? color.color : "#dc3545";
  };
  const notifySuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };
  const notifyWarning = (message) => {
    toast.warning(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const fetchCustomersData = async (emisor) => {
    try {
      const response = await fetch(
        `${API_URL}/api/customerUpdate?empresa=${emisor}&estatus=Aprobado`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      // const approvedCustomers = data.filter(
      //   (item) => item.estatus === "Aprobado"
      // );

      console.log("data", data);
      // setCustomersData(data);

      console.log("Llamando a fetchCustomersData");
      if (data.status === "Ok") {
        setCustomersData(data.customers);
      } else {
        notifyWarning(data.message);
        setCustomersData([]);
      }
    } catch (error) {
      notifyError("No se puede devolver la información de los clientes");
    }
  };

  const fetchRegimenesFiscales = async () => {
    try {
      const response = await fetch(`${API_URL}/api/catalagos/regimenFiscal`);
      const data = await response.json();
      setRegimenesFiscales(data);
    } catch (error) {
      notifyError(
        "No se pudo devolver la información de los regimenes fiscales"
      );
    }
  };

  const fetchDollarValue = async () => {
    try {
      let response = await fetch(`${API_URL}/api/divisas/dolar`);
      let data = await response.json();
      let dolar = data.response[0].dato;
      setDollarValue(Number(dolar).toFixed(2));
    } catch (error) {
      notifyError("No se puede acceder al valor del dolar");
    }
  };

  const fetchFormasPago = async () => {
    try {
      let response = await fetch(`${API_URL}/api/catalagoFormasPago`);
      let data = await response.json();
      if (data.formasPago) {
        setFormasPago(data.formasPago);
      } else {
        notifyWarning(data.message);
      }
    } catch (error) {
      notifyError("No se pudieron obtener las formas de pago");
    }
  };

  const fetchFacturasData = async () => {
    try {
      let response = await fetch(`${API_URL}/api/facturas`);
      let data = await response.json();
      if (data.status === "Ok") {
        setFacturasData(data.facturas);
      }
    } catch (error) {
      notifyError("No se pudo obtener la data de las facturas");
    }
  };

  const fetchDataTransport = async () => {
    try {
      let response = await fetch(`${API_URL}/api/transports/`);
      let data = await response.json();
      console.log("DATA transports", data);
      setTransportsData(data);
    } catch (error) {
      notifyError(error);
    }
  };

  const handleChangeClient = (e) => {
    let custReceiver = customersData.find(
      (customer) => customer._id === e.target.value
    );
    setCustomerValue(e.target.value);
    setCustomerReceiver(custReceiver);
  };

  const getTotalValue = (quantity, unitPrice, taxObj = "01") => {
    let total = Number(quantity) * Number(unitPrice);
    let subtotal = total;
    if (taxObj === "02") {
      subtotal = Number(quantity) * Number(unitPrice);
      total = Number(quantity * unitPrice * 1.16);
    } else if (taxObj === "03") {
      total = Number(quantity * unitPrice);
      subtotal = total;
    }

    return { subtotal: subtotal.toFixed(2), total: total.toFixed(2) };
  };

  const handleChangeFacturaObj = (e) => {
    let factura = CFDIObj;
    setCFDIObj({
      ...CFDIObj,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "Currency") {
      if (e.target.value === "USN") {
        setUnitPriceAux(Number(unitPriceAux / dollarValue).toFixed(2));
        let precioUnit;

        if (items[0].TaxObject === "03") {
          precioUnit = Number((unitPriceAux / dollarValue) * 1.16).toFixed(2);
        }
        setItems([
          {
            ...items[0],
            UnitPrice:
              items[0].TaxObject === "03"
                ? precioUnit
                : Number(unitPriceAux / dollarValue).toFixed(2),
            Total: Number(items[0].Total / dollarValue).toFixed(2),
            Subtotal: Number(items[0].Subtotal / dollarValue).toFixed(2),
          },
        ]);
      }

      if (e.target.value === "MXN") {
        setUnitPriceAux(mxnPrice);
        let total = getTotalValue(
          items[0].Quantity,
          mxnPrice,
          items[0].TaxObject
        );

        if (items[0].TaxObject === "03") {
          setItems([
            {
              ...items[0],
              UnitPrice: Number(mxnPrice * 1.16).toFixed(2),
              Subtotal: Number(mxnPrice * items[0].Quantity * 1.16).toFixed(2),
              Total: Number(mxnPrice * items[0].Quantity * 1.16).toFixed(2),
            },
          ]);
        } else {
          setItems([
            {
              ...items[0],
              UnitPrice: mxnPrice,
              Total: total.total,
              Subtotal: total.subtotal,
            },
          ]);
        }
      }
    }
  };

  const handleItemsChange = (e) => {
    let itemsArray = [...items];

    if (e.target.name === "Quantity") {
      let total = getTotalValue(
        e.target.value,
        itemsArray[0].UnitPrice,
        items[0].TaxObject
      );

      itemsArray[0] = {
        ...itemsArray[0],
        Quantity: e.target.value,
        Total: total.total,
        Subtotal: total.subtotal,
      };
    } else if (e.target.name === "UnitPrice") {
      // let total = Number(itemsArray[0].Quantity) * Number(e.target.value);
      let total = getTotalValue(
        itemsArray[0].Quantity,
        e.target.value,
        items[0].TaxObject
      );
      setUnitPriceAux(e.target.value);
      if (CFDIObj.Currency === "MXN") {
        setMxnPrice(e.target.value);
      }
      itemsArray[0] = {
        ...itemsArray[0],
        UnitPrice: e.target.value,
        Total: total.total,
        Subtotal: total.subtotal,
      };
    } else if (e.target.name === "TaxObject") {
      let total = getTotalValue(
        itemsArray[0].Quantity,
        unitPriceAux,
        e.target.value
      );
      itemsArray[0] = {
        ...itemsArray[0],
        [e.target.name]: e.target.value,
        UnitPrice: unitPriceAux,
        Total: total.total,
        Subtotal: total.subtotal,
      };

      if (e.target.value === "03") {
        itemsArray[0] = {
          ...itemsArray[0],
          UnitPrice: Number(unitPriceAux * 1.16).toFixed(2),
          Subtotal: Number(unitPriceAux * items[0].Quantity * 1.16).toFixed(2),
          Total: Number(unitPriceAux * items[0].Quantity * 1.16).toFixed(2),
        };
      }
    } else {
      itemsArray[0] = {
        ...itemsArray[0],
        [e.target.name]: e.target.value,
      };
    }

    setItems([...itemsArray]);
  };

  const handleEntradaSalida = ({ target }) => {
    let valueInput = target.value;

    let currentArray = mercancias.map((element) => {
      element.entradaSalida = valueInput;
      return element;
    });

    setEntradaSalida(valueInput);

    setMercancias([...currentArray]);
  };

  const handleInputChange = (target, index) => {
    let currentArray = mercancias[index];
    let valueInput = target.value;
    if (target.name == "sectorCofepris") {
      currentArray.denominacionGenProd = "";
      currentArray.denominacionDistProd = "";
      currentArray.fabricante = "";
      currentArray.fechaCaducidad = "";
      currentArray.loteMed = "";
      currentArray.formaFarmaceutica = "";
      currentArray.condTransporte = "";
      currentArray.registroSanitario = "";
      currentArray.ingActivo = "";
      currentArray.nomQuimico = "";
      currentArray.numCAS = "";
      currentArray.regSanPlag = "";
      currentArray.datosFabricante = "";
      currentArray.datosFormulador = "";
      currentArray.datosMaquilador = "";
      currentArray.usoAutorizado = "";
      currentArray.razonSocialEmpImp = "";
      if (
        valueInput == "01" ||
        valueInput == "02" ||
        (valueInput == "03" && formaFarmaceutica.length <= 0)
      ) {
        fetchFormaFarmaceutica();
        fetchCondicionesEspeciales();
      }
    }

    let newObj = {
      ...currentArray,
      [target.name]: valueInput,
    };

    let mercanciasArray = [...mercancias];
    mercanciasArray[index] = newObj;
    setMercancias([...mercanciasArray]);
  };
  const handleTranspInt = (isChecked) => {
    setTranspInt(isChecked ? "Sí" : "No");
  };

  const handleDeleteMercancia = (index) => {
    let merchArray = [...mercancias];
    let merchArraySlice = merchArray.splice(index, 1);
    setMercancias([...merchArray]);
  };

  const handleAddMerch = (e) => {
    e.preventDefault();
    console.log("Mercancias", mercancias);
    setMercancias([...mercancias, mercanciaObj]);
  };

  const handleMaterialPeligroso = (isChecked, index) => {
    // mercancias[1].matPeligroso =
    console.log("valueChevekd material peligroso");
    let valueChecked = isChecked ? "Sí" : "No";
    let currentArray = mercancias[index];

    if (embalajeData === "") {
      fetchEmbalajeData();
    }

    let newObj = {
      ...currentArray,
      matPeligroso: valueChecked,
    };
    let mercanciasArray = [...mercancias];
    mercanciasArray[index] = newObj;

    setMatPeligroso(valueChecked);
    setMercancias([...mercanciasArray]);

    if (valueChecked == "Sí") {
      let filteredData = transportsData.filter(
        (transport) => transport.medAmbiente === true
      );
      setTransportsData(filteredData);
    } else {
      let matPelig = [];

      mercanciasArray.forEach((mercancia) => {
        matPelig.push(mercancia.matPeligroso);
      });
      console.log("MatPelig", matPelig);
      if (!matPelig.includes("Sí")) {
        console.log("Entrpo en no tiene matPeligroso");
        notifyWarning("Los transportes han sido actualizados, revisa de nuevo");
        fetchDataTransport();
      }
    }
  };

  const fetchEmbalajeData = async () => {
    const response = await fetch(`${API_URL}/api/catalagos/embalaje`);
    const data = await response.json();
    setEmbalajeData(data.response);
  };
  const fetchFormaFarmaceutica = async () => {
    const response = await fetch(`${API_URL}/api/catalagos/formaFarmaceutica`);

    const data = await response.json();
    setFormaFarmaceutica(data.response);
  };

  const fetchCondicionesEspeciales = async () => {
    const response = await fetch(
      `${API_URL}/api/catalagos/condicionesEspeciales`
    );
    const data = await response.json();
    setCondicionesEspeciales(data.response);
  };

  const handleTransportChange = async (e, transport = "") => {
    console.log("HandleTransportChange", transport);
    console.log("UnidadTransporte name", e.target.name);

    console.log("transport valcue", transport);
    setAutoTransporte(transport);

    if (e.target.name === "unidadTransporte") {
      setAutoTransporte(transport);
    } else if (e.target.name === "confVehicular") {
      let remolqueRequired = [
        "C2",
        "C3",
        "OTROSG",
        "GPLUTA",
        "GPLUTB",
        "GPLUTD",
        "GPLATA",
        "GPLATB",
        "GPLATC",
        "GPLUTC",
        "GPLATD",
      ];
      let useRemolque;

      if (!remolqueRequired.includes(e.target.value)) {
        useRemolque = true;
      } else {
        useRemolque = false;
      }
      setAutoTransporte([
        {
          ...autoTransporte[0],
          [e.target.name]: e.target.value,
          remolqueRequerido: useRemolque,
        },
      ]);
    } else {
      setAutoTransporte([
        {
          ...autoTransporte[0],
          [e.target.name]: e.target.value,
        },
      ]);
    }

    console.log("autotransporte", autoTransporte);
  };

  const handleRemolqueChange = (e) => {
    console.log("handleRemolqueChange", remolque);
    setRemolque([
      {
        ...remolque[0],
        [e.target.name]: e.target.value,
      },
    ]);
  };

  const handleFiguraChange = (e, figura = "") => {
    console.log("Name handleFiguraChange", e.target.name);
    console.log("Figura value", figuraTransporte);
    if (e.target.name === "driver") {
      setFiguraTransporte(figura);
    } else {
      setFiguraTransporte([
        {
          ...figuraTransporte[0],
          [e.target.name]: e.target.value,
        },
      ]);
    }
  };

  const handleUbicacionesChange = (e) => {
    console.log("Ubicaciones", ubicaciones);

    if (e.target.name.includes("Origen")) {
      let ubicacionesOrigen = ubicaciones;
      console.log("Ubicaciones destino antes de", ubicacionesOrigen);
      ubicacionesOrigen[0] = {
        ...ubicaciones[0],
        [e.target.name]: e.target.value,
      };

      console.log("ubicaciones destino", ubicacionesOrigen);

      setUbicaciones(ubicacionesOrigen);
    } else if (e.target.name.includes("Dest")) {
      console.log("Este valor es de destino");
      let ubicacionesDestino = ubicaciones;
      console.log("Ubicaciones destino antes de", ubicacionesDestino);

      ubicacionesDestino[1] = {
        ...ubicaciones[1],
        [e.target.name]: e.target.value,
      };

      console.log("ubicaciones destino", ubicacionesDestino);

      setUbicaciones(ubicacionesDestino);
    }
  };

  const handleEnvioInt = (e) => {
    if (e.target.name === "viaEntradaSalida") {
      setViaEntSal(e.target.value);
    }

    if (e.target.name === "regimenAduanero") {
      setRegimAduan(e.target.value);
    }

    if (e.target.name === "paisOrigenDestino") {
      setPaisOrigDest(e.target.value);
    }
  };

  const handleLogInversa = (isChecked) => {
    setLogInversa(isChecked ? "Sí" : "No");
  };

  const getPayloadWithTax = (payload) => {
    let taxes = [
      {
        Total: Number(items[0].Subtotal * 0.16).toFixed(2),
        Name: "IVA",
        Base: items[0].Subtotal,
        Rate: 0.16,
        IsRetention: false,
      },
    ];

    payload.Items[0].Taxes = taxes;

    return payload;
  };

  const handleSearchFacturas = async (e) => {
    e.preventDefault();
    setIsLoadingSearch(true);
    let queryString = "?";

    Object.entries(filtersFacturas).forEach(([key, value], index) => {
      if (value !== "") {
        queryString += `${key}=${value}&`;
      }
    });

    if (!queryString.includes("emisor")) {
      queryString += `emisor=${permisos}&`;
    }

    queryString = queryString.substring(0, queryString.length - 1);

    try {
      let response = await fetch(`${API_URL}/api/facturas${queryString}`);
      let data = await response.json();

      data.facturas.map((factura) => {
        let foundFormaPago = formasPagos.find(
          (formaPago) => formaPago.Clave === factura.paymentForm
        );
        factura.paymentForm = foundFormaPago.formaPago;
        factura.paymentMethod =
          factura.paymentMethod === "PUE"
            ? "Pago en una Sola Exhibición"
            : factura.paymentMethod;
      });
      setFacturasData(data.facturas);
      setIsLoadingSearch(false);
    } catch (error) {
      setIsLoadingSearch(false);
      setFacturasData([]);
      notifyWarning("No se encontraron registros de facturas");
    }
  };

  const handleChangeFilter = (e) => {
    setfiltersFacturas({
      ...filtersFacturas,
      [e.target.name]: e.target.value,
    });
  };

  const handlePagoChange = (e, index) => {
    setIsExcedente(false);
    const pagos = [...registroPagos];
    let pagoObj = pagos[index];
    let pagoRegistro;

    if (e.target.name === "comprobante") {
      pagoRegistro = {
        ...pagoObj,
        [e.target.name]: e.target.files,
      };
    } else {
      pagoRegistro = {
        ...pagoObj,
        [e.target.name]: e.target.value,
      };
    }
    pagos[index] = pagoRegistro;

    if (e.target.name === "totalPago") {
      let totalP = 0;
      let totalRes = 0;
      let total = pagos.map((pago) => {
        totalP += Number(pago.totalPago);
        if (!pago.comprobante.destination) {
          totalRes += Number(pago.totalPago);
        }
      });

      let totalFaltante = Number(totalACubrir - totalRes).toFixed(2);

      if (totalFaltante < 0) {
        notifyWarning("El monto a registrar excede el total");
        setIsExcedente(true);
        return false;
      }

      setInfoPago({
        ...infoPago,
        totalFaltante: totalFaltante,
        totalCubierto: totalP.toFixed(2),
      });

      pagos[index].totalFaltante = totalFaltante;
      pagos[index].totalCubierto = totalP.toFixed(2);
    }

    setRegistroPagos([...pagos]);
  };

  const handleDeletePago = (e, index) => {
    let pagoArray = [...registroPagos];

    let totalPag = Number();

    let totalFaltante =
      Number(pagoArray[index].totalPago) + Number(infoPago.totalFaltante);
    let totalCubierto = Number(
      infoPago.totalCubierto - pagoArray[index].totalPago
    );

    setInfoPago({
      ...infoPago,
      totalFaltante: totalFaltante.toFixed(2),
      totalCubierto: totalCubierto.toFixed(2),
    });
    let pagoArraySlice = pagoArray.splice(index, 1);
    setRegistroPagos([...pagoArray]);
  };

  const handleRegistrarPago = async (e) => {
    e.preventDefault();
    setIsLoadingPago(true);

    if (isExcedente) {
      notifyWarning(
        "No se puede hacer el registro del pago ya que excede el monto faltante"
      );
      setIsLoadingPago(false);
      return false;
    }

    if (registroPagos.length > 0) {
      let hasNewPayment = [];

      registroPagos.forEach((pago) => {
        if (pago.comprobante.destination) {
          hasNewPayment.push(false);
        } else {
          hasNewPayment.push(true);
        }
      });

      if (!hasNewPayment.includes(true)) {
        notifyWarning("Agrega un nuevo pago");
        setIsLoadingPago(false);
        return false;
      }
    } else {
      notifyWarning("Agrega un nuevo pago");
      setIsLoadingPago(false);
      return false;
    }

    try {
      let newFormData = new FormData();
      newFormData.append("id", infoPago._id);
      newFormData.append("createdBy", userName);
      newFormData.append("totalCubierto", infoPago.totalCubierto);
      newFormData.append("totalFaltante", infoPago.totalFaltante);
      newFormData.append("pagos", JSON.stringify(registroPagos));
      registroPagos.forEach((pago) => {
        for (let i = 0; i < pago.comprobante.length; i++) {
          if (!pago.comprobante.destination) {
            newFormData.append("comprobantes", pago.comprobante[i]);
          }
        }
      });

      let response = await fetch(`${API_URL}/api/facturas/registrarPago`, {
        method: "PUT",
        body: newFormData,
      });
      let data = await response.json();
      setIsLoadingPago(false);

      if (data.status === "Ok") {
        notifySuccess(data.message);
        handleSearchFacturas(e);
        setIsDialogPagosOpen(false);
      } else {
        notifyError(data.message);
      }
    } catch (error) {
      setIsLoadingPago(false);

      notifyWarning("No se pudo realizar el registro del pago");
    }
  };

  const handleGenNotaPago = async (e) => {
    e.preventDefault();
    try {
      const link = document.createElement("a");
      link.href = `${API_URL}/api/facturas/notaPago/${infoPago.idFactura}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error", error);
      notifyError("Error en el servidor al realizar nota de pago");
    }
  };

  const genenerateCartaPortePayload = (basePayload) => {
    let mercancia = [];
    let transpMatPel = [];

    mercancias.forEach((el) => {
      let objMercancia = {
        BienesTransp: el.bienesTransp,
        Descripcion: el.descripcion,
        Cantidad: Number(el.cantidad).toFixed(2),
        ClaveUnidad: el.claveUnidad.toUpperCase(),
        PesoEnKg: el.pesoMateria,
        MaterialPeligroso: el.matPeligroso,
      };

      if (el.matPeligroso === "Sí") {
        objMercancia.CveMaterialPeligroso = el.cveMatPeligroso;
        objMercancia.Embalaje = el.embalaje;
        transpMatPel.push(true);
      }
      mercancia.push(objMercancia);
    });

    let Ubicaciones = [
      {
        TipoUbicacion: "Origen",
        RFCRemitenteDestinatario: ubicaciones[0].rfcOrigen,
        FechaHoraSalidaLlegada: ubicaciones[0].fechaHoraOrigen,
        Domicilio: {
          Pais: ubicaciones[0].paisOrigen,
          CodigoPostal: ubicaciones[0].cpOrigen,
          Estado: ubicaciones[0].estadoOrigen,
          Municipio: ubicaciones[0].municipioOrigen,
          Colonia: ubicaciones[0].coloniaOrigen,
        },
      },
      {
        TipoUbicacion: "Destino",
        RFCRemitenteDestinatario: ubicaciones[1].rfcDest,
        FechaHoraSalidaLlegada: ubicaciones[1].fechaHoraDest,
        DistanciaRecorrida: ubicaciones[1].distanceDest,
        Domicilio: {
          Pais: ubicaciones[1].paisDest,
          CodigoPostal: ubicaciones[1].cpDest,
          Estado: ubicaciones[1].estadoDest,
          Municipio: ubicaciones[1].municipioDest,
          Colonia: ubicaciones[1].coloniaDest,
        },
      },
    ];
    let cartaPorte = {
      TranspInternac: transpInt === "Sí" ? "Sí" : "No",
      Mercancias: {
        UnidadPeso: "KGM",
        Mercancia: mercancia,
        Autotransporte: {
          PermSCT: autoTransporte[0].permisoSCT,
          NumPermisoSCT: autoTransporte[0].numPermisoSCT,
          IdentificacionVehicular: {
            ConfigVehicular: autoTransporte[0].confVehicular,
            PesoBrutoVehicular: autoTransporte[0].pesoVehiculo,
            PlacaVM: autoTransporte[0].placaVehiculo,
            AnioModeloVM: autoTransporte[0].anioVehiculo,
          },
          Seguros: {
            AseguraRespCivil: autoTransporte[0].nombreSeguro,
            PolizaRespCivil: autoTransporte[0].numPoliza,
            AseguraMedAmbiente: autoTransporte[0].aseguraMedAmbiente,
            PolizaMedAmbiente: autoTransporte[0].polizaMedAmbiente,
          },
          Remolques: remolque,
        },
      },
    };
    if (logInversa === "Sí") {
      cartaPorte.Mercancias.LogisticaInversaRecoleccionDevolucion = logInversa;
    }

    if (!autoTransporte[0].remolqueRequerido) {
      delete cartaPorte.Mercancias.Autotransporte.Remolques;
    }

    if (!autoTransporte[0].medAmbiente || !transpMatPel.includes(true)) {
      delete cartaPorte.Mercancias.Autotransporte.Seguros.AseguraMedAmbiente;
      delete cartaPorte.Mercancias.Autotransporte.Seguros.PolizaMedAmbiente;
    }

    let figTransporte = [
      {
        TipoFigura: "01",
        RFCFigura: figuraTransporte[0].RFCFigura,
        NumLicencia: figuraTransporte[0].numLicencia,
        NombreFigura: figuraTransporte[0].name,
        Domicilio: {
          Calle: figuraTransporte[0].calle,
          NumeroExterior: figuraTransporte[0].numExt,
          NumeroInterior: figuraTransporte[0].numInt,
          Colonia: figuraTransporte[0].colonia,
          Referencia: figuraTransporte[0].referencia,
          Municipio: figuraTransporte[0].municipio,
          Estado: figuraTransporte[0].estado,
          Pais: figuraTransporte[0].pais,
          CodigoPostal: figuraTransporte[0].codigoPostal,
        },
      },
    ];

    cartaPorte.Ubicaciones = Ubicaciones;
    cartaPorte.FiguraTransporte = figTransporte;

    basePayload.Complemento = {
      CartaPorte30: cartaPorte,
    };

    return basePayload;
  };

  const genInternationalPayload = (payload) => {
    payload.Complemento.CartaPorte30.ViaEntradaSalida = viaEntSal;
    payload.Complemento.CartaPorte30.TranspInternac = transpInt;
    payload.Complemento.CartaPorte30.RegimenAduanero = regimAduan;
    payload.Complemento.CartaPorte30.EntradaSalidaMerc = entradaSalida;
    payload.Complemento.CartaPorte30.PaisOrigenDestino = paisOrigDest;
    let mercanciasArr = payload.Complemento.CartaPorte30.Mercancias.Mercancia;

    mercancias.forEach((el, index) => {
      mercanciasArr[index].FraccionArancelaria = el.fraccAranc;

      if (
        el.impoVucem.trim() !== "" &&
        el.sectorCofepris !== "03" &&
        el.sectorCofepris !== ""
      ) {
        mercanciasArr[index].FolioImpoVUCEM = el.impoVucem;
      }

      if (
        el.sectorCofepris === "03" &&
        mercanciasArr[index].hasOwnProperty("FolioImpoVUCEM")
      ) {
        delete mercanciasArr[index].FolioImpoVUCEM;
      }

      if (
        el.permImpo.trim() !== "" &&
        el.sectorCofepris !== "" &&
        el.sectorCofepris !== "04"
      ) {
        mercanciasArr[index].PermisoImportacion = el.permImpo;
      }

      if (el.sectorCofepris.trim() !== "") {
        mercanciasArr[index].SectorCOFEPRIS = el.sectorCofepris;
      }

      console.log("Tipo Materia", el.tipoMateria);

      mercanciasArr[index].TipoMateria = el.tipoMateria;

      if (el.descripcionMateria.trim() !== "") {
        mercanciasArr[index].DescripcionMateria = el.descripcionMateria;
      }

      if (
        el.sectorCofepris === "01" ||
        el.sectorCofepris === "02" ||
        el.sectorCofepris === "03"
      ) {
        if (el.sectorCofepris !== "02") {
          mercanciasArr[index].DenominacionGenericaProd =
            el.denominacionGenProd;
          mercanciasArr[index].DenominacionDistintivaProd =
            el.denominacionDistProd;
          mercanciasArr[index].RegistroSanitarioFolioAutorizacion =
            el.registroSanitario;
        }

        mercanciasArr[index].Fabricante = el.fabricante;
        mercanciasArr[index].FechaCaducidad = el.fechaCaducidad;
        mercanciasArr[index].LoteMedicamento = el.loteMed;
        mercanciasArr[index].FormaFarmaceutica = el.formaFarmaceutica;
        mercanciasArr[index].CondicionesEspTransp = el.condTransporte;
      }

      if (el.sectorCofepris === "02") {
        mercanciasArr[index].NombreIngredienteActivo = el.ingActivo;
        mercanciasArr[index].nomQuimico = el.nomQuimico;
      }

      if (el.sectorCofepris === "04") {
        mercanciasArr[index].razonSocialEmpImp = el.razonSocialEmpImp;
        mercanciasArr[index].nomQuimico = el.nomQuimico;
        mercanciasArr[index].numCAS = el.numCAS;
      }

      if (el.sectorCofepris === "05") {
        mercanciasArr[index].NombreIngredienteActivo = el.ingActivo;
        mercanciasArr[index].NumRegSanPlagCOFEPRIS = el.regSanPlag;
        mercanciasArr[index].DatosFabricante = el.datosFabricante;
        mercanciasArr[index].DatosFormulador = el.datosFormulador;
        mercanciasArr[index].DatosMaquilador = el.datosMaquilador;
        mercanciasArr[index].UsoAutorizado = el.usoAutorizado;
      }
      mercanciasArr[index].DocumentacionAduanera = [
        {
          TipoDocumento: el.tipoDoc,
          NumPedimento: el.numPedimento,
          RFCImpo: el.RFCImportador,
        },
      ];

      if (el.tipoDoc !== "01" && el.tipoDoc !== "") {
        delete mercanciasArr[index].DocumentacionAduanera[0].NumPedimento;
        // delete mercanciasArr[index].DocumentacionAduanera[0].RFCImpo;
        mercanciasArr[index].DocumentacionAduanera[0].IdentDocAduanero =
          el.identDocAduanero;
      }
    });

    payload.Complemento.CartaPorte30.Mercancias.Mercancia = mercanciasArr;

    return payload;
  };

  const handleGenFactura = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let emisorFact = emisor != "" ? emisor : permisos[0];
      let payload = CFDIObj;

      payload.Items = items;
      payload.ExpeditionPlace = 66070;

      payload.Receiver = {
        Name: customerReceiver.razonSocial,
        CfdiUse: cfdiUse,
        Rfc: customerReceiver.RFC,
        FiscalRegime: customerReceiver.regimenFiscal,
        TaxZipCode: customerReceiver.codigoPostal,
      };

      if (CFDIObj.Currency !== "MXN") {
        delete payload.Currency;
      }

      if (items[0].TaxObject === "02") {
        payload = getPayloadWithTax(payload);
      } else {
        delete items[0].Taxes;
      }

      payload =
        cartaPorte === "Sí" ? genenerateCartaPortePayload(payload) : payload;

      payload = transpInt === "Sí" ? genInternationalPayload(payload) : payload;

      console.log("Payload", JSON.stringify(payload));

      const response = await fetch(`${API_URL}/api/cfdi`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          datosDeSolicitud: payload,
          userId,
          idCliente: customerReceiver._id,
          emisor: emisorFact,
          cartaPorte,
        }),
      });

      const data = await response.json();
      setIsLoading(false);

      if (data.Message) {
        if (data.ModelState) {
          const errores = data.ModelState;

          for (const prop in errores) {
            if (Object.hasOwnProperty.call(errores, prop)) {
              const mensajes = errores[prop];

              mensajes.forEach((mensaje) => {
                notifyWarning(mensaje);
              });
            }
          }
        }
        notifyWarning(data.Message || "Error al realizar la solicitud");
      } else if (data.error) {
        notifyError(data.error);
      } else {
        setCFDIObj(facturaObj);
        setItems(itemsObj);
        setCartaPorte("No");
        setUnitPriceAux("");
        setMxnPrice("");
        setCustomerReceiver(customerObj);
        setEmisor("");
        setCustomerValue("");
        notifySuccess("La factura fue generada correctamente");
      }
    } catch (error) {
      notifyError("No se pudo realizar la factura");
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="content-wrapper container__block">
        <PageInfo heading="DATT - Tracking | Cuentas por Cobrar" />
        <section className="">
          <div className="container-fluid">
            <Tabs.Root className="TabsRoot" defaultValue="tab1">
              <Tabs.List className="TabsList" aria-label="Registra un chofer">
                <Tabs.Trigger className="TabsTrigger" value="tab1">
                  Generar Factura
                </Tabs.Trigger>
                <Tabs.Trigger className="TabsTrigger" value="tab2">
                  Ver Facturas
                </Tabs.Trigger>
              </Tabs.List>

              <Tabs.Content
                className="TabsContent"
                value="tab1"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  className="container__width_70 client__container"
                  style={{ flex: 1 }}
                >
                  {/* Register customer form */}

                  <form
                    encType="multipart/form-data"
                    onSubmit={handleGenFactura}
                    id="formFactura"
                  >
                    <div className="data__group">
                      <div
                        className="cartaPorte__group_elements_checkbox"
                        style={{ marginBottom: "2rem" }}
                      >
                        <Checkbox.Root
                          className="CheckboxRoot"
                          checked={cartaPorte === "Sí" ? true : false}
                          onCheckedChange={(isChecked) => {
                            setCartaPorte(isChecked ? "Sí" : "No");
                            if (transportsData.length <= 0) {
                              fetchDataTransport();
                            }
                          }}
                        >
                          <Checkbox.Indicator className="CheckboxIndicator">
                            <CheckIcon />
                          </Checkbox.Indicator>
                        </Checkbox.Root>

                        <label
                          style={{ marginLeft: "1rem" }}
                          className="FormLabel"
                          htmlFor="c1"
                        >
                          Carta Porte 3.0
                        </label>
                      </div>

                      <h4>Datos del Cliente Receptor</h4>

                      <div className="data__group_form">
                        <div className="form__group_form-select">
                          {permisos && permisos.length > 1 ? (
                            <div className="form__group form__group_selectField">
                              <label
                                className="FormLabel"
                                style={{ margin: "1rem 0" }}
                              >
                                Emisor
                              </label>
                              <select
                                className="SelectTrigger"
                                required
                                placeholder="Emisor"
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  setEmisor(e.target.value);
                                  setCustomerValue("");
                                  fetchCustomersData(e.target.value);
                                }}
                                value={emisor}
                              >
                                <option value="">Selecciona un Emisor</option>
                                {permisos.map((permiso, index) => (
                                  <option key={index} value={permiso}>
                                    {permiso}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="form__group form__group_selectField">
                            <label
                              className="FormLabel"
                              style={{ margin: "1rem 0" }}
                            >
                              Cliente
                            </label>
                            <select
                              className="SelectTrigger"
                              required
                              placeholder="Razón Social"
                              value={customerValue}
                              style={{ width: "100%" }}
                              onChange={handleChangeClient}
                            >
                              <option value="">Selecciona un Cliente</option>
                              {customersData.length > 0 && customersData ? (
                                customersData.map((customer) => (
                                  <option
                                    key={customer._id}
                                    value={customer._id}
                                  >
                                    {customer.razonSocial}
                                  </option>
                                ))
                              ) : (
                                <option>No hay clientes disponibles</option>
                              )}
                            </select>
                          </div>
                        </div>

                        {customerValue !== "" ? (
                          <>
                            <div className="form__group_form-select">
                              <div className="form__group form__group_selectField">
                                <label
                                  className="FormLabel"
                                  style={{ margin: 0 }}
                                >
                                  Razón Social
                                </label>
                                <input
                                  className="Input boxSizing__bb"
                                  type="text"
                                  disabled
                                  required
                                  placeholder="Razón Social"
                                  value={customerReceiver?.razonSocial}
                                />
                              </div>
                              <div className="form__group form__group_selectField">
                                <label
                                  className="FormLabel"
                                  style={{ margin: 0 }}
                                >
                                  Código Postal
                                </label>
                                <input
                                  className="Input boxSizing__bb"
                                  type="text"
                                  name="codigoPostal"
                                  disabled
                                  required
                                  placeholder="Código Postal"
                                  value={customerReceiver?.codigoPostal}
                                />
                              </div>
                            </div>

                            <div className="form__group_form-select">
                              <div className="form__group form__group_selectField">
                                <label
                                  className="FormLabel"
                                  style={{ margin: 0 }}
                                >
                                  RFC
                                </label>

                                <input
                                  className="Input boxSizing__bb"
                                  type="text"
                                  name="RFC"
                                  disabled
                                  required
                                  placeholder="RFC"
                                  value={customerReceiver?.RFC}
                                />
                              </div>
                              <div className="form__group form__group_selectField">
                                <label
                                  className="FormLabel"
                                  style={{ margin: 0 }}
                                >
                                  Regimen Fiscal
                                </label>
                                <select
                                  className="SelectTrigger boxSizing__bb"
                                  disabled
                                  required
                                  name="regimenFiscal"
                                  style={{ width: "100%" }}
                                  value={customerReceiver?.regimenFiscal}
                                >
                                  <option value="">
                                    Selecciona un cliente
                                  </option>
                                  {regimenesFiscales &&
                                  regimenesFiscales.length > 0 ? (
                                    regimenesFiscales.map((regimenFiscal) => (
                                      <option
                                        key={regimenFiscal._id}
                                        value={regimenFiscal.Clave}
                                      >
                                        {regimenFiscal.regimenFiscal}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">
                                      No se encontraron regimenes fiscales
                                    </option>
                                  )}
                                </select>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        <h4 style={{ margin: "1rem 0" }}>
                          Datos de la Factura
                        </h4>

                        <div className="form__group_form-select">
                          <div className="form__group form__group_selectField">
                            <label style={{ margin: 0 }} className="FormLabel">
                              Tipo de CFDI
                            </label>
                            <br></br>
                            <select
                              className="SelectTrigger"
                              name="CfdiType"
                              required
                              style={{ width: "100%" }}
                              // value={CFDIObj?.CfdiType}
                              // onChange={handleChangeFacturaObj}
                              onChange={handleChangeFacturaObj}
                              value={CFDIObj.CfdiType}
                            >
                              <option value="">Selecciona una opción</option>
                              <option value="T">T - Traslado</option>
                              <option value="I">I - Ingreso</option>
                            </select>
                          </div>
                          <div className="form__group form__group_selectField">
                            <label style={{ margin: 0 }} className="FormLabel">
                              Uso de CFDI
                            </label>
                            <br></br>
                            <select
                              className="SelectTrigger"
                              name="CfdiType"
                              required
                              style={{ width: "100%" }}
                              // value={CFDIObj?.CfdiType}
                              // onChange={handleChangeFacturaObj}
                              onChange={(e) => setCfdiUse(e.target.value)}
                              value={cfdiUse}
                            >
                              <option value="" disabled>
                                Escoge una opción
                              </option>
                              <option value="G01">
                                Adquisición de mercancías
                              </option>
                              <option value="G02">
                                Devoluciones, descuentos o bonificaciones{" "}
                              </option>
                              <option value="G03">Gastos en general</option>
                              <option value="I01">Construcciones</option>
                              <option value="I02">
                                Mobiliario y equipo de oficina por inversiones{" "}
                              </option>
                              <option value="I03">Equipo de transporte </option>
                              <option value="I04">
                                Equipo de cómputo y accesorios{" "}
                              </option>
                              <option value="I05">
                                Dados, troqueles, moldes, matrices y herramental{" "}
                              </option>
                              <option value="I06">
                                Comunicaciones telefónicas{" "}
                              </option>
                              <option value="I07">
                                Comunicaciones satelitales{" "}
                              </option>
                              <option value="I08">
                                Otra maquinaria y equipo{" "}
                              </option>
                              <option value="D01">
                                Honorarios médicos, dentales y gastos
                                hospitalarios.{" "}
                              </option>
                              <option value="D02">
                                Gastos médicos por incapacidad o discapacidad{" "}
                              </option>
                              <option value="D03">Gastos funerales. </option>
                              <option value="D04">Donativos </option>
                              <option value="D05">
                                Intereses reales efectivamente pagados por
                                créditos hipotecarios (casa habitación).{" "}
                              </option>
                              <option value="D06">
                                Aportaciones voluntarias al SAR.{" "}
                              </option>
                              <option value="D07">
                                Primas por seguros de gastos médicos.{" "}
                              </option>
                              <option value="D08">
                                Gastos de transportación escolar obligatoria.{" "}
                              </option>
                              <option value="D09">
                                Depósitos en cuentas para el ahorro, primas que
                                tengan como base planes de pensiones.{" "}
                              </option>
                              <option value="D10">
                                Pagos por servicios educativos (colegiaturas){" "}
                              </option>
                              <option value="CP01">Pagos</option>
                              <option value="CN01">Nómina</option>
                              <option value="S01">Sin Efectos Fiscales </option>
                            </select>
                          </div>
                        </div>
                        {CFDIObj?.CfdiType === "I" ? (
                          <>
                            <div className="form__group_form-select">
                              <div className="form__group form__group_selectField">
                                <label
                                  style={{ margin: 0 }}
                                  className="FormLabel"
                                >
                                  Forma de Pago
                                </label>
                                <br></br>
                                <select
                                  className="SelectTrigger"
                                  name="PaymentForm"
                                  required
                                  style={{ width: "100%" }}
                                  // value={CFDIObj?.CfdiType}
                                  // onChange={handleChangeFacturaObj}
                                  onChange={handleChangeFacturaObj}
                                  value={CFDIObj.PaymentForm}
                                >
                                  <option value="" disabled>
                                    Escoge una opción
                                  </option>

                                  {formasPagos && formasPagos.length > 0 ? (
                                    formasPagos.map((formaPago) => (
                                      <option
                                        key={formaPago._id}
                                        value={formaPago.Clave}
                                      >
                                        {formaPago.formaPago}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">
                                      No se encontraron formas de pago
                                    </option>
                                  )}
                                  {/* <option value="01">01 - Efectivo</option>
                                    <option value="02">02 - Cheque nominativo</option>
                                    <option value="03">
                                      03 - Transferencia electrónica de fondos
                                    </option>
                                    <option value="04">
                                      04 - Tarjeta de Crédito
                                    </option>
                                    <option value="05">
                                      05 - Monedero Electrónico
                                    </option>
                                    <option value="06">
                                      06 - Dinero Electrónico
                                    </option>
                                    <option value="08">08 - Vales de Despensa</option>
                                    <option value="12">12 - Dación de Pago</option>
                                    <option value="13">
                                      13 - Pago por subrogación
                                    </option>
                                    <option value="14">
                                      14 - Pago por Consignación
                                    </option>
                                    <option value="15">15 - Condonación</option>
                                    <option value="17">17 - Compensación</option>
                                    <option value="23">23 - Renovación</option>
                                    <option value="24">24 - Confusión</option>
                                    <option value="25">25 - Remisión de Deuda</option>
                                    <option value="26">
                                      26 - Prescripción o Caducidad
                                    </option>
                                    <option value="27">
                                      27 - A Satisfacción del Acreedor
                                    </option>
                                    <option value="28">28 - Tarjeta de Débito</option>
                                    <option value="29">
                                      29 - Tarjeta de Servicios
                                    </option>
                                    <option value="30">
                                      30 - Aplicación de Anticipos
                                    </option>
                                    <option value="31">31 - Intermediarios</option>
                                    <option value="99">99 - Por definir</option> */}
                                </select>
                              </div>
                              <div className="form__group form__group_selectField">
                                <label
                                  style={{ margin: 0 }}
                                  className="FormLabel"
                                >
                                  Metodo de pago
                                </label>
                                <br></br>
                                <select
                                  className="SelectTrigger"
                                  name="PaymentMethod"
                                  required
                                  style={{ width: "100%" }}
                                  // value={CFDIObj?.CfdiType}
                                  // onChange={handleChangeFacturaObj}
                                  onChange={handleChangeFacturaObj}
                                  value={CFDIObj.PaymentMethod}
                                >
                                  <option value="" disabled>
                                    Escoge una opción
                                  </option>
                                  <option value="PUE">
                                    PUE - Pago en una Sola Exhibición
                                  </option>
                                  {CFDIObj.PaymentForm === "99" && (
                                    <option value="PPD">
                                      PPD - Pago en Parcialidades o Diferido
                                    </option>
                                  )}
                                </select>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        <div className="form__group_form-select">
                          <div className="form__group form__group_selectField">
                            <label style={{ margin: 0 }} className="FormLabel">
                              Exportación
                            </label>
                            <br></br>
                            <select
                              className="SelectTrigger"
                              name="Exportation"
                              required
                              style={{ width: "100%" }}
                              // value={CFDIObj?.CfdiType}
                              // onChange={handleChangeFacturaObj}
                              onChange={handleChangeFacturaObj}
                              value={CFDIObj.Exportation}
                            >
                              <option value="" disabled>
                                Escoge una opción
                              </option>
                              <option value="01">No Aplica</option>
                              <option value="02">
                                Definitiva con clave A1
                              </option>
                              <option value="03">Temporal</option>
                              <option value="04">
                                Definitiva con Clave Distinta a A1 o cuando no
                                existe enajenación en términos del CFF
                              </option>
                            </select>
                          </div>

                          <div className="form__group form__group_selectField">
                            <label style={{ margin: 0 }} className="FormLabel">
                              Tipo de Cambio
                            </label>
                            <br></br>
                            <select
                              className="SelectTrigger"
                              name="Currency"
                              required
                              style={{ width: "100%" }}
                              // value={CFDIObj?.CfdiType}
                              // onChange={handleChangeFacturaObj}
                              onChange={handleChangeFacturaObj}
                              value={CFDIObj.Currency}
                            >
                              <option value="" disabled>
                                Escoge una opción
                              </option>
                              <option value="MXN">Pesos mexicanos</option>
                              <option value="USN">Dolar</option>
                            </select>
                          </div>
                        </div>

                        {CFDIObj.Currency === "USN" ? (
                          <div className="form__group_form-select">
                            <div className="form__group form__group_selectField">
                              <label
                                style={{ margin: 0 }}
                                className="FormLabel"
                              >
                                Valor del Dólar
                              </label>
                              <br></br>
                              <input
                                className="Input boxSizing__bb"
                                type="text"
                                disabled
                                value={dollarValue}
                              ></input>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <hr></hr>

                        <h4 style={{ margin: "1rem 0" }}>Datos del Servicio</h4>

                        <div className="form__group_form-select">
                          <div className="form__group form__group_selectField">
                            <label className="FormLabel" style={{ margin: 0 }}>
                              Descripción
                            </label>
                            <input
                              className="Input boxSizing__bb"
                              name="Description"
                              type="text"
                              required
                              placeholder="Ingresa la descripción del servicio"
                              onChange={handleItemsChange}
                              value={items[0].Description}
                            />
                          </div>

                          <div className="form__group form__group_selectField">
                            <label className="FormLabel" style={{ margin: 0 }}>
                              Código de Producto
                            </label>
                            <select
                              className="SelectTrigger"
                              // value={codigoViaje}
                              name="ProductCode"
                              value={items[0].ProductCode}
                              onChange={handleItemsChange}
                              required
                              style={{ width: "100%" }}
                            >
                              <option value="" disabled>
                                Escoge una opción
                              </option>
                              <option value="81141601">
                                81141601 - Logística
                              </option>
                              <option value="78101500">
                                78101500 - Transporte de carga aérea
                              </option>
                              <option value="78101501">
                                78101501 - Transporte nacional aéreo de carga
                              </option>
                              <option value="78101502">
                                78101502 - Transporte internacional aéreo de
                                carga
                              </option>
                              <option value="78101503">
                                78101503 - Transporte aéreo blindado
                              </option>
                              <option value="78101600">
                                78101600 - Transporte de carga por ferrocarril
                              </option>
                              <option value="78101601">
                                78101601 - Servicios de transporte en furgones,
                                Servicios de transporte en vagones
                              </option>
                              <option value="78101602">
                                78101602 - Servicios de transporte ferroviario
                                de carga a granel, Servicios de transporte
                                ferroviario de mercancía a granel
                              </option>
                              <option value="78101603">
                                78101603 - Transporte de ganado por ferrocarril,
                                Transporte ferroviario de ganado
                              </option>
                              <option value="78101604">
                                78101604 - Servicios de transporte de vehículos
                                por ferrocarril, Vehículos de servicios de
                                transporte
                              </option>
                              <option value="78101605">
                                78101605 - Servicio de alquiler de carrotanques
                                de ferrocarril, Servicio de renta de
                                carrotanques de ferrocarril
                              </option>
                              <option value="78101606">
                                78101606 - Servicio de alquiler de furgones de
                                ferrocarril o carros de carga, Servicio de renta
                                de vagones de ferrocarril o carros de carga
                              </option>
                              <option value="78101702">
                                78101702 - Servicios de transporte internacional
                                por buque (Servicios de transporte internacional
                                por embarcación)
                              </option>
                              <option value="78101701">
                                78101701 - Servicios de transporte nacional por
                                buque, Servicios de transporte nacional por
                                embarcación
                              </option>
                              <option value="78101704">
                                78101704 - Servicios de transporte internacional
                                por barcazas
                              </option>
                              <option value="78101705">
                                78101705 - Transporte marítimo blindado
                              </option>
                              <option value="">
                                78101706 - Servicio de buques para suministros a
                                plataformas de gas y petróleo en alta mar,
                                Servicio de embarcaciones para suministros a
                                plataformas de gas y petróleo mar adentro
                              </option>
                              <option value="78101800">
                                78101800 - Transporte de carga por carretera
                              </option>
                              <option value="78101801">
                                78101801 - Servicios de transporte de carga
                                local, Servicios de transporte de carga por
                                carretera (en camión) en área local
                              </option>
                              <option value="78101802">
                                78101802 - Servicios de transporte de carga
                                foráneo, Servicios transporte de carga por
                                carretera (en camión) a nivel regional y
                                nacional
                              </option>
                              <option value="78101803">
                                78101803 - Servicios de transporte de vehículos,
                                Servicios de transporte de vehículos por
                                carretera
                              </option>
                              <option value="78101804">
                                78101804 - Servicios de fletes y mudanzas,
                                Servicios de reubicación
                              </option>
                              <option value="78101806">
                                78101806 - Servicios transporte de carga por
                                carretera a nivel internacional
                              </option>
                              <option value="78101807">
                                78101807 - Servicios de transporte de carga de
                                petróleo o químicos por carretera
                              </option>
                              <option value="78101900">
                                78101900 - Transporte de carga intermodal
                              </option>
                              <option value="78101901">
                                78101901 - Transporte aéreo a marítimo,
                                Transporte con salida aérea y destino marítimo
                              </option>
                              <option value="78101902">
                                78101902 - Transporte con salida marítima y
                                destino ferroviario, Transporte marítimo a
                                ferroviario
                              </option>
                              <option value="78101903">
                                78101903 - Transporte con salida marítima y
                                destino a carretera (por camión), Transporte
                                marino a carretera (por camión)
                              </option>
                              <option value="78101904">
                                78101904 - Transporte aéreo a carretera (por
                                camión), Transporte con salida aérea y destino a
                                carretera (por camión)
                              </option>
                              <option value="78101905">
                                78101905 - Transporte por vagones de ferrocarril
                              </option>
                              <option value="78102200">
                                78102200 - Servicios postales de paqueteo y
                                courrier
                              </option>
                              <option value="78102201">
                                78102201 - Servicios de entrega postal nacional
                              </option>
                              <option value="78102203">
                                78102203 - Servicios de envío, recogida o
                                entrega de correo
                              </option>
                              <option value="78102204">
                                78102204 - Servicios de entrega a nivel mundial
                                de cartas o paquetes pequeños, Servicios de
                                mensajería y paquetería a nivel mundial
                              </option>
                              <option value="78102205">
                                78102205 - Servicios de entrega local de cartas
                                o paquetes pequeños, Servicios de mensajería y
                                paquetería en territorio nacional
                              </option>
                              <option value="78121603">
                                78121603 - Tarifa de los fletes (Servicio de
                                fletes de carga)
                              </option>
                              <option value="78141500">
                                78141500 - Servicios de organización de
                                transportes
                              </option>
                              <option value="78141501">
                                78141501 - Servicios de agentes de carga,
                                Servicios de expedidores de fletes
                              </option>
                              <option value="84121806">
                                84121806 - Servicios de custodia de valores
                              </option>
                              <option value="92121800">
                                92121800 - Servicios de Carros blindados y
                                transporte de valores
                              </option>
                              <option value="92121801">
                                92121801 - Servicio de carros blindados
                              </option>
                              <option value="92121802">
                                92121802 - Servicio de transporte de dinero
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="form__group_form-select">
                          <div className="form__group form__group_selectField">
                            <label
                              className="FormLabel"
                              style={{ margin: 0 }}
                              htmlFor="name"
                            >
                              Código de Unidad *
                            </label>
                            <select
                              disabled
                              className="SelectTrigger"
                              style={{ width: "100%" }}
                              // onChange={(e) =>
                              //   setCodigoServicioViaje(e.target.value)
                              // }
                              // value={codigoServicioViaje}
                              required
                            >
                              <option value="" disabled>
                                Escoge una opción
                              </option>
                              <option>E48</option>
                            </select>
                          </div>

                          <div className="form__group form__group_selectField">
                            <label className="FormLabel" style={{ margin: 0 }}>
                              Unidad
                            </label>
                            <select
                              className="SelectTrigger"
                              disabled
                              // onChange={(e) =>
                              //   setClaveUnidadViaje(e.target.value)
                              // }
                              // value={claveUnidadViaje}
                              style={{ width: "100%" }}
                              required
                            >
                              <option value="" disabled>
                                Escoge una opción
                              </option>
                              <option value="Unidad de Servicio">
                                Unidad de Servicio
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="form__group_form-select">
                          <div className="form__group form__group_selectField">
                            <label className="FormLabel" style={{ margin: 0 }}>
                              Precio Unitario
                            </label>
                            <input
                              className="Input boxSizing__bb"
                              type="number"
                              name="UnitPrice"
                              required
                              placeholder="Precio Unitario"
                              onChange={handleItemsChange}
                              value={items[0].UnitPrice}
                            />
                          </div>

                          <div className="form__group form__group_selectField">
                            <label className="FormLabel" style={{ margin: 0 }}>
                              Cantidad
                            </label>
                            <input
                              className="Input boxSizing__bb"
                              type="number"
                              min={0}
                              name="Quantity"
                              required
                              placeholder="Cantidad"
                              value={items[0].Quantity}
                              onChange={handleItemsChange}
                            />
                          </div>
                        </div>

                        <div className="form__group_form-select">
                          <div className="form__group form__group_selectField">
                            <label className="FormLabel" style={{ margin: 0 }}>
                              Objeto de Impuesto
                            </label>
                            <select
                              className="SelectTrigger"
                              name="TaxObject"
                              style={{ width: "100%" }}
                              value={items[0].TaxObject}
                              onChange={handleItemsChange}
                              required
                            >
                              <option value="" disabled>
                                Escoge una opción
                              </option>
                              <option value="01">
                                01 - No Objeto de Impuesto
                              </option>
                              <option value="02">
                                {" "}
                                {/*Se debe agregar nodo de Impuestos */}
                                02 - (Sí objeto de impuesto), se deben desglosar
                                los Impuestos a nivel de Concepto.
                              </option>
                              <option value="03">
                                03 - (Sí objeto del impuesto y no obligado al
                                desglose) no se desglosan impuestos a nivel
                                Concepto.
                              </option>
                              <option value="04">
                                04 - (Sí Objeto de impuesto y no causa impuesto)
                              </option>
                            </select>
                          </div>

                          <div className="form__group form__group_selectField">
                            <label className="FormLabel" style={{ margin: 0 }}>
                              Subtotal
                            </label>
                            <input
                              className="Input boxSizing__bb"
                              type="text"
                              name="Subtotal"
                              required
                              placeholder="Subtotal"
                              value={items[0].Subtotal}
                            />
                          </div>
                        </div>

                        <div className="form__group_form-select">
                          <div className="form__group form__group_selectField">
                            <label className="FormLabel" style={{ margin: 0 }}>
                              Total
                            </label>
                            <input
                              className="Input boxSizing__bb"
                              type="text"
                              name="Total"
                              required
                              placeholder="Total"
                              value={items[0].Total}
                            />
                          </div>
                        </div>
                        {cartaPorte === "Sí" ? (
                          <CartaPorte
                            mercancias={mercancias}
                            handleInputChange={handleInputChange}
                            handleTranspInt={handleTranspInt}
                            handleEntradaSalida={handleEntradaSalida}
                            handleDeleteMercancia={handleDeleteMercancia}
                            handleMaterialPeligroso={handleMaterialPeligroso}
                            handleAddMerch={handleAddMerch}
                            handleTransportChange={handleTransportChange}
                            handleRemolqueChange={handleRemolqueChange}
                            handleFiguraChange={handleFiguraChange}
                            handleUbiChange={handleUbicacionesChange}
                            handleEnvioInt={handleEnvioInt}
                            handleLogInversa={handleLogInversa}
                            transportsData={transportsData}
                            autoTransporte={autoTransporte}
                            remolque={remolque}
                            figuraTransporte={figuraTransporte}
                            isTransInt={transpInt}
                            logInversa={logInversa}
                            entradaSalida={entradaSalida}
                            formaFarmaceutica={formaFarmaceutica}
                            condicionesEspeciales={condicionesEspeciales}
                            embalajeData={embalajeData}
                          />
                        ) : (
                          ""
                        )}

                        <div className="btn__container">
                          {/*Register driver btn in BD*/}
                          <Form.Submit asChild>
                            <button
                              type="submit"
                              disabled={isLoading}
                              className="btn__login"
                              style={{
                                marginTop: 10,
                                width: "230px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              // onClick={registerTransport}
                            >
                              {isLoading ? (
                                <SyncLoader
                                  color="white"
                                  loading={isLoading}
                                  size={20}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                  speedMultiplier={0.7}
                                />
                              ) : (
                                "Generar Factura"
                              )}
                            </button>
                          </Form.Submit>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Tabs.Content>

              <Tabs.Content className="TabsContent" value="tab2">
                <h3>Facturas Registradas</h3>
                <section className="">
                  <div className="cartaPorte__group">
                    <div className="cartaPorte__group_elements">
                      <label className="FormLabel">Id de Factura</label>
                      <input
                        className="InputForm"
                        name="idFactura"
                        value={filtersFacturas.idFactura}
                        onChange={handleChangeFilter}
                      ></input>
                    </div>
                    {/* <div className="cartaPorte__group_elements">
                      <label className='FormLabel'>Cliente</label>
                      <select
                        className='SelectTrigger'
                        name="clientName"
                        value={filtersFacturas.Cliente}
                        onChange={handleChangeFilter}
                      >
                        <option value="">Selecciona un Cliente</option>
                        {
                          customersData && customersData.length > 0 ?
                            customersData.map((customer) => (
                              <option key={customer._id} value={customer.razonSocial}>{customer.razonSocial}</option>
                            )) : ""
                        }
                      </select>
                    </div> */}

                    {permisos && permisos.length > 1 ? (
                      <div className="cartaPorte__group_elements">
                        <label className="FormLabel">Emisor</label>
                        <select
                          className="SelectTrigger"
                          required
                          name="emisor"
                          placeholder="Emisor"
                          style={{ width: "100%" }}
                          onChange={handleChangeFilter}
                          value={filtersFacturas.emisor}
                        >
                          <option value="">Selecciona un Emisor</option>
                          {permisos.map((permiso, index) => (
                            <option key={index} value={permiso}>
                              {permiso}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="cartaPorte__group_elements">
                      <label className="FormLabel">Forma de pago</label>
                      <select
                        className="SelectTrigger"
                        name="paymentForm"
                        onChange={handleChangeFilter}
                        value={filtersFacturas.formaPago}
                      >
                        <option value="">Selecciona una Forma de Pago</option>
                        {formasPagos && formasPagos.length > 0
                          ? formasPagos.map((formaPago) => (
                              <option
                                key={formaPago._id}
                                value={formaPago.Clave}
                              >
                                {formaPago.formaPago}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>

                    <div className="cartaPorte__group_elements">
                      <label className="FormLabel">Estatus</label>
                      <select
                        className="SelectTrigger"
                        name="status"
                        onChange={handleChangeFilter}
                        value={filtersFacturas.status}
                      >
                        <option value="">Selecciona un Estatus</option>
                        <option value="Finalizado">Finalizado</option>
                        <option value="Pendiente Pago">Pendiente Pago</option>
                      </select>
                    </div>
                    <div className="cartaPorte__group_elements">
                      <label className="FormLabel">Tipo de Cambio</label>
                      <select
                        className="SelectTrigger"
                        name="currency"
                        onChange={handleChangeFilter}
                        value={filtersFacturas.currency}
                      >
                        <option value="">Selecciona un Tipo de Cambio</option>
                        <option value="MXN">Pesos Mexicanos</option>
                        <option value="USN">Dolar</option>
                      </select>
                    </div>
                    <div className="cartaPorte__group_elements">
                      <label className="FormLabel">Complemento</label>
                      <select
                        className="SelectTrigger"
                        name="complemento"
                        onChange={handleChangeFilter}
                        value={filtersFacturas.complemento}
                      >
                        <option value="">Selecciona un Complemento</option>
                        <option value="Carta Porte 3.0">Carta Porte 3.0</option>
                        <option value="Sin Complemento">Sin complemento</option>
                      </select>
                    </div>
                    <div className="cartaPorte__group_elements">
                      <label className="FormLabel">Fecha de Emisión</label>
                      <input
                        type="date"
                        name="createdAt"
                        value={filtersFacturas.createdAt}
                        onChange={handleChangeFilter}
                      />
                    </div>

                    <div
                      className="cartaPorte__group_elements"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <div className="btn__container_search">
                        <button
                          type="submit"
                          disabled={isLoadingSearch}
                          className="btn__login"
                          style={{
                            marginTop: 10,
                            width: "240px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={handleSearchFacturas}
                        >
                          {
                            isLoadingSearch ? (
                              <SyncLoader
                                color="white"
                                loading={isLoadingSearch}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                speedMultiplier={0.7}
                              />
                            ) : (
                              "Buscar Facturas"
                            )
                            // <FaSearch />
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
                <header
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Button
                    type="button"
                    icon="pi pi-file-excel"
                    severity="success"
                    rounded
                    onClick={exportExcel}
                    data-pr-tooltip="XLS"
                    style={{ fontSize: "1.5rem", color: "green" }}
                  />
                </header>

                <div className="card">
                  <DataTable
                    value={facturasData}
                    paginator
                    header={renderHeader}
                    ref={dt}
                    filters={filters}
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: "50rem" }}
                    editMode="row"
                    dataKey="_id"
                    // onRowEditComplete={onRowEditComplete}
                  >
                    <Column field="_id" header="ID" hidden />
                    <Column field="emisor" header="Emisor" sortable />
                    <Column field="clientName" header="Cliente" sortable />
                    <Column field="clientRFC" header="RFC" sortable />
                    <Column field="description" header="Descripción" sortable />
                    <Column
                      field="paymentMethod"
                      header="Método de Pago"
                      sortable
                    />
                    <Column
                      field="paymentForm"
                      header="Forma de Pago"
                      sortable
                    />
                    <Column field="complemento" header="Complemento" sortable />
                    <Column field="quantity" header="Cantidad" sortable />

                    <Column field="currency" header="Tipo de Cambio" sortable />
                    <Column
                      field="unitPrice"
                      header="Precio Unitario"
                      sortable
                    />

                    <Column field="subtotal" header="Subtotal" sortable />
                    <Column
                      field="totalCubierto"
                      header="Total Cubierto"
                      sortable
                    />

                    <Column
                      field="totalFaltante"
                      header="Total Faltante"
                      sortable
                    />

                    <Column field="total" header="Total" sortable />

                    <Column
                      field="registrarPago"
                      header="Registrar Pago"
                      sortable
                      body={registrarPagoBody}
                    />

                    <Column
                      field="status"
                      header="Estatus"
                      sortable
                      style={{ width: "150px" }}
                      body={(rowData) => (
                        <div
                          style={{
                            background: getStatusColor(rowData.status),
                            padding: "6px",
                            borderRadius: "5px",
                            color: "white",
                          }}
                        >
                          {rowData.status}
                        </div>
                      )}
                    />
                    <Column
                      field="facturacion"
                      header="Factura"
                      body={(rowData) => {
                        if (rowData.idFactura) {
                          return (
                            <FaFileInvoiceDollar
                              className="documentosInicio"
                              onClick={() => {
                                if (rowData.idFactura) {
                                  setIsDialogSendFacturaOpen(true);
                                  setIdFactura(rowData.idFactura);
                                  setIdViaje(rowData._id);
                                  setEmisorFactura(rowData.emisor);
                                }
                              }}
                            />
                          );
                        }
                      }}
                    />
                    {/* 
                    <Column
                      field='createdBy'
                      header="Generada por"
                      sortable
                    /> */}
                    <Column field="createdAt" header="Generada el" sortable />
                  </DataTable>
                  <Dialog.Root
                    open={isDialogSendFacturaOpen}
                    onOpenChange={() => setIsDialogSendFacturaOpen(false)}
                  >
                    <Dialog.Portal>
                      <Dialog.Overlay className="DialogOverlay" />
                      <Dialog.Content
                        className="DialogContentFactura"
                        style={{ backgroundColor: "white", width: "40rem" }}
                      >
                        <Dialog.Title
                          className="DialogTitle"
                          style={{ textAlign: "center" }}
                        >
                          CFDI 4.0
                        </Dialog.Title>
                        <div className="facturaId">
                          <p style={{ margin: "1rem 0" }}>
                            ID de factura asignado:<br></br>{" "}
                            <strong>{idFactura}</strong>
                          </p>
                        </div>

                        <div
                          style={{
                            margin: "1rem 0 2rem 0",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            aria-label="Close"
                            className="btn__login"
                            style={{
                              marginRight: "10px",
                              width: "150px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={descargarFacturaPDF}
                          >
                            {isLoadingFactura ? (
                              <SyncLoader
                                color="white"
                                loading={isLoadingFactura}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                speedMultiplier={0.7}
                              />
                            ) : (
                              "Descargar Factura"
                            )}
                          </Button>
                          <Button
                            aria-label="Close"
                            className="btn__login"
                            onClick={descargarFacturaXML}
                            style={{
                              width: "150px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {isLoadingXML ? (
                              <SyncLoader
                                color="white"
                                loading={isLoadingXML}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                speedMultiplier={0.7}
                              />
                            ) : (
                              "Descargar XML"
                            )}
                          </Button>
                        </div>
                        <hr></hr>
                        <h2 className="DialogTitle">
                          Enviar factura por correo electrónico
                        </h2>
                        <form
                          id="formRoute"
                          encType="multipart/form-data"
                          onSubmit={(e) => handleSendFactura(e)}
                          style={{ marginTop: "1rem" }}
                        >
                          <label className="FormLabel" style={{ margin: "0" }}>
                            Correo electrónico
                          </label>
                          <input
                            className="Input"
                            type="mail"
                            required
                            name="numero"
                            placeholder="E-mail"
                            onChange={(e) => setEmailCliente(e.target.value)}
                            value={emailCliente}
                          />

                          <div
                            style={{
                              margin: "20px 0",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {/* <input
                              className="btn__login"
                              type="submit"
                              value="Enviar Factura"
                              style={{ width: "140px" }}
                            /> */}

                            <button
                              type="submit"
                              onClick={handleSendFactura}
                              className="btn__login"
                              style={{
                                marginTop: 10,
                                width: "140px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxSizing: "border-box",
                              }}
                              // onClick={registerTransport}
                            >
                              {isLoadingSend ? (
                                <SyncLoader
                                  color="white"
                                  loading={isLoadingSend}
                                  size={20}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                  speedMultiplier={0.7}
                                />
                              ) : (
                                "Enviar Factura"
                              )}
                            </button>
                          </div>
                        </form>

                        <hr></hr>
                        <div className="cfdiCancelacion">
                          <div className="cfdiCancelacion__group">
                            <h2 className="DialogTitle">Cancelación de CFDI</h2>
                            <label
                              className="FormLabel"
                              style={{ marginTop: "1rem" }}
                            >
                              Motivo de cancelación
                            </label>
                            <select
                              className="SelectTrigger"
                              style={{ width: "100%" }}
                              value={motivoCancelacion}
                              onChange={(e) =>
                                setMotivoCancelacion(e.target.value)
                              }
                            >
                              <option value="" disabled>
                                Elige una opción
                              </option>
                              <option value="01">
                                Comprobante emitido con errores con relación
                              </option>
                              <option value="02">
                                {" "}
                                Comprobante emitido con errores sin relación
                              </option>
                              <option value="04">
                                No se llevó a cabo la operación
                              </option>
                              <option value="04">
                                Operación nominativa relacionada con una factura
                                global
                              </option>
                            </select>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              margin: "1rem 0",
                            }}
                          >
                            <button
                              type="submit"
                              onClick={CancelarCFDI}
                              className="EliminarButton"
                              style={{
                                marginTop: 10,
                                width: "140px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxSizing: "border-box",
                              }}
                              // onClick={registerTransport}
                            >
                              {isLoadingCancelar ? (
                                <SyncLoader
                                  color="white"
                                  loading={isLoadingCancelar}
                                  size={20}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                  speedMultiplier={0.7}
                                />
                              ) : (
                                "Cancelar CFDI"
                              )}
                            </button>
                            {/* <Button
                              aria-label="Close"
                              className="EliminarButton"
                              style={{ marginLeft: "20px" }}
                              onClick={CancelarCFDI}
                            >Cancelar CFDI</Button> */}
                          </div>
                        </div>
                        {/*                         
                        
                        {/* <hr></hr>  */}
                        {/* <Dialog.Close asChild>
                          <Button
                            aria-label="Close"
                            className="EliminarButton"
                          >
                            Cerrar
                          </Button>
                        </Dialog.Close> */}
                      </Dialog.Content>
                    </Dialog.Portal>
                  </Dialog.Root>
                  <Dialog.Root
                    open={isDialogPagosOpen}
                    onOpenChange={() => setIsDialogPagosOpen(false)}
                  >
                    <Dialog.Portal>
                      <Dialog.Overlay className="DialogOverlay" />
                      <Dialog.Content
                        className="DialogContentCP dialogEdit"
                        style={{ backgroundColor: "white" }}
                      >
                        <Dialog.Title
                          className="DialogTitle"
                          style={{ textAlign: "center" }}
                        >
                          Registro de Pagos
                        </Dialog.Title>
                        <form onSubmit={handleRegistrarPago}>
                          <hr></hr>
                          <h2
                            className="DialogTitle"
                            style={{ textAlign: "left" }}
                          >
                            Datos del cliente
                          </h2>

                          <div className="dialogEditGroup">
                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="name">
                                Razón Social
                              </label>
                              <input
                                className="InputForm"
                                type="text"
                                name="clientName"
                                required
                                disabled
                                value={infoPago.clientName}
                              ></input>
                            </div>

                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="name">
                                RFC
                              </label>
                              <input
                                className="InputForm"
                                type="text"
                                name="clientRFC"
                                required
                                disabled
                                value={infoPago.clientRFC}
                              ></input>
                            </div>

                            {/* Fin de dialogEditGroup */}
                          </div>
                          <hr></hr>
                          <h2
                            className="DialogTitle"
                            style={{ textAlign: "left" }}
                          >
                            Datos de la cuenta
                          </h2>
                          <div className="dialogEditGroup">
                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="name">
                                Descripcion del servicio
                              </label>
                              <input
                                className="InputForm"
                                type="text"
                                name="description"
                                required
                                disabled
                                value={infoPago.description}
                              ></input>
                            </div>

                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="name">
                                Tipo de Cambio
                              </label>
                              <input
                                className="InputForm"
                                type="text"
                                name="currency"
                                required
                                disabled
                                value={infoPago.currency}
                              ></input>
                            </div>

                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="name">
                                Forma de Pago
                              </label>
                              <input
                                className="InputForm"
                                type="text"
                                name="paymentForm"
                                required
                                disabled
                                value={infoPago.paymentForm}
                              ></input>
                            </div>

                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="name">
                                Cantidad
                              </label>
                              <input
                                className="InputForm"
                                type="text"
                                name="quantity"
                                required
                                disabled
                                value={infoPago.quantity}
                              ></input>
                            </div>
                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="name">
                                Subtotal
                              </label>
                              <input
                                className="InputForm"
                                type="text"
                                name="subtotal"
                                required
                                disabled
                                value={infoPago.subtotal}
                              ></input>
                            </div>

                            <div className="dialogEditGroup_elements">
                              <label className="FormLabel" htmlFor="name">
                                Total
                              </label>
                              <input
                                className="InputForm"
                                type="text"
                                name="total"
                                required
                                disabled
                                value={infoPago.total}
                              ></input>
                            </div>

                            {/* Fin de dialogEditGroup */}
                          </div>
                          <hr></hr>

                          <div
                            style={{
                              margin: "2rem 0 1rem 0",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <h2
                              className="DialogTitle"
                              style={{ textAlign: "left" }}
                            >
                              Datos del pago
                            </h2>

                            {registroPagos.length > 0 &&
                            registroPagos[0].comprobante.destination ? (
                              <button
                                className="btn__login"
                                // href={`${API_URL}/api/facturas/notaPago/AHl9DGZxEflaB3nDFAoB5w2`}
                                download
                                onClick={handleGenNotaPago}
                              >
                                Generar nota de pago
                              </button>
                            ) : (
                              ""
                            )}
                          </div>

                          {registroPagos && registroPagos.length > 0
                            ? registroPagos.map((pago, index) => (
                                <>
                                  {index > 0 ? <hr></hr> : ""}
                                  <h5
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    Pago {index + 1}{" "}
                                    {index > 0 &&
                                    !pago.comprobante.destination ? (
                                      <FaMinus
                                        onClick={(e) =>
                                          handleDeletePago(e, index)
                                        }
                                        className="deleteMercancia"
                                        style={{ width: "15px" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </h5>
                                  <div className="dialogEditGroup">
                                    <div className="dialogEditGroup_elements">
                                      <label
                                        className="FormLabel"
                                        htmlFor="name"
                                      >
                                        Monto a pagar
                                      </label>
                                      <input
                                        className="InputForm"
                                        placeholder="Monto total a pagar"
                                        type="number"
                                        name="totalPago"
                                        onChange={(e) =>
                                          handlePagoChange(e, index)
                                        }
                                        required
                                        disabled={
                                          pago.comprobante.destination
                                            ? true
                                            : false
                                        }
                                        value={pago.totalPago}
                                      ></input>
                                    </div>
                                    <div className="dialogEditGroup_elements">
                                      <label
                                        className="FormLabel"
                                        htmlFor="name"
                                      >
                                        {pago.comprobante.destination
                                          ? "Comprobante"
                                          : "Subir comprobante"}
                                      </label>
                                      {pago.comprobante.destination ? (
                                        <>
                                          <i
                                            onClick={() => {
                                              window.open(
                                                `${API_URL}${pago.comprobante.path}`
                                              );
                                            }}
                                            className="pi pi-file-pdf btn__pi_pago"
                                            style={{ color: "#ad0b00" }}

                                            // disabled={!rowData.documentoFinalizado}
                                          ></i>
                                        </>
                                      ) : (
                                        <input
                                          className="InputForm"
                                          placeholder="Subir comprobante de pago"
                                          type="file"
                                          multiple
                                          name="comprobante"
                                          onChange={(e) =>
                                            handlePagoChange(e, index)
                                          }
                                          required
                                        ></input>
                                      )}
                                    </div>
                                    {pago.comprobante.destination ? (
                                      <>
                                        <div className="dialogEditGroup_elements">
                                          <label
                                            className="FormLabel"
                                            htmlFor="name"
                                          >
                                            Registrado por
                                          </label>
                                          <input
                                            className="InputForm"
                                            placeholder="Creado Por"
                                            type="text"
                                            name="createdBy"
                                            onChange={(e) =>
                                              handlePagoChange(e, index)
                                            }
                                            required
                                            disabled={
                                              pago.comprobante.destination
                                                ? true
                                                : false
                                            }
                                            value={pago.createdBy}
                                          ></input>
                                        </div>
                                        <div className="dialogEditGroup_elements">
                                          <label
                                            className="FormLabel"
                                            htmlFor="name"
                                          >
                                            Registrado el
                                          </label>
                                          <input
                                            className="InputForm"
                                            placeholder="Fecha de registro"
                                            type="text"
                                            name="createdAt"
                                            onChange={(e) =>
                                              handlePagoChange(e, index)
                                            }
                                            required
                                            disabled={
                                              pago.comprobante.destination
                                                ? true
                                                : false
                                            }
                                            value={pago.createdAt}
                                          ></input>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>
                              ))
                            : ""}
                          {/* Fin de dialogEditGroup */}
                          {infoPago.totalFaltante > 0 ? (
                            <button
                              className="btn__login"
                              style={{ margin: "1rem 0" }}
                              onClick={(e) => {
                                e.preventDefault();
                                setRegistroPagos([
                                  ...registroPagos,
                                  registroPagosObj,
                                ]);
                              }}
                            >
                              Agregar nuevo pago
                            </button>
                          ) : (
                            ""
                          )}

                          {/* <button type='submit'>
                            hola
                          </button> */}

                          {infoPago.status !== "Finalizado" ? (
                            <>
                              <hr></hr>

                              <div className="btn__container_pago">
                                <div
                                  className="dialogEditGroup_elements"
                                  style={{ marginBottom: "2rem" }}
                                >
                                  <label className="LabelForm">
                                    Total a cubrir
                                  </label>
                                  <input
                                    className="InputForm"
                                    value={infoPago.totalFaltante}
                                    disabled
                                  ></input>
                                </div>
                                <button
                                  className="btn__login"
                                  style={{
                                    marginTop: ".6rem",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  type="submit"
                                  disabled={isLoadingPago}
                                >
                                  {isLoadingPago ? (
                                    <SyncLoader
                                      color="white"
                                      loading={isLoadingPago}
                                      size={20}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                      speedMultiplier={0.7}
                                    />
                                  ) : (
                                    "Realizar Pago"
                                  )}
                                </button>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </form>
                      </Dialog.Content>
                    </Dialog.Portal>
                  </Dialog.Root>
                </div>
              </Tabs.Content>
            </Tabs.Root>
          </div>
        </section>
      </div>
    </div>
  );
};
